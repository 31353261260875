import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { withRoomContext } from '../../RoomContext';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { getServerUrl } from '../../urlFactory';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import Tooltip from '@material-ui/core/Tooltip';
import * as roomActions from '../../store/actions/roomActions';
import { useLocation } from 'react-router-dom';
import { config } from '../../config';
import Logger from '../../Logger';

const styles = (theme) =>
	({
		root :
		{
			display              : 'flex',
			width                : '100%',
			height               : '100%',
			backgroundColor      : 'var(--background-color)',
			backgroundImage      : `url(${config.background})`,
			backgroundAttachment : 'fixed',
			backgroundPosition   : 'center',
			backgroundSize       : 'cover',
			backgroundRepeat     : 'no-repeat'
		},
		dialogTitle :
		{
		},
		dialogPaper :
		{
			width                          : '60vw',
			padding                        : theme.spacing(2),
			[theme.breakpoints.down('lg')] :
			{
				width : '70vw'
			},
			[theme.breakpoints.down('md')] :
			{
				width : '80vw'
			},
			[theme.breakpoints.down('sm')] :
			{
				width : '90vw'
			},
			[theme.breakpoints.down('xs')] :
			{
				width : '95vw'
			}
		},
		accountButton :
		{
			padding : 0
		},
		logo :
		{
			maxHeight : 48
		},
		loginButton :
		{
			position : 'absolute',
			right    : theme.spacing(2),
			top      : theme.spacing(2),
			padding  : 0
		},
		largeIcon :
		{
			fontSize : '2em'
		},
		largeAvatar :
		{
			width  : 50,
			height : 50
		},
		green :
		{
			color : 'rgba(0, 153, 0, 1)'
		},
		red :
		{
			color : 'rgba(153, 0, 0, 1)'
		},
		username :
		{
			display : 'none'
		},
		list :
		{
			listStyleType   : 'none',
			padding         : theme.spacing(1),
			boxShadow       : '0 2px 5px 2px rgba(0, 0, 0, 0.2)',
			backgroundColor : 'rgba(255, 255, 255, 1)'
		},
		listheader :
		{
			fontWeight : 'bolder'
		},
		listItem :
		{
			width                : '100%',
			overflow             : 'hidden',
			'&:not(:last-child)' :
			{
				borderBottom : '1px solid #CBCBCB'
			}
		},
		listItemRoot :
		{
			width        : '100%',
			overflow     : 'hidden',
			cursor       : 'auto',
			display      : 'flex',
			marginTop    : '5px',
			marginBottom : '5px'
		},
		peerInfo :
		{
			fontSize    : '1rem',
			display     : 'flex',
			paddingLeft : theme.spacing(1),
			flexGrow    : 1,
			alignItems  : 'center'
		},
		peerCount :
		{
			fontSize   : '1rem',
			display    : 'flex',
			marginLeft : theme.spacing(1),
			alignItems : 'center',
			whiteSpace : 'nowrap'
		},
		joinButton :
		{
			marginLeft : theme.spacing(2),
			maxHeight  : '36px'
		},
		customRoomUrlInput :
		{
			width : '60%'
		}
	});

const logger = new Logger('RoomListDialog');

const DialogTitle = withStyles((theme) => ({
	root :
	{
		margin  : 0,
		padding : theme.spacing(1)
	}
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
	root :
	{
		padding : theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root :
	{
		margin  : 0,
		padding : theme.spacing(1)
	}
}))(MuiDialogActions);

const RoomListDialog = (props) =>
{
	const {
		classes,
		roomClient,
		locale,
		localesList
	} = props;

	const location = useLocation();
	const intl = useIntl();
	const serverUrl = getServerUrl();
	const hostname = config.serverHostname || window.location.hostname;
	const logoURL = config.logo ? config.logo : '';
	const params = new URLSearchParams(window.location.search);
	const [ roomList, setRoomList ] = useState([]);

	/*
	const dummyjson = '[{"roomId":"test11","accessToken":"sdsdsdsd12","peerCount":2},
		{"roomId":"test12","accessToken":"333f3c3v","peerCount":0}]';

	if (roomList?.length === 0)
		setRoomList(JSON.parse(dummyjson));

	*/

	const peersCheck = () =>
	{
		if (params.has('list_token') && roomList?.length > 0)
		{
			const data = {};
			const roomIds = [];

			roomList.forEach((room) =>
			{
				roomIds.push(room.roomId);
			});

			data.roomIds = roomIds;

			roomClient.fetchWithTimeout(`${serverUrl}/auth/medvc_api?apiToken=${params.get('list_token')}&action=peerCount&hostname=${hostname}`, {
				method         : 'POST',
				cache          : 'no-cache',
				redirect       : 'follow',
				referrerPolicy : 'no-referrer',
				headers        : { 'Content-Type': 'application/json' },
				body           : JSON.stringify(data)
			})
				.then((response) => response.json())
				.then((json) =>
				{
					if (json.error)
						logger.error('Error getting peer count: "%s"', json.error);
					else if (!json.peerCount)
						logger.error('Error getting peer count - no list in response');
					else
					{
						const newRoomList = [ ...roomList ];

						let wasUpdated = false;

						newRoomList.forEach((room) =>
						{
							if (room.roomId in json.peerCount &&
								room.peerCount !== json.peerCount[room.roomId])
							{
								room.peerCount = json.peerCount[room.roomId];
								wasUpdated = true;
							}
						});

						if (wasUpdated)
							setRoomList(newRoomList);
					}
				})
				.catch((error) =>
				{
					logger.error('Exception while getting room list', error);
				});
		}
	};

	useEffect(() =>
	{
		if (params.has('list_token'))
		{
			roomClient.fetchWithTimeout(`${serverUrl}/auth/medvc_api?apiToken=${params.get('list_token')}&action=roomList&hostname=${hostname}`, {
				method         : 'GET',
				cache          : 'no-cache',
				redirect       : 'follow',
				referrerPolicy : 'no-referrer' })
				.then((response) => response.json())
				.then((json) =>
				{
					if (json.error)
						logger.error('Error getting room list: "%s"', json.error);
					else if (!json.roomList)
						logger.error('Error getting room list - no list in response');
					else
						setRoomList(json.roomList);
				})
				.catch((error) =>
				{
					logger.error('Exception while getting room list', error);
				});
		}
	}, []);

	useEffect(() =>
	{
		const peersInterval = setInterval(peersCheck, 5000);

		return () => { clearInterval(peersInterval); };
	}, [ roomList ]);

	return (
		<div className={classes.root}>
			<Dialog
				open
				maxWidth='xl'
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<DialogTitle>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<Grid item>
							{ logoURL !=='' &&
								<img alt='Logo' src={logoURL} className={classes.logo} />
							}
						</Grid>
						<Grid item>
							<Grid
								container
								direction='row'
								justifyContent='flex-end'
								alignItems='center'
							>
								{/* LOCALE SELECTOR */}
								<Grid item>
									<Grid container direction='column' alignItems='center'>
										<Grid item>
											<PopupState variant='popover' popupId='demo-popup-menu'>
												{(popupState) => (
													<React.Fragment>
														<Button
															className={classes.actionButton}
															aria-label={locale.split(/[-_]/)[0]}
															color='secondary'
															disableRipple
															style={{ backgroundColor: 'transparent' }}
															{...bindTrigger(popupState)}
														>
															{locale.split(/[-_]/)[0]}
														</Button>
														<Menu {...bindMenu(popupState)}>
															{localesList.map((item, index) => (
																<MenuItem
																	selected={item.locale.includes(locale)}
																	key={index}
																	onClick={() =>
																	{
																		roomClient.setLocale(item.locale[0]);
																	}}
																>
																	{item.name}
																</MenuItem>)
															)}

														</Menu>
													</React.Fragment>
												)}
											</PopupState>
										</Grid>
									</Grid>
								</Grid>
								{/* /LOCALE SELECTOR */}
							</Grid>
						</Grid>
					</Grid>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<Grid item>
							<Typography variant='h5'>
								<FormattedMessage
									id='roomList.title'
									defaultMessage={'Room list'}
								/>
							</Typography>
						</Grid>
					</Grid>
					<hr />
				</DialogTitle>
				<DialogContent>
					{roomList?.length > 0 &&
					<ul className={classes.list}>
						<Flipper>
							{roomList.map((entry) => (
								<Flipped key={entry.title} flipId={entry.title}>
									<li
										key={entry.title}
										className={classnames(classes.listItem)}
									>
										<div className={classes.listItemRoot}>
											<div className={classes.peerInfo}>
												{(config.titles[entry.roomId] ?
													config.titles[entry.roomId] : config.title)}
											</div>
											<div className={classes.peerCount}>
												<PersonIcon />
												{`${entry.peerCount}`}
											</div>
											<Button
												variant='contained'
												color='secondary'
												type='button'
												className={classes.joinButton}
												onClick={() =>
												{
													const roomUrl = `${serverUrl}/${entry.roomId}`;

													let accessTokenParam = '';

													if (entry.accessToken)
														accessTokenParam = `?access_token=${entry.accessToken}`;

													window.location.href = `${serverUrl}/${entry.roomId}${accessTokenParam}`;
												}}
											>
												<FormattedMessage
													id='label.join'
													defaultMessage='Join'
												/>
											</Button>
										</div>
									</li>
								</Flipped>
							))}
						</Flipper>
					</ul>
					}
				</DialogContent>
			</Dialog>
		</div>
	);
};

RoomListDialog.propTypes =
{
	classes     : PropTypes.object.isRequired,
	roomClient  : PropTypes.any.isRequired,
	locale      : PropTypes.string.isRequired,
	localesList : PropTypes.array.isRequired
};

const makeMapStateToProps = (initialState) =>
{
	const mapStateToProps = (state) =>
	{
		return {
			locale      : state.intl.locale,
			localesList : state.intl.list
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = (dispatch) =>
	({});

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.intl.locale === next.intl.locale &&
				prev.intl.localesList === next.intl.localesList
			);
		}
	}
)(withStyles(styles)(RoomListDialog)));
