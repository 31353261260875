import Logger from './Logger';
import hark from 'hark';
import { getSignalingUrl } from './urlFactory';
import { SocketTimeoutError } from './utils';
import * as requestActions from './store/actions/requestActions';
import * as meActions from './store/actions/meActions';
import * as intlActions from './store/actions/intlActions';
import * as roomActions from './store/actions/roomActions';
import * as peerActions from './store/actions/peerActions';
import * as peerVolumeActions from './store/actions/peerVolumeActions';
import * as settingsActions from './store/actions/settingsActions';
import * as chatActions from './store/actions/chatActions';
import * as fileActions from './store/actions/fileActions';
import * as lobbyPeerActions from './store/actions/lobbyPeerActions';
import * as consumerActions from './store/actions/consumerActions';
import * as producerActions from './store/actions/producerActions';
import * as notificationActions from './store/actions/notificationActions';
import * as statsActions from './store/actions/statsActions';
import * as toolareaActions from './store/actions/toolareaActions';
import Spotlights from './Spotlights';
import { permissions } from './permissions';
import * as locales from './intl/locales';
import { createIntl } from 'react-intl';
import * as recorderActions from './store/actions/recorderActions';
import { directReceiverTransform, opusReceiverTransform } from './transforms/receiver';
import { config } from './config';
import { format } from 'date-fns';
import { fabric } from 'fabric';
import TerminalClient from './TerminalClient';

let createTorrent;

let WebTorrent;

let saveAs;

let mediasoupClient;

let io;

let ScreenShare;

const logger = new Logger('RoomClient');

const VIDEO_CONSTRAINS =
{
	'low' :
	{
		width  : 320,
		height : 180
	},
	'medium' :
	{
		width  : 640,
		height : 360
	},
	'high' :
	{
		width  : 1280,
		height : 720
	},
	'veryhigh' :
	{
		width  : 1920,
		height : 1080
	},
	'ultra' :
	{
		width  : 3840,
		height : 2160
	}
};

function getVideoConstrains(resolution, aspectRatio)
{
	let videoConstrains;

	if (aspectRatio === 16 / 9)
	{
		videoConstrains = {
			width  : { ideal: VIDEO_CONSTRAINS[resolution].width },
			height : { ideal: VIDEO_CONSTRAINS[resolution].height }
		};
	}
	else
	{
		videoConstrains = {
			width  : { ideal: VIDEO_CONSTRAINS[resolution].width },
			height : { ideal: Math.round(VIDEO_CONSTRAINS[resolution].width / aspectRatio) }
		};
	}

	return videoConstrains;
}

function getDeviceIdConstrains(deviceId)
{
	if (!deviceId || deviceId === '')
		return null;
	else
		return { exact: deviceId };
}

const PC_PROPRIETARY_CONSTRAINTS =
{
	optional : [ { googDscp: true } ]
};

function getSvcResolutionScalings(encoding)
{
	const resolutionScalings = [];
	const { spatialLayers } =
		mediasoupClient.parseScalabilityMode(encoding.scalabilityMode);

	for (let i = 0; i < spatialLayers; i++)
	{
		resolutionScalings.push(2 ** (spatialLayers - i - 1));
	}

	return resolutionScalings;
}

/**
 * Validates the simulcast `encodings` array extracting the resolution scalings
 * array.
 * ref. https://www.w3.org/TR/webrtc/#rtp-media-api
 *
 * @param {*} encodings
 * @returns the resolution scalings array
 */
function getSimulcastResolutionScalings(encodings)
{
	const resolutionScalings = [];

	// Simulcast encodings
	let scaleResolutionDownByDefined = false;

	encodings.forEach((encoding) =>
	{
		if (encoding.scaleResolutionDownBy !== undefined)
		{
			// at least one scaleResolutionDownBy is defined
			scaleResolutionDownByDefined = true;
			// scaleResolutionDownBy must be >= 1.0
			resolutionScalings.push(Math.max(1.0, encoding.scaleResolutionDownBy));
		}
		else
		{
			// If encodings contains any encoding whose scaleResolutionDownBy
			// attribute is defined, set any undefined scaleResolutionDownBy
			// of the other encodings to 1.0.
			resolutionScalings.push(1.0);
		}
	});

	// If the scaleResolutionDownBy attribues of sendEncodings are
	// still undefined, initialize each encoding's scaleResolutionDownBy
	// to 2^(length of sendEncodings - encoding index - 1).
	if (!scaleResolutionDownByDefined)
	{
		encodings.forEach((encoding, index) =>
		{
			resolutionScalings[index] = 2 ** (encodings.length - index - 1);
		});
	}

	return resolutionScalings;
}

let store;

let intl;

const insertableStreamsSupported = Boolean(RTCRtpSender.prototype.createEncodedStreams);

export default class RoomClient
{
	/**
	 * @param  {Object} data
	 * @param  {Object} data.store - The Redux store.
	 * @param  {Object} data.intl - react-intl object
	 */
	static init(data)
	{
		store = data.store;
	}

	constructor(
		{
			peerId,
			accessCode,
			device,
			produce,
			headless,
			forceTcp,
			forceTurn,
			displayName,
			muted,
			basePath
		} = {})
	{
		if (!peerId)
			throw new Error('Missing peerId');
		else if (!device)
			throw new Error('Missing device');

		logger.debug(
			'constructor() [peerId: "%s", device: "%s", produce: "%s", forceTcp: "%s", displayName ""]',
			peerId, device.flag, produce, forceTcp, forceTurn, displayName);

		this._startRefreshSessonCookie();

		this._signalingUrl = null;

		// Closed flag.
		this._closed = false;

		// Whether we should produce.
		this._produce = produce;

		// Whether we force TCP
		this._forceTcp = forceTcp;

		// config override of param
		if (config.forceTcp)
			this._forceTcp = true;

		// Whether we force using of turn server
		this._forceTurn = forceTurn;

		// config override of param
		if (config.forceTurn)
			this._forceTurn = true;

		this._turnServers = null;

		// URL basepath
		this._basePath = basePath;

		// Use displayName
		this._displayName=null;
		if (displayName)
		{
			store.dispatch(settingsActions.setDisplayName(displayName));
			this._displayName = displayName;
		}

		this._recorderInitState = {};

		this._tracker = null;

		// Torrent support
		this._torrentSupport = null;

		// Whether simulcast should be used.
		this._useSimulcast = false;

		this._useSimulcast = config.simulcast;

		// Whether simulcast should be used for sharing
		this._useSharingSimulcast = config.simulcastSharing;

		this._muted = muted;

		// This device
		this._device = device;

		// add checking if is PWA
		this._device.isPwa = (this._device.platform !== 'desktop' &&
			(navigator.standalone ||
			window.matchMedia('(display-mode: standalone)').matches));

		// My peer name.
		this._peerId = peerId;

		// Access code
		this._accessCode = accessCode;

		// Alert sounds
		this._soundAlerts = { 'default': { audio: new Audio('/sounds/notify.mp3') } };
		if (config.notificationSounds)
		{
			for (const [ k, v ] of Object.entries(config.notificationSounds))
			{
				if (v != null && v.play !== undefined)
					this._soundAlerts[k] = {
						audio : new Audio(v.play),
						delay : v.delay ? v.delay: 0
					};
			}
		}

		// Socket.io peer connection
		this._signalingSocket = null;

		// The room ID
		this._roomId = null;

		// mediasoup-client Device instance.
		// @type {mediasoupClient.Device}
		this._mediasoupDevice = null;

		// Put the browser info into state
		store.dispatch(meActions.setBrowser(device));

		// Our WebTorrent client
		this._webTorrent = null;

		// if not set, then generate and set clientId
		let clientId = store.getState().settings.clientId;

		if (!clientId)
		{
			const rand1 = Math.floor(Math.random() * Date.now()).toString(36);
			const rand2 = Math.floor(Math.random() * Date.now()).toString(36);
			const rand3 = Math.floor(Math.random() * Date.now()).toString(36);

			clientId = `${rand1}${rand2}${rand3}`;
			store.dispatch(settingsActions.setClientId(clientId));
		}

		// Max spotlights
		if (device.platform === 'desktop')
			this._maxSpotlights = config.lastN;
		else
			this._maxSpotlights = config.mobileLastN;

		const urlParser = new URL(window.location);
		const parameters = urlParser.searchParams;

		this._maxSpotlights = parameters.get('lastN') || this._maxSpotlights;

		store.dispatch(
			settingsActions.setLastN(this._maxSpotlights));

		// Manager of spotlight
		this._spotlights = new Spotlights(this._maxSpotlights,
			store.getState().settings.hideNoVideoParticipants, this);

		// if never set before, set it
		if (!store.getState().settings.resolution)
		{
			const resolutionToSet = (this._device.platform !== 'desktop') ?
				config.resolutionsMobilePerRoom[this._roomId] || config.resolutionMobile
				:
				config.resolutionsPerRoom[this._roomId] || config.resolution;

			store.dispatch(settingsActions.setVideoResolution(resolutionToSet));
		}

		let maxExtraVideos = config.maxExtraVideos;

		// config for clientId overrides standard setting and mobile reduction
		if (Number.isInteger(config.maxExtraVideosForClientId[clientId]) &&
			config.maxExtraVideosForClientId[clientId] >= 0)
		{
			maxExtraVideos = config.maxExtraVideosForClientId[clientId];
		}
		else if (this._device.platform !== 'desktop' && maxExtraVideos > 0)
		{
			maxExtraVideos = 1;
		}

		store.dispatch(meActions.setMaxExtraVideos(maxExtraVideos));

		// Transport for sending.
		this._sendTransport = null;

		// Transport for receiving.
		this._recvTransport = null;

		// Local mic mediasoup Producer.
		this._micProducer = null;

		// Local mic hark
		this._hark = null;

		// Local MediaStream for hark
		this._harkStream = null;

		// Local webcam mediasoup Producer.
		this._webcamProducer = null;

		// Extra videos being produced
		this._extraVideoProducers = new Map();

		// Map of webcam MediaDeviceInfos indexed by deviceId.
		// @type {Map<String, MediaDeviceInfos>}
		this._webcams = {};

		this._audioDevices = {};

		this._audioOutputDevices = {};

		// mediasoup Consumers.
		// @type {Map<String, mediasoupClient.Consumer>}
		this._consumers = new Map();

		this._screenSharing = null;

		this._screenSharingProducer = null;

		this._screenSharingAudioProducer = null;

		this._startKeyListener();

		this._startDevicesListener();

		this._startVisibilityStateListener();

		this._startLaunchQueueListener();

		this.setLocale(store.getState().intl.locale);

		if (store.getState().settings.localPicture)
		{
			store.dispatch(meActions.setPicture(store.getState().settings.localPicture));
		}
		store.dispatch(
			settingsActions.setRecorderSupportedMimeTypes(this.getRecorderSupportedMimeTypes())
		);

		// Receive transport restart ICE object
		this._recvRestartIce = { timer: null, restarting: false };

		// Send transport restart ICE object
		this._sendRestartIce = { timer: null, restarting: false };

		TerminalClient.init({ store });
		this._terminalClient = null;

		if (headless)
		{
			const encodedRoomId =
				encodeURIComponent(decodeURIComponent(window.location.pathname.slice(1)));

			this.join({
				roomId    : encodedRoomId,
				joinVideo : false,
				joinAudio : false
			});
		}

		this._uploadSnapshotCurrentTimeout = null;
		this._uploadSnapshotInProgressTimeout = null;
		this._statsInterval = null;
		this._statsScoresExchangeInterval = null;

		this._consumerQueue = {};

		this._encodingProblems = { videoStartTimes: [], doSwitch: '', problems: [] };

		this._blurBodyPix = null;
		this._blurBackgroundInterval = null;
	}

	close(doNotReload = false, withTimeout = false, url = null)
	{
		// Dla medVC zawsze wpracamy na stronę pokoju
		doNotReload = false;

		// ASTAGOR END

		this._encodingProblems = { videoStartTimes: [], doSwitch: '', problems: [] };

		if (this._terminalClient)
			this._terminalClient.close();

		this._terminalClient = null;

		if (this._statsInterval)
		{
			clearInterval(this._statsInterval);
			this._statsInterval = null;
		}

		if (this._statsScoresExchangeInterval)
		{
			clearInterval(this._statsScoresExchangeInterval);
			this._statsScoresExchangeInterval = null;
		}

		if (this._uploadSnapshotCurrentTimeout)
		{
			clearTimeout(this._uploadSnapshotCurrentTimeout);
			this._uploadSnapshotCurrentTimeout = null;
		}

		if (this._uploadSnapshotInProgressTimeout)
		{
			clearTimeout(this._uploadSnapshotInProgressTimeout);
			this._uploadSnapshotInProgressTimeout = null;
		}

		if (this._closed)
			return;

		this._closed = true;

		logger.debug('close()');

		if (this._blurBackgroundInterval)
		{
			clearInterval(this._blurBackgroundInterval);
			this._blurBackgroundInterval = null;
		}

		window.removeEventListener('beforeunload', this._onBeforeUnload);

		this._signalingSocket.close();

		// Close mediasoup Transports.
		if (this._sendTransport)
			this._sendTransport.close();

		if (this._recvTransport)
			this._recvTransport.close();

		store.dispatch(roomActions.setRoomState('closed'));

		// ASTAGOR START
		let newLocation = `/${this._roomId}`;

		if (url)
		{
			newLocation = url;
		}
		else if (doNotReload)
		{
			newLocation = '/';
		}
		else if (store.getState().me.isRecorder)
		{
			newLocation = `/${this._roomId}`;
		}
		else if (store.getState().me.shutdownEnabled)
		{
			newLocation = config.closeUrlTerminal;
		}
		else if (store.getState().me.loggedInWithPin && config.closeUrlPin)
		{
			newLocation = config.closeUrlPin;
		}
		else if (config.closeUrl)
		{
			newLocation = config.closeUrl;
		}

		if (withTimeout)
			setTimeout(() =>
			{
				window.location = newLocation;
			}, 2000);
		else
			window.location = newLocation;

		// ASTAGOR END
	}

	_startKeyListener()
	{
		// Add keydown event listener on document
		document.addEventListener('keydown', (event) =>
		{
			if (event.repeat) return;
			const key = String.fromCharCode(event.which);

			const source = event.target;

			const exclude = [ 'input', 'textarea', 'div' ];

			if (exclude.indexOf(source.tagName.toLowerCase()) === -1)
			{
				logger.debug('keyDown() [key:"%s"]', key);

				switch (key)
				{
					case String.fromCharCode(35):
					{
						const fullScreenConsumerId = store.getState().room.fullScreenConsumer;
						const fullScreenProducerId = store.getState().room.fullScreenProducer;

						if (!fullScreenConsumerId && !fullScreenProducerId)
						{
							break;
						}

						if (fullScreenConsumerId)
						{
							store.dispatch(roomActions.toggleConsumerFullscreen(fullScreenConsumerId));

							if (store.getState().me.loggedInWithPin)
								this.sendRecorderRequest('recorderRemoveFullscreenConsumer', null);
						}
						else if (fullScreenProducerId)
						{
							store.dispatch(roomActions.toggleProducerFullscreen(fullScreenProducerId));

							if (store.getState().me.loggedInWithPin)
								this.sendRecorderRequest('recorderRemoveFullscreenConsumer', null);
						}

						break;
					}

					case String.fromCharCode(19):
					case String.fromCharCode(80):
					{
						const fullScreenConsumerId = store.getState().room.fullScreenConsumer;
						const fullScreenProducerId = store.getState().room.fullScreenProducer;
						const uploadSnapshotInProgress = store.getState().me.uploadSnapshotInProgress;

						if (!fullScreenConsumerId && !fullScreenProducerId)
						{
							break;
						}

						if (uploadSnapshotInProgress)
						{
							break;
						}

						if (fullScreenConsumerId)
						{
							const fullScreenConsumer = store.getState().
								consumers[fullScreenConsumerId];

							if (!fullScreenConsumer)
								break;

							const peer = store.getState().peers[fullScreenConsumer.peerId];

							if (!peer)
								break;

							if (fullScreenConsumer.snapshot)
								this.removeSnapshot(peer.id, fullScreenConsumer.producerId);
							else
								this.requestSnapshot(peer.id, fullScreenConsumer.producerId);
						}
						else if (fullScreenProducerId)
						{
							const fullScreenProducer = store.getState().
								producers[fullScreenProducerId];

							if (!fullScreenProducer)
								break;

							const me = store.getState().me;

							if (fullScreenProducer.snapshot)
								this.removeSnapshot(me.id, fullScreenProducerId);
							else
								this.requestSnapshot(me.id, fullScreenProducerId);
						}

						break;
					}

					case String.fromCharCode(8):
					case String.fromCharCode(46):
					{
						const fullScreenConsumerId = store.getState().room.fullScreenConsumer;
						const fullScreenProducerId = store.getState().room.fullScreenProducer;

						if (!fullScreenConsumerId && !fullScreenProducerId)
						{
							break;
						}

						if (fullScreenConsumerId)
						{
							const fullScreenConsumer = store.getState().
								consumers[fullScreenConsumerId];

							if (!fullScreenConsumer)
								break;

							const peer = store.getState().peers[fullScreenConsumer.peerId];

							if (!peer)
								break;

							if (!fullScreenConsumer.pathsToDraw ||
								fullScreenConsumer.pathsToDraw.length === 0)
							{
								break;
							}

							this.removeDrawings(peer.id, fullScreenConsumer.producerId);
						}
						else if (fullScreenProducerId)
						{
							const fullScreenProducer = store.getState().
								producers[fullScreenProducerId];

							if (!fullScreenProducer)
								break;

							if (!fullScreenProducer.pathsToDraw ||
								fullScreenProducer.pathsToDraw.length === 0)
							{
								break;
							}

							const me = store.getState().me;

							this.removeDrawings(me.id, fullScreenProducerId);
						}

						break;
					}

					case String.fromCharCode(37):
					case String.fromCharCode(39):
					{
						const fullScreenConsumerId = store.getState().room.fullScreenConsumer;
						const fullScreenProducerId = store.getState().room.fullScreenProducer;
						const isRight = (key === String.fromCharCode(39));

						if (!fullScreenConsumerId && !fullScreenProducerId)
						{
							if (isRight)
								this.setSpotlightsCurrentPage(this._spotlights.currentPage + 1);
							else
								this.setSpotlightsCurrentPage(this._spotlights.currentPage - 1);
						}
						else if (fullScreenConsumerId)
						{
							const fullScreenConsumer =
								store.getState().consumers[fullScreenConsumerId];

							if (!fullScreenConsumer)
								break;

							const peer = store.getState().peers[fullScreenConsumer.peerId];

							if (!peer)
								break;

							const allConsumers = Object.values(store.getState().consumers);
							const consumersArray = allConsumers.filter((consumer) =>
							{
								return (consumer.peerId === fullScreenConsumer.peerId &&
									!consumer.locallyPaused && !consumer.remotelyPaused &&
									(consumer.source === 'webcam' || consumer.source === 'screen' ||
									consumer.source === 'extravideo'));
							});

							for (let i = 0; i < consumersArray.length; i++)
							{
								if (consumersArray[i].id === fullScreenConsumerId)
								{
									let newIndex = isRight ? i + 1 : i - 1;

									if (newIndex >= consumersArray.length)
										newIndex = 0;
									else if (newIndex < 0)
										newIndex = consumersArray.length - 1;

									if (newIndex === i)
										break;

									const newConsumer = consumersArray[newIndex];

									store.dispatch(
										roomActions.toggleConsumerFullscreen(newConsumer.id));

									if (store.getState().me.loggedInWithPin)
									{
										this.sendRecorderRequest('recorderSetFullscreenConsumer',
											{ peerId: peer.id, producerId: newConsumer.producerId });
									}

									break;
								}
							}
						}
						else if (fullScreenProducerId)
						{
							const fullScreenProducer =
								store.getState().producers[fullScreenProducerId];

							if (!fullScreenProducer)
								break;

							const allProducers = Object.values(store.getState().producers);
							const producersArray = allProducers.filter((producer) =>
							{
								return ((producer.source === 'webcam' || producer.source === 'screen' ||
									producer.source === 'extravideo') && !producer.paused);
							});

							for (let i = 0; i < producersArray.length; i++)
							{
								if (producersArray[i].id === fullScreenProducerId)
								{
									let newIndex = isRight ? i + 1 : i - 1;

									if (newIndex >= producersArray.length)
										newIndex = 0;
									else if (newIndex < 0)
										newIndex = producersArray.length - 1;

									if (newIndex === i)
										break;

									const newProducer = producersArray[newIndex];

									store.dispatch(roomActions.toggleProducerFullscreen(
										producersArray[newIndex].id));

									const me = store.getState().me;

									if (me.loggedInWithPin)
									{
										this.sendRecorderRequest('recorderSetFullscreenConsumer',
											{ peerId: me.id, producerId: newProducer.id });
									}

									break;
								}
							}
						}

						break;
					}

					/*
					case String.fromCharCode(37):
					{
						const newPeerId = this._spotlights.getPrevAsSelected(
							store.getState().room.selectedPeerId);
						if (newPeerId) this.setSelectedPeer(newPeerId);
						break;
					}
					case String.fromCharCode(39):
					{
						const newPeerId = this._spotlights.getNextAsSelected(
							store.getState().room.selectedPeerId);
						if (newPeerId) this.setSelectedPeer(newPeerId);
						break;
					}
					*/

					case 'A': // Activate advanced mode
					{
						store.dispatch(settingsActions.toggleAdvancedMode());
						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.toggleAdvancedMode',
									defaultMessage : 'Toggled advanced mode'
								})
							}));
						break;
					}

					/*
					case '1': // Set democratic view
					{
						store.dispatch(roomActions.setDisplayMode('democratic'));
						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.setDemocraticView',
									defaultMessage : 'Changed layout to democratic view'
								})
							}));
						break;
					}

					case '2': // Set filmstrip view
					{
						store.dispatch(roomActions.setDisplayMode('filmstrip'));
						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.setFilmStripView',
									defaultMessage : 'Changed layout to filmstrip view'
								})
							}));
						break;
					}
					*/

					case ' ': // Push To Talk start
					{
						if (this._micProducer)
						{
							if (this._micProducer.paused)
							{
								this.unmuteMic();
							}
						}

						break;
					}
					case 'M': // Toggle microphone
					{
						if (this._micProducer)
						{
							if (!this._micProducer.paused)
							{
								this.muteMic();

								store.dispatch(requestActions.notify(
									{
										text : intl.formatMessage({
											id             : 'devices.microphoneMute',
											defaultMessage : 'Muted your microphone'
										})
									}));
							}
							else
							{
								this.unmuteMic();

								store.dispatch(requestActions.notify(
									{
										text : intl.formatMessage({
											id             : 'devices.microphoneUnMute',
											defaultMessage : 'Unmuted your microphone'
										})
									}));
							}
						}
						else
						{
							this.updateMic({ start: true });

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'devices.microphoneEnable',
										defaultMessage : 'Enabled your microphone'
									})
								}));
						}

						break;
					}

					case 'V': // Toggle video
					{
						if (this._webcamProducer)
							this.disableWebcam();
						else
							this.updateWebcam({ start: true });

						break;
					}

					case 'H': // Open help dialog
					{
						store.dispatch(roomActions.setHelpOpen(true));

						break;
					}

					default:
					{
						break;
					}
				}
			}
		});
		document.addEventListener('keyup', (event) =>
		{
			const key = String.fromCharCode(event.which);

			const source = event.target;

			const exclude = [ 'input', 'textarea', 'div' ];

			if (exclude.indexOf(source.tagName.toLowerCase()) === -1)
			{
				logger.debug('keyUp() [key:"%s"]', key);

				switch (key)
				{
					case ' ': // Push To Talk stop
					{
						if (this._micProducer)
						{
							if (!this._micProducer.paused)
							{
								this.muteMic();
							}
						}

						break;
					}
					default:
					{
						break;
					}
				}
			}
			event.preventDefault();
		}, true);
	}

	_startRefreshSessonCookie()
	{
		setInterval(async () =>
		{
			await this.fetchWithTimeout('/auth/refresh_cookie', {
				timeout        : 10000,
				credentials    : 'include',
				method         : 'GET',
				cache          : 'no-cache',
				redirect       : 'follow',
				referrerPolicy : 'no-referrer' })
				.then((response) => response.json())
				.then((json) =>
				{
					if (json.status)
					{
						logger.debug('refreshSessonCookie status: %s', json.status);
					}
					else
					{
						logger.error('refreshSessonCookie no status in reponse, response: %o', json);
					}
				})
				.catch((error) =>
				{
					logger.error('refreshSessonCookie error', error);
				});
		}, 15 * 60 * 1000);
	}

	_startDevicesListener()
	{
		navigator.mediaDevices.addEventListener('devicechange', async () =>
		{
			logger.debug('_startDevicesListener() | navigator.mediaDevices.ondevicechange');

			await this._updateAudioDevices();
			await this._updateWebcams();
			await this._updateAudioOutputDevices();

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'devices.devicesChanged',
						defaultMessage : 'Your devices changed, configure your devices in the settings dialog'
					})
				}));
		});
	}

	_startLaunchQueueListener()
	{
		if ('launchQueue' in window)
		{
			window.launchQueue.setConsumer((launchParams) =>
			{
				// logger.error('Astagor launchParams %o', launchParams);

				return;
			});
		}

	}

	setPipWebcam(pipWebcam)
	{
		store.dispatch(meActions.setPipWebcam(pipWebcam));
	}

	_startVisibilityStateListener()
	{
		if (this._device.platform !== 'desktop')
		{
			document.addEventListener('visibilitychange', () =>
			{
				const visibilityState = document.visibilityState;
				const isPip = store.getState().me.pipWebcam;

				if (visibilityState === 'visible' && isPip)
					store.dispatch(meActions.setPipWebcam(false));

				if (this._device.isPwa &&
					visibilityState === 'visible' &&
					this._webcamProducer
				)
				{
					// fix PWA freez on cameras when comming back from background
					// trick set newresolution to force extraconsumer restart
					const { resolution } = store.getState().settings;

					this.updateWebcam({ restart: true, newResolution: resolution });
				}
			});
		}
	}

	setLocale(locale)
	{

		if (locale === null)
			locale = locales.detect();

		const one = locales.loadOne(locale);

		store.dispatch(intlActions.updateIntl({
			locale   : one.locale[0],
			messages : one.messages,
			list     : locales.getList()
		}));

		intl = createIntl({
			locale   : one.locale[0],
			messages : one.messages
		});

		document.documentElement.lang = one.locale[0].toUpperCase();
	}

	login(roomId = this._roomId)
	{
		const url = `/auth/login?peerId=${this._peerId}&roomId=${roomId}`;

		window.open(url, 'loginWindow');
	}

	logout(roomId = this._roomId)
	{
		window.open(`/auth/logout?peerId=${this._peerId}&roomId=${roomId}`, 'logoutWindow');
	}

	setLoggedIn(loggedIn, loggedInWithPin = false, isRecorder = false)
	{
		logger.debug('setLoggedIn() | [loggedIn: "%s", loggedInWithPin: "%s", isRecorder: "%s"]',
			loggedIn, loggedInWithPin, isRecorder);

		store.dispatch(meActions.loggedIn(loggedIn));
		store.dispatch(meActions.loggedInWithPin(loggedInWithPin));
		store.dispatch(meActions.isRecorder(isRecorder));
	}

	setPicture(picture)
	{
		store.dispatch(settingsActions.setLocalPicture(picture));
		store.dispatch(meActions.setPicture(picture));
		this.changePicture(picture);

		if (store.getState().settings.videoBackgroundEffect === 'image')
		{
			if (!picture)
				store.dispatch(settingsActions.setVideoBackgroundEffect('blur'));

			this.updateWebcam({ restart: true });
		}
	}

	receiveLoginChildWindow(data)
	{
		logger.debug('receiveFromChildWindow() | [data:"%o"]', data);

		// ASTAGOR START
		/*
		const { picture } = data;

		let displayName;

		if (typeof(data.displayName) === 'undefined' || !data.displayName)
			displayName = '';
		else
			displayName = data.displayName;
		*/

		const { displayName, picture } = data;

		if (typeof(displayName) !== 'undefined' && typeof(displayName) !== 'boolean')
		{
			store.dispatch(settingsActions.setDisplayName(displayName));
		}
		// ASTAGOR END

		this._displayName=displayName;

		if (!store.getState().settings.localPicture)
		{
			store.dispatch(meActions.setPicture(picture));
		}

		store.dispatch(meActions.loggedIn(true));

		store.dispatch(requestActions.notify(
			{
				text : intl.formatMessage({
					id             : 'room.loggedIn',
					defaultMessage : 'You are logged in'
				})
			}));
	}

	receiveLogoutChildWindow()
	{
		logger.debug('receiveLogoutChildWindow()');

		if (!store.getState().settings.localPicture)
		{
			store.dispatch(meActions.setPicture(null));
		}

		store.dispatch(meActions.isRecorder(false));
		store.dispatch(meActions.loggedInWithPin(false));
		store.dispatch(meActions.loggedIn(false));

		store.dispatch(requestActions.notify(
			{
				text : intl.formatMessage({
					id             : 'room.loggedOut',
					defaultMessage : 'You are logged out'
				})
			}));
	}

	_soundNotification(type = 'default')
	{
		const { notificationSounds } = store.getState().settings;

		if (notificationSounds)
		{
			const soundAlert = this._soundAlerts[type] === undefined
				? this._soundAlerts['default'] : this._soundAlerts[type];

			const now = Date.now();

			if (soundAlert.last !== undefined && (now - soundAlert.last) < soundAlert.delay)
			{
				return;
			}
			soundAlert.last = now;

			const alertPromise = soundAlert.audio.play();

			if (alertPromise !== undefined)
			{
				alertPromise
					.then()
					.catch((error) =>
					{
						logger.error('_soundAlert.play() [error:"%o"]', error);
					});
			}
		}
	}

	timeoutCallback(callback)
	{
		let called = false;

		const interval = setTimeout(
			() =>
			{
				if (called)
					return;
				called = true;
				callback(new SocketTimeoutError('Request timed out'));
			},
			config.requestTimeout
		);

		return (...args) =>
		{
			if (called)
				return;
			called = true;
			clearTimeout(interval);

			callback(...args);
		};
	}

	_sendRequest(method, data)
	{
		return new Promise((resolve, reject) =>
		{
			if (!this._signalingSocket)
			{
				reject('No socket connection');
			}
			else
			{
				this._signalingSocket.emit(
					'request',
					{ method, data },
					this.timeoutCallback((err, response) =>
					{
						if (err)
							reject(err);
						else
							resolve(response);
					})
				);
			}
		});
	}

	async _getLocalProducersStats()
	{
		const producersStats = {};

		let producerStats = await this._getLocalProducerStats(this._webcamProducer);

		if (producerStats.length > 0)
			producersStats[this._webcamProducer.id] = producerStats;

		producerStats = await this._getLocalProducerStats(this._screenSharingProducer);

		if (producerStats.length > 0)
			producersStats[this._screenSharingProducer.id] = producerStats;

		producerStats = await this._getLocalProducerStats(this._micProducer);

		if (producerStats.length > 0)
			producersStats[this._micProducer.id] = producerStats;

		producerStats = await this._getLocalProducerStats(this._screenSharingAudioProducer);

		if (producerStats.length > 0)
			producersStats[this._screenSharingAudioProducer.id] = producerStats;

		for (const producer of this._extraVideoProducers.values())
		{
			producerStats = await this._getLocalProducerStats(producer);

			if (producerStats.length > 0)
				producersStats[producer.id] = producerStats;

		}

		if (Object.keys(producersStats).length > 0)
			store.dispatch(statsActions.setProducersStats(producersStats));
	}

	async _getLocalProducerStats(producer)
	{
		const producerStats = [];

		if (!producer || producer.closed)
			return producerStats;

		const outStats = [];
		const inStats = [];
		const raports = await producer.getStats();

		raports.forEach((report) =>
		{
			if (report.type === 'outbound-rtp' &&
				(report.kind === 'video' || report.kind === 'audio'))
			{
				outStats.push(report);
			}
			else if (report.type === 'remote-inbound-rtp' &&
				(report.kind === 'video' || report.kind === 'audio'))
			{
				inStats.push(report);
			}
		});

		outStats.sort((a, b) =>
		{
			return b.frameWidth - a.frameWidth;
		});

		const prevStats = store.getState().stats.producersStats[producer.id];
		const producerScores = store.getState().producers[producer.id].score;

		outStats.forEach((outStat) =>
		{
			const inStat = inStats.find((e) => e.ssrc === outStat.ssrc);
			const prodStat = {};

			if (prevStats)
			{
				const prevStat = prevStats.find((e) => e.outbound.ssrc === outStat.ssrc);

				if (prevStat && prevStat['outbound'])
				{
					const bitrate = ((outStat.bytesSent - prevStat['outbound'].bytesSent) * 8) /
						((outStat.timestamp - prevStat['outbound'].timestamp) / 1000);

					outStat['calculatedBitrate'] = bitrate;
				}
			}

			if (producerScores)
			{
				const score = producerScores.find((e) => e.ssrc === outStat.ssrc);

				if (score)
				{
					outStat['mediasoupScore'] = score.score;
				}
			}

			const now = Date.now();

			if (!this._encodingProblems.videoStartTimes[producer.id])
				this._encodingProblems.videoStartTimes[producer.id] = now;

			if (config.enableProblemAutoSwitching &&
				producer.kind === 'video' &&
				(producer.appData.source === 'webcam' || producer.appData.source === 'extravideo') &&
				(this._encodingProblems.doSwitch === '' || this._encodingProblems.doSwitch === 'doneBlur') &&
				this._encodingProblems.videoStartTimes[producer.id] + 20000 < now)
			{
				if (!this._encodingProblems.problems[producer.id])
					this._encodingProblems.problems[producer.id] = [];

				if (outStat.qualityLimitationReason === 'bandwidth')
				{
					this._encodingProblems.problems[producer.id].push(now);

					if (this._encodingProblems.problems[producer.id][0] + (10 * 1000) < now)
					{
						if (this._encodingProblems.doSwitch === '')
						{
							this._encodingProblems.doSwitch = 'switch';
							this._encodingProblems.problems[producer.id] = [];
							this.updateWebcam({ restart: true });
						}

						/*
						blur na canvas ma klopoty wydajnościowe dlatego nie robimy, jest tylko switch
						jesli kiedyś to to zamiast bloku wyżej
						if (this._encodingProblems.doSwitch === '')
						{
							this._encodingProblems.doSwitch = 'blur';
							this._encodingProblems.problems[producer.id] = [];
							this.updateWebcam({ restart: true });
							setTimeout(() =>
							{
								if (this._encodingProblems.doSwitch === 'blur')
								{
									this._encodingProblems.doSwitch = 'doneBlur';
									this._encodingProblems.problems[producer.id] = [];
									this.updateWebcam({ restart: true });
								}
							}, 10 * 1000);
						}
						else if (this._encodingProblems.doSwitch === 'doneBlur')
						{
							this._encodingProblems.doSwitch = 'switch';
							this._encodingProblems.problems[producer.id] = [];
							this.updateWebcam({ restart: true });
						}
						*/
					}
				}
				else if (this._encodingProblems.problems[producer.id].length > 0)
				{
					this._encodingProblems.problems[producer.id] = [];
				}
			}

			prodStat['outbound'] = outStat;
			prodStat['remoteInbound'] = inStat;
			producerStats.push(prodStat);
		});

		logger.debug('_getLocalProducerStats producerId = %s | producerStats %o',
			producer.id, producerStats);

		return producerStats;
	}

	async _getLocalTransportsStats()
	{
		let send = null;

		let recv = null;

		if (this._sendTransport && !this._sendTransport.closed)
		{
			const raports = await this._sendTransport.getStats();

			send = {};

			const remoteIp = new Set();
			const localIp = new Set();

			let remoteIpUsed = '';

			let localIpUsed = '';

			let candidatePair;

			raports.forEach((report) =>
			{
				if (report.type === 'candidate-pair' && report.state === 'succeeded')
					candidatePair = report;
			});

			// only ip v4 report.ip.includes('.')
			raports.forEach((report) =>
			{
				if (report.type === 'transport')
					send = report;
				else if (report.type === 'remote-candidate' &&
					report.ip?.includes('.'))
				{
					if (report.id === candidatePair?.remoteCandidateId)
						remoteIpUsed = report.ip;
					else
						remoteIp.add(report.ip);
				}
				else if (report.type === 'local-candidate' &&
					report.ip?.includes('.'))
				{
					if (report.id === candidatePair?.localCandidateId)
						localIpUsed = `${report.ip} - ${report.protocol}`;
					else
						localIp.add(`${report.ip} - ${report.protocol}`);
				}
			});

			if (remoteIp.size > 0)
				send['remoteIp'] = `used: ${remoteIpUsed} | other: ${Array.from(remoteIp).join('; ')}`;
			else
				send['remoteIp'] = remoteIpUsed;

			if (localIp.size > 0)
				send['localIp'] = `used: ${localIpUsed} | other: ${Array.from(localIp).join('; ')}`;
			else
				send['localIp'] = localIpUsed;

			const prevStat = store.getState().stats.transportsStats['send'];

			if (prevStat && send.bytesSent && prevStat.bytesSent)
			{
				const bitrate = ((send.bytesSent - prevStat.bytesSent) * 8) /
					((send.timestamp - prevStat.timestamp) / 1000);

				send['calculatedBitrate'] = bitrate;
			}

			logger.debug('getLocalTransportStats send stats %o', send);
		}

		if (this._recvTransport && !this._recvTransport.closed)
		{
			const raports = await this._recvTransport.getStats();

			recv = {};

			raports.forEach((report) =>
			{
				if (report.type === 'transport')
					recv = report;
			});

			const prevStat = store.getState().stats.transportsStats['recv'];

			if (prevStat && recv.bytesReceived && prevStat.bytesReceived)
			{
				const bitrate = ((recv.bytesReceived - prevStat.bytesReceived) * 8) /
					((recv.timestamp - prevStat.timestamp) / 1000);

				recv['calculatedBitrate'] = bitrate;
			}

			logger.debug('getLocalTransportStats recv stats %o', recv);
		}

		if (send || recv)
		{
			const transportsStats = {};

			if (send)
				transportsStats['send'] = send;
			else
				transportsStats['send'] = {};

			if (recv)
				transportsStats['recv'] = recv;
			else
				transportsStats['recv'] = {};

			store.dispatch(statsActions.setTransportsStats(transportsStats));
		}
	}

	async _exchangeStatsAndScores()
	{
		logger.debug('_exchangeStatsAndScores()');

		const producersStats = store.getState().stats.producersStats;
		const transportsStats = store.getState().stats.transportsStats;

		producersStats['transportsStats'] = transportsStats;

		try
		{
			const {
				peerProducersStats,
				producersScores,
				consumersScores
			} = await this.sendRequest('exchangeStatsAndScores', { producersStats });

			if (producersScores)
				store.dispatch(producerActions.setProducersScores(producersScores));

			if (consumersScores)
				store.dispatch(consumerActions.setConsumersScores(consumersScores));

			if (peerProducersStats && store.getState().settings.advancedMode)
				store.dispatch(statsActions.setPeerProducersStats(peerProducersStats));
		}
		catch (error)
		{
			logger.error('_exchangeStatsAndScores() [error:"%o"]', error);
		}
	}

	_startStatsMonitoring()
	{
		this._statsInterval = setInterval(() =>
		{
			this._getLocalTransportsStats();
			this._getLocalProducersStats();
		}
		, config.producersStatsIntervalTime);

		// run first time at once to get scores
		this._exchangeStatsAndScores();

		this._statsScoresExchangeInterval = setInterval(() =>
		{
			this._exchangeStatsAndScores();
		}
		, config.statsScoresExchangeIntervalTime);
	}

	/*
	getProducersStats()
	{
		if (this._webcamProducer)
			this._getProducerStats(this._webcamProducer);

		if (this._screenSharingProducer)
			this._getProducerStats(this._screenSharingProducer);

		for (const producer of this._extraVideoProducers.values())
		{
			this._getProducerStats(producer);
		}
	}

	async _getProducerStats(producer)
	{
		try
		{
			const stats = await this.sendRequest(
				'getProducerStats', { producerId: producer.id });

			logger.debug('getProducerStats id: %s; source: %s; stats: %o',
				producer.id, producer.appData.source, stats);
		}
		catch (error)
		{
			logger.error('getProducerStats() [error:"%o"]', error);
		}
	}

	async getTransportStats()
	{
		try
		{
			if (this._recvTransport)
			{
				logger.debug('getTransportStats() - recv [transportId: "%s"]', this._recvTransport.id);

				const recv = await this.sendRequest('getTransportStats', { transportId: this._recvTransport.id });

				store.dispatch(
					transportActions.addTransportStats(recv, 'recv'));
			}

			if (this._sendTransport)
			{
				logger.debug('getTransportStats() - send [transportId: "%s"]', this._sendTransport.id);

				const send = await this.sendRequest('getTransportStats', { transportId: this._sendTransport.id });

				store.dispatch(
					transportActions.addTransportStats(send, 'send'));
			}
		}
		catch (error)
		{
			logger.error('getTransportStats() [error:"%o"]', error);
		}
	}
	*/

	async sendRequest(method, data)
	{
		logger.debug('sendRequest() [method:"%s", data:"%o"]', method, data);

		for (let tries = 0; tries < config.requestRetries; tries++)
		{
			try
			{
				return await this._sendRequest(method, data);
			}
			catch (error)
			{
				if (
					error instanceof SocketTimeoutError &&
					tries < config.requestRetries
				)
					logger.warn('sendRequest() | timeout, retrying [attempt:"%s"]', tries);
				else
					throw error;
			}
		}
	}

	async drawPathOnVideo(path, srcWidth, destPeerId, producerId)
	{
		try
		{
			await this.sendRequest('drawPathOnVideo', {
				path       : path,
				srcWidth   : srcWidth,
				destPeerId : destPeerId,
				producerId : producerId,
				srcPeerId  : this._peerId
			});
		}
		catch (error)
		{
			logger.error('drawPathOnVideo() [error:"%o"]', error);
		}
	}

	async removeDrawings(peerId, producerId)
	{
		try
		{
			await this.sendRequest('removeDrawings', { peerId, producerId });

			if (!peerId)
			{
				store.dispatch(producerActions.removeDrawings(null));
				store.dispatch(consumerActions.removeDrawings(null));
			}
			else if (peerId === this._peerId)
				store.dispatch(producerActions.removeDrawings(producerId));
			else
				store.dispatch(consumerActions.removeDrawings(producerId));

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.drawingsRemoved',
						defaultMessage : 'The drawings have been removed'
					})
				}));

		}
		catch (error)
		{
			logger.error('removeDrawings() [error:"%o"]', error);
		}
	}

	async changeDisplayName(displayName)
	{
		displayName = displayName.trim();

		if (!displayName)
			displayName = `Guest ${Math.floor(Math.random() * (100000 - 10000)) + 10000}`;

		logger.debug('changeDisplayName() [displayName:"%s"]', displayName);

		store.dispatch(
			meActions.setDisplayNameInProgress(true));

		try
		{
			await this.sendRequest('changeDisplayName', { displayName });

			store.dispatch(settingsActions.setDisplayName(displayName));

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.changedDisplayName',
						defaultMessage : 'Your display name changed to {displayName}'
					}, {
						displayName
					})
				}));

			this._displayName=displayName;

		}
		catch (error)
		{
			logger.error('changeDisplayName() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.changeDisplayNameError',
						defaultMessage : 'An error occurred while changing your display name'
					})
				}));
		}

		store.dispatch(
			meActions.setDisplayNameInProgress(false));
	}

	async changePicture(picture)
	{
		logger.debug('changePicture() [picture: "%s"]', picture);

		try
		{
			await this.sendRequest('changePicture', { picture });
		}
		catch (error)
		{
			logger.error('changePicture() [error:"%o"]', error);
		}
	}

	async sendChatMessage(chatMessage)
	{
		logger.debug('sendChatMessage() [chatMessage:"%s"]', chatMessage);

		try
		{
			store.dispatch(
				chatActions.addMessage(
					{
						...chatMessage,
						// name    : 'Me',
						sender  : 'client',
						picture : undefined,
						isRead  : true
					}
				)
			);

			store.dispatch(
				chatActions.setIsScrollEnd(true));

			await this.sendRequest('chatMessage', { chatMessage });

		}
		catch (error)
		{
			logger.error('sendChatMessage() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.chatError',
						defaultMessage : 'Unable to send chat message'
					})
				}));
		}
	}

	saveFile(file)
	{
		file.getBlob((err, blob) =>
		{
			if (err)
			{
				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'filesharing.saveFileError',
							defaultMessage : 'Unable to save file'
						})
					}));

				return;
			}

			saveAs(blob, file.name);
		});
	}

	async saveChat()
	{
		const html = window.document.getElementsByTagName('html')[0].cloneNode(true);

		const chatEl = html.querySelector('#chatList');

		html.querySelector('body').replaceChildren(chatEl);

		const fileName= 'chat.html';

		// remove unused tags
		[ 'script', 'link' ].forEach((element) =>
		{
			const el = html.getElementsByTagName(element);

			let i = el.length;

			while (i--) el[i].parentNode.removeChild(el[i]);
		});

		// embed images
		for await (const img of html.querySelectorAll('img'))
		{
			img.src = `${img.src}`;

			await fetch(img.src)

				.then((response) => response.blob())
				.then((data) =>
				{
					const reader = new FileReader();

					reader.readAsDataURL(data);

					reader.onloadend = () => { img.src = reader.result; };
				});
		}

		const blob = new Blob([ html.innerHTML ], { type: 'text/html;charset=utf-8' });

		saveAs(blob, fileName);
	}

	sortChat(order)
	{
		store.dispatch(
			chatActions.sortChat(order)
		);
	}

	handleDownload(magnetUri)
	{
		store.dispatch(
			fileActions.setFileActive(magnetUri));

		const existingTorrent = this._webTorrent.get(magnetUri);

		if (existingTorrent)
		{
			// Never add duplicate torrents, use the existing one instead.
			this._handleTorrent(existingTorrent);

			return;
		}

		this._webTorrent.add(magnetUri, this._handleTorrent);
	}

	_handleTorrent(torrent)
	{
		// Torrent already done, this can happen if the
		// same file was sent multiple times.
		if (torrent.progress === 1)
		{
			store.dispatch(
				fileActions.setFileDone(
					torrent.magnetURI,
					torrent.files
				));

			return;
		}

		// let lastMove = 0;

		torrent.on('download', () =>
		{
			// if (Date.now() - lastMove > 1000)
			// {
			store.dispatch(
				fileActions.setFileProgress(
					torrent.magnetURI,
					torrent.progress
				));

			// lastMove = Date.now();
			// }
		});

		torrent.on('done', () =>
		{
			store.dispatch(
				fileActions.setFileDone(
					torrent.magnetURI,
					torrent.files
				));
		});
	}

	_sleep(ms)
	{
		return new Promise((resolve) =>
		{
			setTimeout(resolve, ms);
		});
	}

	async _sendChunks(file, fileData)
	{
		const chunkSize = 100 * 1024; // 100kB
		const numberOfChunks = Math.ceil(file.size / chunkSize);

		let sentChunks = 0;

		while (sentChunks < numberOfChunks)
		{

			if (!store.getState().files.isUploading)
			{
				logger.debug('_sendChunk upload has been canceled');

				return;
			}

			const chunkStart = sentChunks * chunkSize;
			const chunkEnd = Math.min(chunkStart + chunkSize, file.size);
			const chunk = file.slice(chunkStart, chunkEnd);

			try
			{
				await this.sendRequest('sendFileChunk',
					{ chunk, fileId: fileData.fileId });
			}
			catch (error)
			{
				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'filesharing.unableToShare',
							defaultMessage : 'Unable to share file'
						})
					}));

				store.dispatch(fileActions.setIsUploading(false));

				return;
			}

			sentChunks++;

			await this._sleep(100);
		}

		const { magnetUri } = await this.sendRequest('sendFileFinished',
			{ fileId: fileData.fileId });

		fileData.magnetUri = magnetUri;

		store.dispatch(fileActions.addFile(fileData));

		store.dispatch(requestActions.notify(
			{
				text : intl.formatMessage({
					id             : 'filesharing.successfulFileShare',
					defaultMessage : 'File successfully shared'
				})
			}));

		store.dispatch(fileActions.setIsUploading(false));
	}

	shareFilesSocketIoCancel()
	{
		store.dispatch(fileActions.setIsUploading(false));

		// w przyszłości poinformować serwer, na razie po prostu nic sie nie stanie,
		// pliki czeciowe się wyczyszczą po wyjsciu
		/*
		try
		{
			await this.sendRequest('sendFileCancel', { });
		}
		catch (error)
		{
			logger.error('shareFilesSocketIoCancel - Error while cancel %o', error);
		}
		*/
	}

	async shareFilesSocketIo(data)
	{
		try
		{
			const file = data.attachment;
			const fileData = {
				...data,
				attachment : null
			};

			store.dispatch(fileActions.setIsUploading(true));

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'filesharing.startingFileShare',
						defaultMessage : 'Attempting to share file'
					})
				}));

			await this.sendRequest('sendFileStart', { fileData });

			this._sendChunks(file, fileData);
		}
		catch (error)
		{
			logger.error('shareFilesSocketIo() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'filesharing.unableToShare',
						defaultMessage : 'Unable to share file'
					})
				}));

			store.dispatch(fileActions.setIsUploading(false));
		}
	}

	async shareFiles(data)
	{
		if (!this._tracker || !this._webTorrent)
		{
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'filesharing.unableToShare',
						defaultMessage : 'Unable to share file'
					})
				}));

			return;
		}

		store.dispatch(requestActions.notify(
			{
				text : intl.formatMessage({
					id             : 'filesharing.startingFileShare',
					defaultMessage : 'Attempting to share file'
				})
			}));

		createTorrent(data.attachment, (err, torrent) =>
		{
			if (err)
			{
				logger.error('shareFiles createTorrent error', err);

				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'filesharing.unableToShare',
							defaultMessage : 'Unable to share file'
						})
					}));

				return;
			}

			const existingTorrent = this._webTorrent.get(torrent);

			if (existingTorrent)
			{
				store.dispatch(requestActions.notify(
					{
						text : intl.formatMessage({
							id             : 'filesharing.successfulFileShare',
							defaultMessage : 'File successfully shared'
						})
					}));

				const file = {
					...data,
					peerId     : this._peerId,
					magnetUri  : existingTorrent.magnetURI,
					attachment : null
				};

				store.dispatch(fileActions.addFile(file));

				this._sendFile(file);

				return;
			}

			this._webTorrent.seed(
				data.attachment,
				{
					announceList : [ [ this._tracker ] ]
				},
				(newTorrent) =>
				{
					store.dispatch(requestActions.notify(
						{
							text : intl.formatMessage({
								id             : 'filesharing.successfulFileShare',
								defaultMessage : 'File successfully shared'
							})
						}));

					const file = {
						...data,
						peerId     : this._peerId,
						magnetUri  : newTorrent.magnetURI,
						attachment : null
					};

					store.dispatch(fileActions.addFile(file));

					this._sendFile(file);
				});
		});
	}

	async _sendFile(file)
	{
		logger.debug('sendFile() [magnetUri:"%o"]', file.magnetUri);

		try
		{
			await this.sendRequest('sendFile', file);
		}
		catch (error)
		{
			logger.error('sendFile() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'filesharing.unableToShare',
						defaultMessage : 'Unable to share file'
					})
				}));
		}
	}

	async muteMic()
	{
		logger.debug('muteMic()');

		this._micProducer.pause();

		try
		{
			await this.sendRequest(
				'pauseProducer', { producerId: this._micProducer.id });

			store.dispatch(
				producerActions.setProducerPaused(this._micProducer.id));

			store.dispatch(
				settingsActions.setAudioMuted(true));

		}
		catch (error)
		{
			logger.error('muteMic() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.microphoneMuteError',
						defaultMessage : 'Unable to mute your microphone'
					})
				}));
		}
	}

	async unmuteMic()
	{
		logger.debug('unmuteMic()');

		if (!this._micProducer)
		{
			this.updateMic({ start: true });
		}
		else
		{
			this._micProducer.resume();

			try
			{
				await this.sendRequest(
					'resumeProducer', { producerId: this._micProducer.id });

				store.dispatch(
					producerActions.setProducerResumed(this._micProducer.id));

				store.dispatch(
					settingsActions.setAudioMuted(false));

			}
			catch (error)
			{
				logger.error('unmuteMic() [error:"%o"]', error);

				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'devices.microphoneUnMuteError',
							defaultMessage : 'Unable to unmute your microphone'
						})
					}));
			}
		}
	}

	getConfigMaxSpotlights()
	{
		let lastN = 1;

		if (this._device.platform === 'desktop')
		{
			lastN = config.lastN;

			if (config.lastNs[this._roomId])
				lastN = config.lastNs[this._roomId];
		}
		else
		{
			lastN = config.mobileLastN;

			if (config.mobileLastNs[this._roomId])
				lastN = config.mobileLastNs[this._roomId];
		}

		return lastN;
	}

	changeMaxSpotlights(maxSpotlights)
	{
		this._spotlights.maxSpotlights = maxSpotlights;

		store.dispatch(
			settingsActions.setLastN(maxSpotlights));
	}

	// Updated consumers based on spotlights
	async updateSpotlights(spotlights, selectedPeers, currentPage, pagesCount)
	{
		logger.debug('updateSpotlights() spotlights [%o], selectedPeers [%o], currentPage [%s], pagesCount [%s]',
			spotlights, selectedPeers, currentPage, pagesCount);

		store.dispatch(roomActions.setSpotlights(spotlights, currentPage, pagesCount));

		try
		{
			const meSelected = store.getState().me.selected;
			const peerWithPinId = store.getState().room.peerWithPinId;
			const fullScreenConsumerId = store.getState().room.fullScreenConsumer;

			for (const consumer of this._consumers.values())
			{
				if (consumer.kind === 'video')
				{
					if (spotlights.includes(consumer.appData.peerId))
					{
						logger.debug('updateSpotlights() resume for [peer: %s] [consumer: %s]',
							consumer.appData.peerId, consumer.id);

						await this._resumeConsumer(consumer);
					}
					else if (consumer.appData.peerId === peerWithPinId &&
						!meSelected && selectedPeers.length === 0)
					{
						logger.debug('updateSpotlights() resume peer with pin [peer: %s] [consumer: %s]',
							consumer.appData.peerId, consumer.id);

						await this._resumeConsumer(consumer);
					}
					else if (selectedPeers.includes(consumer.appData.peerId))
					{
						logger.debug('updateSpotlights() resume selected peer [peer: %s] [consumer: %s]',
							consumer.appData.peerId, consumer.id);

						await this._resumeConsumer(consumer);
					}
					else if (consumer.id === fullScreenConsumerId)
					{
						logger.debug('updateSpotlights() resume fullscreen peer [peer: %s] [consumer: %s]',
							consumer.appData.peerId, consumer.id);

						await this._resumeConsumer(consumer);
					}
					else
					{
						logger.debug('updateSpotlights() pause for [peer: %s] [consumer: %s]',
							consumer.appData.peerId, consumer.id);

						await this._pauseConsumer(consumer);
					}
				}
			}
		}
		catch (error)
		{
			logger.error('updateSpotlights() [error:"%o"]', error);
		}
	}

	disconnectLocalHark()
	{
		logger.debug('disconnectLocalHark()');

		if (this._harkStream != null)
		{
			let [ track ] = this._harkStream.getAudioTracks();

			track.stop();
			track = null;

			this._harkStream = null;
		}

		if (this._hark != null)
			this._hark.stop();
	}

	connectLocalHark(track)
	{
		logger.debug('connectLocalHark() [track:"%o"]', track);

		this._harkStream = new MediaStream();

		const newTrack = track.clone();

		this._harkStream.addTrack(newTrack);

		newTrack.enabled = true;

		this._hark = hark(this._harkStream,
			{
				play      : false,
				interval  : 10,
				threshold : store.getState().settings.noiseThreshold,
				history   : 100
			});

		this._hark.lastVolume = -100;

		this._hark.on('volume_change', (volume) =>
		{
			volume = Math.round(volume);

			// Update only if there is a bigger diff
			if (this._micProducer && Math.abs(volume - this._hark.lastVolume) > 0.5)
			{
				// Decay calculation: keep in mind that volume range is -100 ... 0 (dB)
				// This makes decay volume fast if difference to last saved value is big
				// and slow for small changes. This prevents flickering volume indicator
				// at low levels
				if (volume < this._hark.lastVolume)
				{
					volume = Math.round(
						this._hark.lastVolume -
						Math.pow(
							(volume - this._hark.lastVolume) /
							(100 + this._hark.lastVolume)
							, 2
						) * 10);
				}

				this._hark.lastVolume = volume;

				store.dispatch(peerVolumeActions.setPeerVolume(this._peerId, volume));
			}
		});

		this._hark.on('speaking', () =>
		{
			store.dispatch(meActions.setIsSpeaking(true));

			if (
				(store.getState().settings.voiceActivatedUnmute ||
				store.getState().me.isAutoMuted) &&
				this._micProducer &&
				this._micProducer.paused
			)
				this._micProducer.resume();

			store.dispatch(meActions.setAutoMuted(false)); // sanity action
		});

		this._hark.on('stopped_speaking', () =>
		{
			store.dispatch(meActions.setIsSpeaking(false));

			if (
				store.getState().settings.voiceActivatedUnmute &&
				this._micProducer &&
				!this._micProducer.paused
			)
			{
				this._micProducer.pause();

				store.dispatch(meActions.setAutoMuted(true));
			}
		});
	}

	async changeAudioOutputDevice(deviceId)
	{
		logger.debug('changeAudioOutputDevice() [deviceId:"%s"]', deviceId);

		store.dispatch(
			meActions.setAudioOutputInProgress(true));

		try
		{
			const device = this._audioOutputDevices[deviceId];

			if (!device)
			{
				logger.error('Selected audio output device no longer available');
				await this._updateAudioOutputDevices();
			}
			else
			{
				store.dispatch(settingsActions.setSelectedAudioOutputDevice(deviceId));
			}
		}
		catch (error)
		{
			logger.error('changeAudioOutputDevice() [error:"%o"]', error);
		}

		store.dispatch(
			meActions.setAudioOutputInProgress(false));
	}

	// Only Firefox supports applyConstraints to audio tracks
	// See:
	// https://bugs.chromium.org/p/chromium/issues/detail?id=796964
	async updateMic({
		start = false,
		restart = true,
		newDeviceId = null
	} = {})
	{
		logger.debug(
			'updateMic() [start:"%s", restart:"%s", newDeviceId:"%s"]',
			start,
			restart,
			newDeviceId
		);

		let track;

		try
		{
			if (!this._mediasoupDevice.canProduce('audio'))
				throw new Error('cannot produce audio');

			if (newDeviceId && !restart)
				throw new Error('changing device requires restart');

			if (newDeviceId)
				store.dispatch(settingsActions.setSelectedAudioDevice(newDeviceId));

			store.dispatch(meActions.setAudioInProgress(true));

			const deviceId = await this._getAudioDeviceId();
			const device = this._audioDevices[deviceId];

			if (!device)
				throw new Error('no audio devices');

			const {
				autoGainControl,
				echoCancellation,
				noiseSuppression,
				sampleRate,
				channelCount,
				sampleSize,
				opusStereo,
				opusDtx,
				opusFec,
				opusPtime,
				opusMaxPlaybackRate
			} = store.getState().settings;

			if (
				(restart && this._micProducer) ||
				start
			)
			{
				this.disconnectLocalHark();

				let muted = false;

				if (this._micProducer)
				{
					muted = this._micProducer.paused;
					await this.disableMic();
				}

				const deviceIdConstrains = getDeviceIdConstrains(deviceId);

				const stream = await navigator.mediaDevices.getUserMedia(
					{
						audio : {
							deviceId : deviceIdConstrains,
							sampleRate,
							channelCount,
							autoGainControl,
							echoCancellation,
							noiseSuppression,
							sampleSize
						}
					}
				);

				([ track ] = stream.getAudioTracks());

				const { deviceId: trackDeviceId } = track.getSettings();

				store.dispatch(settingsActions.setSelectedAudioDevice(trackDeviceId));

				const networkPriority = config.networkPriorities.audio;

				this._micProducer = await this._sendTransport.produce(
					{
						track,
						encodings :
						[
							{
								networkPriority
							}
						],
						codecOptions :
						{
							opusStereo          : opusStereo,
							opusFec             : opusFec,
							opusDtx             : opusDtx,
							opusMaxPlaybackRate : opusMaxPlaybackRate,
							opusPtime           : opusPtime
						},
						appData :
						{ source: 'mic' }
					});

				store.dispatch(producerActions.addProducer(
					{
						id            : this._micProducer.id,
						source        : 'mic',
						deviceLabel   : device.label,
						paused        : this._micProducer.paused,
						track         : this._micProducer.track,
						rtpParameters : this._micProducer.rtpParameters,
						codec         : this._micProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
					}));

				this._micProducer.on('transportclose', () =>
				{
					this._micProducer = null;
				});

				this._micProducer.on('trackended', () =>
				{
					store.dispatch(requestActions.notify(
						{
							type : 'error',
							text : intl.formatMessage({
								id             : 'devices.microphoneDisconnected',
								defaultMessage : 'Microphone disconnected'
							})
						}));

					this.disableMic();
				});

				this.connectLocalHark(track);
				if (muted) this.muteMic();
				else this.unmuteMic();
			}
			else if (this._micProducer)
			{
				({ track } = this._micProducer);

				await track.applyConstraints(
					{
						sampleRate,
						channelCount,
						autoGainControl,
						echoCancellation,
						noiseSuppression,
						sampleSize
					}
				);

				if (this._harkStream != null)
				{
					const [ harkTrack ] = this._harkStream.getAudioTracks();

					harkTrack && await harkTrack.applyConstraints(
						{
							sampleRate,
							channelCount,
							autoGainControl,
							echoCancellation,
							noiseSuppression,
							sampleSize
						}
					);
				}
			}

			// TODO update recorder inputs
			/*
			if (recorder != null)
			{
				recorder.addTrack(new MediaStream([ this._micProducer.track ]));
			}
			*/
			await this._updateAudioDevices();
		}
		catch (error)
		{
			logger.error('updateMic() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.microphoneError',
						defaultMessage : 'An error occurred while accessing your microphone'
					})
				}));

			if (track)
				track.stop();

			// even on error try getting the device list
			await this._updateAudioDevices();
		}

		store.dispatch(meActions.setAudioInProgress(false));
	}

	async updateWebcam({
		init = false,
		start = false,
		restart = false,
		newDeviceId = null,
		newResolution = null,
		newFrameRate = null,
		newVideoCodec = null
	} = {})
	{
		logger.debug(
			'updateWebcam() [start:"%s", restart:"%s", newDeviceId:"%s", newResolution:"%s", newFrameRate:"%s"]',
			start,
			restart,
			newDeviceId,
			newResolution,
			newFrameRate,
			newVideoCodec
		);

		let track;

		let {
			resolution,
			frameRate,
			videoCodec
		} = store.getState().settings;

		try
		{
			if (!this._mediasoupDevice.canProduce('video'))
				throw new Error('cannot produce video');

			if (newDeviceId && !restart)
				throw new Error('changing device requires restart');

			if (newVideoCodec && !restart)
				throw new Error('changing codec requires restart');

			if (newDeviceId)
				store.dispatch(settingsActions.setSelectedWebcamDevice(newDeviceId));

			if (this._encodingProblems.doSwitch === 'switch')
			{
				newVideoCodec = config.videoCodecProblem;

				if (newFrameRate > config.frameRateProblem)
					newFrameRate = config.frameRateProblem;

				const currentIndex =
					config.resolutionsAvailable.indexOf(newResolution);
				const problemIndex =
					config.resolutionsAvailable.indexOf(config.resolutionProblem);

				if (currentIndex === -1 || currentIndex > problemIndex)
					newResolution = config.resolutionProblem;
			}

			if (newVideoCodec)
			{
				store.dispatch(settingsActions.setVideoCodec(newVideoCodec));
				videoCodec = newVideoCodec;
			}

			if (newResolution)
			{
				store.dispatch(settingsActions.setVideoResolution(newResolution));
				resolution = newResolution;
			}

			if (newFrameRate)
			{
				store.dispatch(settingsActions.setVideoFrameRate(newFrameRate));
				frameRate = newFrameRate;
			}

			const {
				videoMuted, aspectRatio
			} = store.getState().settings;

			if (init && videoMuted)
				return;
			else
				store.dispatch(settingsActions.setVideoMuted(false));

			store.dispatch(meActions.setWebcamInProgress(true));

			let deviceId = await this._getWebcamDeviceId();

			let device = this._webcams[deviceId];

			if (!device)
				throw new Error('no webcam devices');

			const videoConstrains = getVideoConstrains(resolution, aspectRatio);

			let deviceIdConstrains = getDeviceIdConstrains(deviceId);

			if (
				(restart && this._webcamProducer) ||
				start
			)
			{
				if (this._webcamProducer)
					await this.disableWebcam();

				// disable also extra video but keep the list for restart
				let selectedExtraVideosCopy;

				if (restart && (newResolution || newFrameRate || newVideoCodec ||
					this._encodingProblems.doSwitch !== '')
				)
				{
					selectedExtraVideosCopy =
						[ ...store.getState().settings.selectedExtraVideos ];

					for (const producer of this._extraVideoProducers.values())
						await this.disableExtraVideo(producer.id);
				}

				let stream;

				try
				{
					stream = await navigator.mediaDevices.getUserMedia(
						{
							video :
							{
								deviceId : deviceIdConstrains,
								...videoConstrains,
								frameRate
							}
						});
				}
				catch (error)
				{
					logger.debug('updateWebcam() error, maybe permissions, will retry. Error: %s',
						error);

					await this._updateWebcams();

					deviceId = await this._getWebcamDeviceId();
					device = this._webcams[deviceId];

					if (!device)
						throw new Error('no webcam devices');

					deviceIdConstrains = getDeviceIdConstrains(deviceId);

					stream = await navigator.mediaDevices.getUserMedia(
						{
							video :
							{
								deviceId : deviceIdConstrains,
								...videoConstrains,
								frameRate
							}
						});
				}

				let trackDeviceId;

				let trackSettings;

				let videoBackgroundEffect = store.getState().settings.videoBackgroundEffect;

				if (resolution !== 'low' && resolution !== 'medium')
				{
					store.dispatch(settingsActions.setVideoBackgroundEffect('none'));
					videoBackgroundEffect = 'none';
				}

				if (this._encodingProblems.doSwitch === 'blur')
				{
					if (videoBackgroundEffect !== 'none')
						store.dispatch(settingsActions.setVideoBackgroundEffect('none'));

					const { canvasStream, origTrackDeviceId } =
						await this._useCanvasStream(stream, frameRate);

					stream = canvasStream;
					trackDeviceId = origTrackDeviceId;
					([ track ] = stream.getVideoTracks());
					trackSettings = track.getSettings();

					logger.debug('getUserMedia problem blur webcam using origTrackDeviceId %s track settings: %o',
						origTrackDeviceId, trackSettings);
				}
				else if (videoBackgroundEffect !== 'none')
				{
					const { canvasStream, origTrackDeviceId } =
						await this._blurBackground(stream, frameRate, videoBackgroundEffect);

					stream = canvasStream;
					trackDeviceId = origTrackDeviceId;
					([ track ] = stream.getVideoTracks());
					trackSettings = track.getSettings();

					logger.debug('getUserMedia blur background webcam using origTrackDeviceId %s track settings: %o',
						origTrackDeviceId, trackSettings);
				}
				else
				{
					([ track ] = stream.getVideoTracks());
					trackSettings = track.getSettings();
					trackDeviceId = trackSettings.deviceId;

					logger.debug('getUserMedia webcam using track settings:', trackSettings);
				}

				const { width, height } = trackSettings;

				store.dispatch(settingsActions.setSelectedWebcamDevice(trackDeviceId));

				const networkPriority = config.networkPriorities.mainVideo;
				const codecOptions = this._getCodecOptions(resolution);
				const codec = this._getVideoCodec(videoCodec);

				this._setVideoTrackContentHint(track, videoCodec.toLowerCase());

				logger.debug('ASTAGOR debug webcam codecs %o', this._mediasoupDevice.rtpCapabilities.codecs);
				logger.debug('ASTAGOR debug webcam videoCodec %s', videoCodec);
				logger.debug('ASTAGOR debug webcam codec %o', codec);
				logger.debug('ASTAGOR debug webcam codecOptions %o', codecOptions);

				if (videoCodec.toLowerCase() === 'vp9')
				{
					const encoding = this._getSvcEncoding(width, height);
					const resolutionScalings = getSvcResolutionScalings(encoding);

					encoding.networkPriority = networkPriority;

					logger.debug('ASTAGOR VP9 debug webcam encoding %o', encoding);
					logger.debug('ASTAGOR VP9 debug webcam resolutionScalings %o', resolutionScalings);

					this._webcamProducer = await this._sendTransport.produce(
						{
							track,
							codec,
							codecOptions,
							encodings : [ encoding ],
							appData   :
							{
								source   : 'webcam',
								deviceId : trackDeviceId,
								width,
								height,
								resolutionScalings
							}
						});
				}
				else if (videoCodec.toLowerCase() === 'vp8' &&
					this._useSimulcast && resolution !== 'ultra')
				{
					const encodings = this._getSimulcastEncodings(width, height);
					const resolutionScalings = getSimulcastResolutionScalings(encodings);

					/**
					 * TODO:
					 * I receive DOMException:
					 * Failed to execute 'addTransceiver' on 'RTCPeerConnection':
					 * Attempted to set an unimplemented parameter of RtpParameters.
					encodings.forEach((encoding) =>
					{
						encoding.networkPriority=networkPriority;
					});
					*/

					encodings[0].networkPriority=networkPriority;

					this._webcamProducer = await this._sendTransport.produce(
						{
							track,
							codec,
							codecOptions,
							encodings,
							appData :
							{
								source   : 'webcam',
								deviceId : trackDeviceId,
								width,
								height,
								resolutionScalings
							}
						});
				}
				else
				{
					this._webcamProducer = await this._sendTransport.produce({
						track,
						codec,
						codecOptions,
						encodings : [ { networkPriority } ],
						appData   :
						{
							source   : 'webcam',
							deviceId : trackDeviceId,
							width,
							height
						}
					});
				}

				// this._webcamProducer.appData.drawCanvas = drawCanvas;

				store.dispatch(producerActions.addProducer(
					{
						id            : this._webcamProducer.id,
						source        : 'webcam',
						deviceLabel   : device.label,
						paused        : this._webcamProducer.paused,
						track         : this._webcamProducer.track,
						rtpParameters : this._webcamProducer.rtpParameters,
						codec         : this._webcamProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
					}));

				this._webcamProducer.on('transportclose', () =>
				{
					this._webcamProducer = null;
				});

				this._webcamProducer.on('trackended', () =>
				{
					store.dispatch(requestActions.notify(
						{
							type : 'error',
							text : intl.formatMessage({
								id             : 'devices.cameraDisconnected',
								defaultMessage : 'Camera disconnected'
							})
						}));

					this.disableWebcam();
				});

				// add back extra videos on restart, but only inf not UHD
				if (selectedExtraVideosCopy && resolution !== 'ultra')
					this.addExtraVideos(selectedExtraVideosCopy,
						{
							newResolution : resolution,
							newFrameRate  : frameRate,
							newVideoCodec : videoCodec
						}
					);

				this._encodingProblems.videoStartTimes[this._webcamProducer.id] = Date.now();

				store.dispatch(settingsActions.setVideoMuted(false));
			}
			else if (this._webcamProducer)
			{
				({ track } = this._webcamProducer);

				await track.applyConstraints(
					{
						...videoConstrains,
						frameRate
					}
				);

				// Also change resolution of extra video producers
				for (const producer of this._extraVideoProducers.values())
				{
					({ track } = producer);

					await track.applyConstraints(
						{
							...videoConstrains,
							frameRate
						}
					);
				}
			}

			await this._updateWebcams();
		}
		catch (error)
		{
			logger.error('updateWebcam() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.cameraError',
						defaultMessage : 'An error occurred while accessing your camera'
					})
				}));

			if (track)
				track.stop();

			// even on error try getting the device list
			await this._updateWebcams();
		}

		store.dispatch(
			meActions.setWebcamInProgress(false));
	}

	addSelectedPeer(peerId)
	{
		logger.debug('addSelectedPeer() [peerId:"%s"]', peerId);

		store.dispatch(
			roomActions.addSelectedPeer(peerId));

		this._spotlights.addPeerToSelectedSpotlights(peerId);
	}

	setSelectedPeer(peerId)
	{
		logger.debug('setSelectedPeer() [peerId:"%s"]', peerId);

		this.clearSelectedPeers(false);

		if (peerId === this._peerId)
		{
			store.dispatch(meActions.setSelected(true));

			this._spotlights.requestSpotlightUpdate();
		}
		else
			this.addSelectedPeer(peerId);
	}

	removeSelectedPeer(peerId)
	{
		logger.debug('removeSelectedPeer() [peerId:"%s"]', peerId);

		this.setSoloVideoProducerId(null);

		if (peerId === this._peerId)
		{
			store.dispatch(meActions.setSelected(false));

			this._spotlights.requestSpotlightUpdate();
		}
		else
		{
			store.dispatch(
				roomActions.removeSelectedPeer(peerId));

			this._spotlights.removePeerFromSelectedSpotlights(peerId);
		}
	}

	clearSelectedPeers(doUpdateSpotlights = true)
	{
		logger.debug('clearSelectedPeers()');

		this.setSoloVideoProducerId(null);

		store.dispatch(
			roomActions.clearSelectedPeers());

		store.dispatch(meActions.setSelected(false));

		this._spotlights.clearPeersFromSelectedSpotlights(doUpdateSpotlights);
	}

	async promoteAllLobbyPeers()
	{
		logger.debug('promoteAllLobbyPeers()');

		store.dispatch(
			roomActions.setLobbyPeersPromotionInProgress(true));

		try
		{
			await this.sendRequest('promoteAllPeers');
		}
		catch (error)
		{
			logger.error('promoteAllLobbyPeers() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setLobbyPeersPromotionInProgress(false));
	}

	async promoteLobbyPeer(peerId)
	{
		logger.debug('promoteLobbyPeer() [peerId:"%s"]', peerId);

		store.dispatch(
			lobbyPeerActions.setLobbyPeerPromotionInProgress(peerId, true));

		try
		{
			await this.sendRequest('promotePeer', { peerId });
		}
		catch (error)
		{
			logger.error('promoteLobbyPeer() [error:"%o"]', error);
		}

		store.dispatch(
			lobbyPeerActions.setLobbyPeerPromotionInProgress(peerId, false));
	}

	async clearChat()
	{
		logger.debug('clearChat()');

		store.dispatch(
			roomActions.setClearChatInProgress(true));

		try
		{
			await this.sendRequest('moderator:clearChat');

			store.dispatch(chatActions.clearChat());

			store.dispatch(fileActions.clearFiles());
		}
		catch (error)
		{
			logger.error('clearChat() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setClearChatInProgress(false));
	}

	/*
	async clearFileSharing()
	{
		logger.debug('clearFileSharing()');

		store.dispatch(
			roomActions.setClearFileSharingInProgress(true));

		try
		{
			await this.sendRequest('moderator:clearFileSharing');

			store.dispatch(fileActions.clearFiles());
		}
		catch (error)
		{
			logger.error('clearFileSharing() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setClearFileSharingInProgress(false));
	}
	*/

	async givePeerRole(peerId, roleId)
	{
		logger.debug('givePeerRole() [peerId:"%s", roleId:"%s"]', peerId, roleId);

		store.dispatch(
			peerActions.setPeerModifyRolesInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:giveRole', { peerId, roleId });
		}
		catch (error)
		{
			logger.error('givePeerRole() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setPeerModifyRolesInProgress(peerId, false));
	}

	async removePeerRole(peerId, roleId)
	{
		logger.debug('removePeerRole() [peerId:"%s", roleId:"%s"]', peerId, roleId);

		store.dispatch(
			peerActions.setPeerModifyRolesInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:removeRole', { peerId, roleId });
		}
		catch (error)
		{
			logger.error('removePeerRole() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setPeerModifyRolesInProgress(peerId, false));
	}

	async kickPeer(peerId)
	{
		logger.debug('kickPeer() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setPeerKickInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:kickPeer', { peerId });
		}
		catch (error)
		{
			logger.error('kickPeer() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setPeerKickInProgress(peerId, false));
	}

	async mutePeer(peerId)
	{
		logger.debug('mutePeer() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setMutePeerInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:mute', { peerId });
		}
		catch (error)
		{
			logger.error('mutePeer() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setMutePeerInProgress(peerId, false));
	}

	async stopPeerVideo(peerId)
	{
		logger.debug('stopPeerVideo() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setStopPeerVideoInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:stopVideo', { peerId });
		}
		catch (error)
		{
			logger.error('stopPeerVideo() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setStopPeerVideoInProgress(peerId, false));
	}

	async stopPeerScreenSharing(peerId)
	{
		logger.debug('stopPeerScreenSharing() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setStopPeerScreenSharingInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:stopScreenSharing', { peerId });
		}
		catch (error)
		{
			logger.error('stopPeerScreenSharing() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setStopPeerScreenSharingInProgress(peerId, false));
	}

	async muteAllPeers()
	{
		logger.debug('muteAllPeers()');

		store.dispatch(
			roomActions.setMuteAllInProgress(true));

		try
		{
			await this.sendRequest('moderator:muteAll');
		}
		catch (error)
		{
			logger.error('muteAllPeers() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setMuteAllInProgress(false));
	}

	async stopAllPeerVideo()
	{
		logger.debug('stopAllPeerVideo()');

		store.dispatch(
			roomActions.setStopAllVideoInProgress(true));

		try
		{
			await this.sendRequest('moderator:stopAllVideo');
		}
		catch (error)
		{
			logger.error('stopAllPeerVideo() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setStopAllVideoInProgress(false));
	}

	async stopAllPeerScreenSharing()
	{
		logger.debug('stopAllPeerScreenSharing()');

		store.dispatch(
			roomActions.setStopAllScreenSharingInProgress(true));

		try
		{
			await this.sendRequest('moderator:stopAllScreenSharing');
		}
		catch (error)
		{
			logger.error('stopAllPeerScreenSharing() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setStopAllScreenSharingInProgress(false));
	}

	async closeMeeting()
	{
		logger.debug('closeMeeting()');

		store.dispatch(
			roomActions.setCloseMeetingInProgress(true));

		try
		{
			await this.sendRequest('moderator:closeMeeting');
		}
		catch (error)
		{
			logger.error('closeMeeting() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setCloseMeetingInProgress(false));

		// ASTAGOR START
		this.close(true);
		// ASTAGOR END
	}

	// type: mic/webcam/screen
	// mute: true/false
	async modifyPeerConsumer(peerId, type, mute)
	{
		logger.debug(
			'modifyPeerConsumer() [peerId:"%s", type:"%s"]',
			peerId,
			type
		);

		if (type === 'mic')
			store.dispatch(
				peerActions.setPeerAudioInProgress(peerId, true));
		else if (type === 'webcam')
			store.dispatch(
				peerActions.setPeerVideoInProgress(peerId, true));
		else if (type === 'screen')
			store.dispatch(
				peerActions.setPeerScreenInProgress(peerId, true));

		try
		{
			for (const consumer of this._consumers.values())
			{
				if (consumer.appData.peerId === peerId && consumer.appData.source === type)
				{
					if (mute)
						await this._pauseConsumer(consumer);
					else
						await this._resumeConsumer(consumer);
				}
			}
		}
		catch (error)
		{
			logger.error('modifyPeerConsumer() [error:"%o"]', error);
		}

		if (type === 'mic')
			store.dispatch(
				peerActions.setPeerAudioInProgress(peerId, false));
		else if (type === 'webcam')
			store.dispatch(
				peerActions.setPeerVideoInProgress(peerId, false));
		else if (type === 'screen')
			store.dispatch(
				peerActions.setPeerScreenInProgress(peerId, false));
	}

	async setAudioGain(micConsumer, peerId, audioGain)
	{
		logger.debug(
			'setAudioGain() [micConsumer:"%o", peerId:"%s", type:"%s"]',
			micConsumer,
			peerId,
			audioGain
		);

		if (!micConsumer)
		{
			return;
		}

		micConsumer.audioGain = audioGain;

		try
		{
			for (const consumer of this._consumers.values())
			{
				if (consumer.appData.peerId === peerId)
				{
					store.dispatch(consumerActions.setConsumerAudioGain(consumer.id, audioGain));
				}
			}
		}
		catch (error)
		{
			logger.error('setAudioGain() [error:"%o"]', error);
		}
	}

	async _pauseConsumer(consumer)
	{
		logger.debug('_pauseConsumer() [consumer:"%o"]', consumer);

		if (consumer.paused || consumer.closed)
			return;

		try
		{
			await this.sendRequest('pauseConsumer', { consumerId: consumer.id });

			consumer.pause();

			store.dispatch(
				consumerActions.setConsumerPaused(consumer.id, 'local'));

		}
		catch (error)
		{
			logger.error('_pauseConsumer() [consumerId: %s; error:"%o"]', consumer.id, error);
			if (error.notFoundInMediasoupError)
			{
				this._closeConsumer(consumer.id);
			}
		}
	}

	async _resumeConsumer(consumer, { initial = false } = {})
	{
		logger.debug('_resumeConsumer() [consumer:"%o"] [initial: "%s"]',
			consumer, initial);

		if ((!initial && !consumer.paused) || consumer.closed)
			return;

		try
		{
			logger.debug('_resumeConsumer() resuming [consumer:"%o"]',
				consumer);

			consumer.resume();
			await this.sendRequest('resumeConsumer', { consumerId: consumer.id });
			store.dispatch(
				consumerActions.setConsumerResumed(consumer.id, 'local'));
		}
		catch (error)
		{
			logger.error('_resumeConsumer() [consumerId: %s; error:"%o"]', consumer.id, error);
			if (error.notFoundInMediasoupError)
			{
				this._closeConsumer(consumer.id);
			}
		}
	}

	async _startConsumer(consumer)
	{
		return this._resumeConsumer(consumer, { initial: true });
	}

	async lowerPeerHand(peerId)
	{
		logger.debug('lowerPeerHand() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setPeerRaisedHandInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:lowerHand', { peerId });
		}
		catch (error)
		{
			logger.error('lowerPeerHand() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setPeerRaisedHandInProgress(peerId, false));
	}

	async setRaisedHand(raisedHand)
	{
		logger.debug('setRaisedHand: ', raisedHand);

		store.dispatch(
			meActions.setRaisedHandInProgress(true));

		try
		{
			await this.sendRequest('raisedHand', { raisedHand });

			store.dispatch(
				meActions.setRaisedHand(raisedHand));
		}
		catch (error)
		{
			logger.error('setRaisedHand() [error:"%o"]', error);

			// We need to refresh the component for it to render changed state
			store.dispatch(meActions.setRaisedHand(!raisedHand));
		}

		store.dispatch(
			meActions.setRaisedHandInProgress(false));
	}

	async setMaxSendingSpatialLayer(producer, spatialLayer)
	{
		if (!producer)
			return;

		logger.debug('setMaxSendingSpatialLayer() [producer.id "%s", spatialLayer:"%s"]',
			producer.id, spatialLayer);

		try
		{
			await producer.setMaxSpatialLayer(spatialLayer);
		}
		catch (error)
		{
			logger.error('setMaxSendingSpatialLayer() [error:"%o"]', error);
		}
	}

	async setConsumerPreferredLayers(consumerId, spatialLayer, temporalLayer)
	{
		logger.debug(
			'setConsumerPreferredLayers() [consumerId:"%s", spatialLayer:"%s", temporalLayer:"%s"]',
			consumerId, spatialLayer, temporalLayer);

		try
		{
			await this.sendRequest(
				'setConsumerPreferedLayers', { consumerId, spatialLayer, temporalLayer });

			store.dispatch(consumerActions.setConsumerPreferredLayers(
				consumerId, spatialLayer, temporalLayer));
		}
		catch (error)
		{
			logger.error('setConsumerPreferredLayers() [consumerId: %s; error:"%o"]', consumerId, error);
			if (error.notFoundInMediasoupError)
			{
				this._closeConsumer(consumerId);
			}
		}
	}

	async restartIce(transport, ice, delay)
	{
		logger.debug('restartIce() [transport:%o ice:%o delay:%d]', transport, ice, delay);

		if (!transport)
		{
			logger.error('restartIce(): missing valid transport object');

			return;
		}

		if (!ice)
		{
			logger.error('restartIce(): missing valid ice object');

			return;
		}

		clearTimeout(ice.timer);
		ice.timer = setTimeout(async () =>
		{
			try
			{
				if (ice.restarting)
				{
					return;
				}
				ice.restarting = true;

				const iceParameters = await this.sendRequest(
					'restartIce',
					{ transportId: transport.id });

				await transport.restartIce({ iceParameters });
				ice.restarting = false;
				logger.debug('ICE restarted');
			}
			catch (error)
			{
				logger.error('restartIce() [failed:%o]', error);

				ice.restarting = false;
				ice.timer = setTimeout(() =>
				{
					this.restartIce(transport, ice, delay * 2);
				}, delay);
			}
		}, delay);
	}

	setConsumerPreferredLayersMax(consumerId)
	{
		const consumer = store.getState().consumers[consumerId];

		if (!consumer)
			return;

		if (consumer.type === 'simple')
		{
			return;
		}

		logger.debug(
			'setConsumerPreferredLayersMax() [consumerId:"%s"]', consumer.id);

		if (consumer.preferredSpatialLayer !== consumer.spatialLayers -1 ||
			consumer.preferredTemporalLayer !== consumer.temporalLayers -1)
		{
			return this.setConsumerPreferredLayers(consumer.id,
				consumer.spatialLayers - 1, consumer.temporalLayers - 1);
		}
	}

	storeConsumerCurrentSize(consumerId, currentWidth, currentHeight)
	{
		if (!consumerId || !currentWidth || !currentHeight)
			return;

		logger.debug('storeConsumerCurrentSize consumerId = %s, currentWidth = %s, currentHeight = %s',
			consumerId, currentWidth, currentHeight);

		// specjalnie piszemy do obiektu a nie przez actions aby nie robić update state
		const consumer = store.getState().consumers[consumerId];

		if (consumer.currentWidth !== currentWidth)
			consumer.currentWidth = currentWidth;

		if (consumer.currentHeight !== currentHeight)
			consumer.currentHeight = currentHeight;
	}

	adaptConsumerPreferredLayers(consumerId, viewportWidth,
		viewportHeight, isSpeaker = false)
	{
		const consumer = store.getState().consumers[consumerId];

		if (!consumer)
			return;

		if (consumer.type === 'simple')
			return;

		if (!viewportWidth || !viewportHeight)
			return;

		// Hack signals TO Recorder should alwayst be best quality dla speaker
		if (store.getState().me.isRecorder &&
			config.recorderSpeakerMaxLayers &&
			isSpeaker)
		{
			return this.setConsumerPreferredLayersMax(consumer.id);
		}

		// Max jakość dla single video dla Vmix
		if (store.getState().me.isRecorder &&
			store.getState().me.recorderMode === 6
		)
		{
			return this.setConsumerPreferredLayersMax(consumer.id);
		}

		/*
		Na razie nie robimy tego, niech sie dostosowuje

		// Hack signals FROM OR should alwayst be best quality
		if (store.getState().peers[consumer.peerId] &&
			store.getState().peers[consumer.peerId].loggedInWithPin)
		{
			return this.setConsumerPreferredLayersMax(consumer.id);
		}
		*/

		const {
			id,
			preferredSpatialLayer,
			preferredTemporalLayer,
			width,
			height,
			currentWidth,
			currentHeight,
			currentSpatialLayer,
			resolutionScalings
		} = consumer;

		logger.debug(
			'adaptConsumerPreferredLayers() [consumer:"%o", viewportWidth:"%d", viewportHeight:"%d"]',
			consumer, viewportWidth, viewportHeight);

		let actualWidth = width;

		let actualHeight = height;

		if (typeof currentWidth === 'number' && typeof currentHeight === 'number' &&
			currentSpatialLayer < resolutionScalings.length)
		{
			const senderWidth = currentWidth * resolutionScalings[currentSpatialLayer];
			const senderHeight = currentHeight * resolutionScalings[currentSpatialLayer];

			if (senderWidth < width || senderHeight < height)
			{
				logger.debug('adaptConsumerPreferredLayers() detected resolution decrease - encoder auto decrease. Using current resolution %s x %s',
					senderWidth, senderHeight);

				actualWidth = senderWidth;
				actualHeight = senderHeight;
			}
		}

		let newPreferredSpatialLayer = 0;

		for (let i = 0; i < resolutionScalings.length; i++)
		{
			const levelWidth = actualWidth / resolutionScalings[i];
			const levelHeight = actualHeight / resolutionScalings[i];

			newPreferredSpatialLayer = i;

			if (viewportWidth <= levelWidth && viewportHeight <= levelHeight)
			{
				logger.debug('Astagor resolutionScalings[i] %s', resolutionScalings[i]);
				logger.debug('Astagor viewportWidth - levelWidth %s - %s', viewportWidth, levelWidth);
				logger.debug('Astagor viewportHeight - levelHeight %s - %s', viewportHeight, levelHeight);
				logger.debug('Astagor Break %s', newPreferredSpatialLayer);

				break;
			}
		}

		let newPreferredTemporalLayer = consumer.temporalLayers - 1;

		if (newPreferredSpatialLayer === 0 && newPreferredTemporalLayer > 0)
		{
			const lowestLevelWidth = actualWidth / resolutionScalings[0];
			const lowestLevelHeight = actualHeight / resolutionScalings[0];

			if (viewportWidth < lowestLevelWidth * 0.5
				&& viewportHeight < lowestLevelHeight * 0.5)
			{
				newPreferredTemporalLayer -= 1;
			}
			if (newPreferredTemporalLayer > 0
				&& viewportWidth < lowestLevelWidth * 0.25
				&& viewportHeight < lowestLevelHeight * 0.25)
			{
				newPreferredTemporalLayer -= 1;
			}
		}

		if (preferredSpatialLayer !== newPreferredSpatialLayer ||
			preferredTemporalLayer !== newPreferredTemporalLayer)
		{
			logger.debug('Astagor CALL new S layer Old %s  | New %s',
				preferredSpatialLayer, newPreferredSpatialLayer);

			logger.debug('Astagor CALL new T layer Old %s  | New %s',
				preferredTemporalLayer, newPreferredTemporalLayer);

			return this.setConsumerPreferredLayers(id,
				newPreferredSpatialLayer, newPreferredTemporalLayer);
		}

	}

	async setConsumerPriority(consumerId, priority)
	{
		logger.debug(
			'setConsumerPriority() [consumerId:"%s", priority:%d]',
			consumerId, priority);

		try
		{
			await this.sendRequest('setConsumerPriority', { consumerId, priority });

			store.dispatch(consumerActions.setConsumerPriority(consumerId, priority));
		}
		catch (error)
		{
			logger.error('setConsumerPriority() [consumerId: %s; error:"%o"]', consumerId, error);
			if (error.notFoundInMediasoupError)
			{
				this._closeConsumer(consumerId);
			}
		}
	}

	async requestConsumerKeyFrame(consumerId)
	{
		logger.debug('requestConsumerKeyFrame() [consumerId:"%s"]', consumerId);

		try
		{
			await this.sendRequest('requestConsumerKeyFrame', { consumerId });
		}
		catch (error)
		{
			logger.error('requestConsumerKeyFrame() [consumerId: %s; error:"%o"]', consumerId, error);
			if (error.notFoundInMediasoupError)
			{
				this._closeConsumer(consumerId);
			}
		}
	}

	async _loadDynamicImports()
	{

		/*
		({ default: createTorrent } = await import(

			/ * webpackPrefetch: true * /
			/ * webpackChunkName: "createtorrent" * /
			'create-torrent'
		));

		({ default: WebTorrent } = await import(

			/ * webpackPrefetch: true * /
			/ * webpackChunkName: "webtorrent" * /
			'webtorrent'
		));
		*/

		({ default: saveAs } = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "file-saver" */
			'file-saver'
		));

		({ default: ScreenShare } = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "screensharing" */
			'./ScreenShare'
		));

		mediasoupClient = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "mediasoup" */
			'mediasoup-client'
		);

		({ default: io } = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "socket.io" */
			'socket.io-client'
		));
	}

	async join({ roomId, joinVideo, joinAudio })
	{
		store.dispatch(meActions.setJoinInProgress(true));

		await this._loadDynamicImports();

		this._roomId = roomId;

		store.dispatch(roomActions.setRoomName(roomId));

		this.changeMaxSpotlights(this.getConfigMaxSpotlights());

		this._signalingUrl = getSignalingUrl(this._peerId, roomId);

		this._screenSharing = ScreenShare.create(this._device);

		this._signalingSocket = io(this._signalingUrl);

		store.dispatch(roomActions.setRoomState('connecting'));

		this._signalingSocket.on('connect', () =>
		{
			logger.debug('signaling Peer "connect" event');
		});

		this._signalingSocket.on('disconnect', (reason) =>
		{
			logger.warn('signaling Peer "disconnect" event [reason:"%s"]', reason);

			if (this._closed)
				return;

			if (reason === 'io server disconnect')
			{
				store.dispatch(requestActions.notify(
					{
						text : intl.formatMessage({
							id             : 'socket.disconnected',
							defaultMessage : 'You are disconnected'
						})
					}));

				this.close(false, true);

				// ASTAGOR if socket was closed by server then do not reconnect

				return;
			}

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'socket.reconnecting',
						defaultMessage : 'You are disconnected, attempting to reconnect'
					})
				}));

			if (this._screenSharingProducer)
			{
				this._screenSharingProducer.close();

				store.dispatch(
					producerActions.removeProducer(this._screenSharingProducer.id));

				this._screenSharingProducer = null;
			}

			if (this._webcamProducer)
			{
				this._webcamProducer.close();

				store.dispatch(
					producerActions.removeProducer(this._webcamProducer.id));

				this._webcamProducer = null;
			}

			for (const producer of this._extraVideoProducers.values())
			{
				producer.close();

				store.dispatch(
					producerActions.removeProducer(producer.id));
			}
			this._extraVideoProducers.clear();

			if (this._micProducer)
			{
				this._micProducer.close();

				store.dispatch(
					producerActions.removeProducer(this._micProducer.id));

				this._micProducer = null;
			}

			if (this._sendTransport)
			{
				this._sendTransport.close();

				this._sendTransport = null;
			}

			if (this._recvTransport)
			{
				this._recvTransport.close();

				this._recvTransport = null;
			}

			this._spotlights.clearSpotlights();

			store.dispatch(peerActions.clearPeers());
			store.dispatch(consumerActions.clearConsumers());
			store.dispatch(roomActions.clearSpotlights());
			store.dispatch(roomActions.setRoomState('connecting'));
		});

		this._signalingSocket.on('reconnect_failed', () =>
		{
			logger.warn('signaling Peer "reconnect_failed" event');

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'socket.disconnected',
						defaultMessage : 'You are disconnected'
					})
				}));

			this.close();
		});

		this._signalingSocket.on('reconnect', (attemptNumber) =>
		{
			logger.debug('signaling Peer "reconnect" event [attempts:"%s"]', attemptNumber);

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'socket.reconnected',
						defaultMessage : 'You are reconnected'
					})
				}));

			store.dispatch(roomActions.setRoomState('connected'));
		});

		this._signalingSocket.on('request', async (request, cb) =>
		{
			logger.debug(
				'socket "request" event [method:"%s", data:"%o"]',
				request.method, request.data);

			switch (request.method)
			{
				default:
				{
					logger.error('unknown request.method "%s"', request.method);

					cb(500, `unknown request.method "${request.method}"`);
				}
			}
		});

		this._signalingSocket.on('notification', async (notification) =>
		{
			logger.debug(
				'socket "notification" event [method:"%s", data:"%o"]',
				notification.method, notification.data);

			try
			{
				switch (notification.method)
				{

					case 'enteredLobby':
					{
						store.dispatch(roomActions.setInLobby(true));

						const { displayName } = store.getState().settings;
						const { picture } = store.getState().me;

						await this.sendRequest('changeDisplayName', { displayName });
						await this.sendRequest('changePicture', { picture });
						break;
					}

					case 'signInRequired':
					{
						store.dispatch(meActions.isRecorder(false));
						store.dispatch(meActions.loggedInWithPin(false));
						store.dispatch(meActions.loggedIn(false));
						store.dispatch(roomActions.setSignInRequired(true));

						break;
					}

					case 'overRoomLimit':
					{
						store.dispatch(roomActions.setOverRoomLimit(true));
						// ASTAGOR START
						window.location = `/${this._roomId}?overroomlimit=1`;
						// ASTAGOR END
						break;
					}

					case 'roomLockedPeerNotLoggedIn':
					{
						// ASTAGOR START
						window.location = `/${this._roomId}?roomLockedPeerNotLoggedIn=1`;
						// ASTAGOR END
						break;
					}

					case 'roomReady':
					{
						const { turnServers } = notification.data;

						this._turnServers = turnServers;

						store.dispatch(roomActions.toggleJoined());
						store.dispatch(roomActions.setInLobby(false));

						await this._joinRoom({ joinVideo, joinAudio });

						break;
					}

					case 'roomBack':
					{
						await this._joinRoom({
							joinVideo : !store.getState().settings.videoMuted,
							joinAudio : !store.getState().settings.audioMuted,
							returning : true
						});

						break;
					}

					case 'lockRoom':
					{
						store.dispatch(
							roomActions.setRoomLocked());

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.locked',
									defaultMessage : 'Room is now locked'
								})
							}));

						break;
					}

					case 'unlockRoom':
					{
						store.dispatch(
							roomActions.setRoomUnLocked());

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.unlocked',
									defaultMessage : 'Room is now unlocked'
								})
							}));

						break;
					}

					case 'parkedPeer':
					{
						const { peerId } = notification.data;

						store.dispatch(
							lobbyPeerActions.addLobbyPeer(peerId));
						store.dispatch(
							roomActions.setToolbarsVisible(true));

						this._soundNotification(notification.method);

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.newLobbyPeer',
									defaultMessage : 'New participant entered the lobby'
								})
							}));

						break;
					}

					case 'parkedPeers':
					{
						const { lobbyPeers } = notification.data;

						if (lobbyPeers.length > 0)
						{
							lobbyPeers.forEach((peer) =>
							{
								store.dispatch(
									lobbyPeerActions.addLobbyPeer(peer.id));

								store.dispatch(
									lobbyPeerActions.setLobbyPeerDisplayName(
										peer.displayName,
										peer.id
									)
								);

								store.dispatch(
									lobbyPeerActions.setLobbyPeerPicture(
										peer.picture,
										peer.id
									)
								);
							});

							store.dispatch(
								roomActions.setToolbarsVisible(true));

							this._soundNotification(notification.method);

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.newLobbyPeer',
										defaultMessage : 'New participant entered the lobby'
									})
								}));
						}

						break;
					}

					case 'lobby:peerClosed':
					{
						const { peerId } = notification.data;

						store.dispatch(
							lobbyPeerActions.removeLobbyPeer(peerId));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.lobbyPeerLeft',
									defaultMessage : 'Participant in lobby left'
								})
							}));

						break;
					}

					case 'lobby:promotedPeer':
					{
						const { peerId } = notification.data;

						store.dispatch(
							lobbyPeerActions.removeLobbyPeer(peerId));

						break;
					}

					case 'lobby:changeDisplayName':
					{
						const { peerId, displayName } = notification.data;

						store.dispatch(
							lobbyPeerActions.setLobbyPeerDisplayName(displayName, peerId));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.lobbyPeerChangedDisplayName',
									defaultMessage : 'Participant in lobby changed name to {displayName}'
								}, {
									displayName
								})
							}));

						if (peerId === this._peerId)
							this._displayName=displayName;

						break;
					}

					case 'lobby:changePicture':
					{
						const { peerId, picture } = notification.data;

						store.dispatch(
							lobbyPeerActions.setLobbyPeerPicture(picture, peerId));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.lobbyPeerChangedPicture',
									defaultMessage : 'Participant in lobby changed picture'
								})
							}));

						break;
					}

					case 'setAccessCode':
					{
						const { accessCode } = notification.data;

						store.dispatch(
							roomActions.setAccessCode(accessCode));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.setAccessCode',
									defaultMessage : 'Access code for room updated'
								})
							}));

						break;
					}

					case 'setJoinByAccessCode':
					{
						const { joinByAccessCode } = notification.data;

						store.dispatch(
							roomActions.setJoinByAccessCode(joinByAccessCode));

						if (joinByAccessCode)
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.accessCodeOn',
										defaultMessage : 'Access code for room is now activated'
									})
								}));
						}
						else
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.accessCodeOff',
										defaultMessage : 'Access code for room is now deactivated'
									})
								}));
						}

						break;
					}

					case 'activeSpeaker':
					{
						const { peerId } = notification.data;

						store.dispatch(
							roomActions.setRoomActiveSpeaker(peerId));

						if (peerId && peerId !== this._peerId)
							this._spotlights.handleActiveSpeaker(peerId);

						break;
					}

					case 'changeDisplayName':
					{
						const { peerId, displayName, oldDisplayName } = notification.data;

						store.dispatch(
							peerActions.setPeerDisplayName(displayName, peerId));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.peerChangedDisplayName',
									defaultMessage : '{oldDisplayName} is now {displayName}'
								}, {
									oldDisplayName,
									displayName
								})
							}));

						if (peerId === this._peerId)
							this._displayName=displayName;

						break;
					}

					case 'changePicture':
					{
						const { peerId, picture } = notification.data;

						store.dispatch(peerActions.setPeerPicture(peerId, picture));

						break;
					}

					case 'raisedHand':
					{
						const {
							peerId,
							raisedHand,
							raisedHandTimestamp
						} = notification.data;

						store.dispatch(
							peerActions.setPeerRaisedHand(
								peerId,
								raisedHand,
								raisedHandTimestamp
							)
						);

						const { displayName } = store.getState().peers[peerId];

						let text;

						if (raisedHand)
						{
							text = intl.formatMessage({
								id             : 'room.raisedHand',
								defaultMessage : '{displayName} raised their hand'
							}, {
								displayName
							});
						}
						else
						{
							text = intl.formatMessage({
								id             : 'room.loweredHand',
								defaultMessage : '{displayName} put their hand down'
							}, {
								displayName
							});
						}

						if (displayName)
						{
							store.dispatch(requestActions.notify(
								{
									text
								}));
						}

						this._soundNotification(notification.method);

						break;
					}

					case 'sendConsultationRequestResult':
					{
						const { error, id } = notification.data;

						if (error)
						{
							this._setConsultationStatus(id, 'error');

						}
						else
						{
							this._setConsultationStatus(id, 'sent');
						}

						break;
					}

					case 'recorderSetFullscreenConsumer':
					{
						if (!store.getState().me.isRecorder)
							break;

						if (this._isNotFollowRecorder())
							break;

						const { peerId, producerId } = notification.data;

						let consumerId = null;

						for (const aConsumer of this._consumers.values())
						{
							if (aConsumer.appData.peerId === peerId &&
								aConsumer.producerId === producerId)
							{
								consumerId = aConsumer.id;

								break;
							}
						}

						if (!consumerId)
							break;

						if (store.getState().room.fullScreenConsumer === consumerId)
							break;

						store.dispatch(roomActions.toggleConsumerFullscreen(consumerId));

						break;
					}

					case 'recorderRemoveFullscreenConsumer':
					{
						if (!store.getState().me.isRecorder)
							break;

						if (this._isNotFollowRecorder())
							break;

						store.dispatch(roomActions.toggleConsumerFullscreen(null));

						break;
					}

					case 'recorderSetSelectedPeer':
					{
						if (!store.getState().me.isRecorder)
							break;

						if (this._isNotFollowRecorder())
							break;

						const { peerId } = notification.data;

						if (!store.getState().peers[peerId])
							break;

						const recorderMode = store.getState().me.recorderMode;

						if (recorderMode === 43)
						{
							this.changeMaxSpotlights(this.getConfigMaxSpotlights() + 1);
							store.dispatch(meActions.setRecorderMode(34));
						}

						// uwaga usuwa też solo peer
						this.setSelectedPeer(peerId);

						break;
					}

					case 'recorderRemoveSelectedPeer':
					{
						if (!store.getState().me.isRecorder)
							break;

						if (this._isNotFollowRecorder())
							break;

						const recorderMode = store.getState().me.recorderMode;

						if (recorderMode === 34)
						{
							store.dispatch(meActions.setRecorderMode(43));
							this.changeMaxSpotlights(100);
						}

						// hack w medVC jest tylko 1 selected bo filmstrip
						// uwaga usuwa też solo peer
						this.clearSelectedPeers();

						break;
					}

					case 'recorderSetSoloVideo':
					{
						if (!store.getState().me.isRecorder)
							break;

						if (this._isNotFollowRecorder())
							break;

						const { peerId, producerId } = notification.data;

						if (!store.getState().peers[peerId])
							break;

						const recorderMode = store.getState().me.recorderMode;

						if (recorderMode === 43)
						{
							this.changeMaxSpotlights(this.getConfigMaxSpotlights() + 1);
							store.dispatch(meActions.setRecorderMode(34));
						}

						// uwaga usuwa też solo peer
						this.setSelectedPeer(peerId);

						for (const aConsumer of this._consumers.values())
						{
							if (aConsumer.appData.peerId === peerId &&
								aConsumer.producerId === producerId)
							{
								this.setSoloVideoProducerId(producerId);

								break;
							}
						}

						break;
					}

					case 'recorderRemoveSoloVideo':
					{
						if (!store.getState().me.isRecorder)
							break;

						if (this._isNotFollowRecorder())
							break;

						this.setSoloVideoProducerId(null);

						break;
					}

					case 'recorderRecordingStoppedStatus':
					{
						store.dispatch(roomActions.setRecorderState('stopped'));

						if (store.getState().me.loggedInWithPin)
						{
							store.dispatch(requestActions.notify(
								{
									type : 'error',
									text : intl.formatMessage({
										id             : 'room.recorderRecordingStatusError',
										defaultMessage : 'A server error caused the recording to stop'
									})
								}));
						}

						break;
					}

					case 'recorderStreamingStoppedStatus':
					{
						store.dispatch(roomActions.setStreamerState('stopped'));

						if (store.getState().me.loggedInWithPin)
						{
							store.dispatch(requestActions.notify(
								{
									type : 'error',
									text : intl.formatMessage({
										id             : 'room.recorderStreamingStatusError',
										defaultMessage : 'A server error caused the streaming to stop'
									})
								}));
						}

						break;
					}

					case 'recorderStartRecordingResult':
					{
						const { error } = notification.data;

						if (error)
						{
							store.dispatch(roomActions.setRecorderState('stopped'));

							if (store.getState().me.loggedInWithPin)
							{
								store.dispatch(requestActions.notify(
									{
										type : 'error',
										text : intl.formatMessage({
											id             : 'room.recorderRequestError',
											defaultMessage : 'The request to the video recorder has failed'
										})
									}));
							}
						}
						else
						{
							store.dispatch(roomActions.setRecorderState('started'));

							/*
							// Już nie muszę bo server pamięta stan nagrywania

							// hack we remove selected peer to start recording in same layout
							// tu sprawdzamy streamer state
							if (store.getState().me.loggedInWithPin &&
								store.getState().room.streamerState !== 'started')
							{
								this.clearSelectedPeers();
								this.setSoloVideoProducerId(null);
								if (store.getState().room.fullScreenProducer)
									store.dispatch(roomActions.toggleProducerFullscreen(null));
								if (store.getState().room.fullScreenConsumer)
									store.dispatch(roomActions.toggleConsumerFullscreen(null));
							}
							*/
						}

						break;
					}

					case 'recorderStopRecordingResult':
					{
						const { error } = notification.data;

						if (error)
						{
							store.dispatch(roomActions.setRecorderState('started'));

							if (store.getState().me.loggedInWithPin)
							{
								store.dispatch(requestActions.notify(
									{
										type : 'error',
										text : intl.formatMessage({
											id             : 'room.recorderRequestError',
											defaultMessage : 'The request to the video recorder has failed'
										})
									}));
							}
						}
						else
						{
							store.dispatch(roomActions.setRecorderState('stopped'));
						}

						break;
					}

					case 'recorderStartStreamingResult':
					{
						const { error } = notification.data;

						if (error)
						{
							store.dispatch(roomActions.setStreamerState('stopped'));

							if (store.getState().me.loggedInWithPin)
							{
								store.dispatch(requestActions.notify(
									{
										type : 'error',
										text : intl.formatMessage({
											id             : 'room.recorderRequestError',
											defaultMessage : 'The request to the video recorder has failed'
										})
									}));
							}
						}
						else
						{
							store.dispatch(roomActions.setStreamerState('started'));

							/*
							// Już nie muszę bo server pamięta stan nagrywania

							// hack we remove selected peer to start recording in same layout
							// tu sprawdzamy recorder state
							if (store.getState().me.loggedInWithPin &&
								store.getState().room.recorderState !== 'started')
							{
								this.clearSelectedPeers();
								this.setSoloVideoProducerId(null);
								if (store.getState().room.fullScreenProducer)
									store.dispatch(roomActions.toggleProducerFullscreen(null));
								if (store.getState().room.fullScreenConsumer)
									store.dispatch(roomActions.toggleConsumerFullscreen(null));
							}
							*/
						}

						break;
					}

					case 'recorderStopStreamingResult':
					{
						const { error } = notification.data;

						if (error)
						{
							store.dispatch(roomActions.setStreamerState('started'));

							if (store.getState().me.loggedInWithPin)
							{
								store.dispatch(requestActions.notify(
									{
										type : 'error',
										text : intl.formatMessage({
											id             : 'room.recorderRequestError',
											defaultMessage : 'The request to the video recorder has failed'
										})
									}));
							}
						}
						else
						{
							store.dispatch(roomActions.setStreamerState('stopped'));
						}

						break;
					}

					case 'uploadSnapshotResult':
					{
						const { error, errorMessage, snapshotId, message } = notification.data;

						if (error)
						{
							logger.debug('uploadSnapshotResult - Error uploading image id: %s; %s',
								snapshotId, errorMessage);

							store.dispatch(meActions.updateUploadSnapshotStatus(snapshotId, 'error'));
						}
						else
						{
							logger.debug('uploadSnapshotResult - Successful upload of image id: %s; %s',
								snapshotId, message);

							store.dispatch(meActions.updateUploadSnapshotStatus(snapshotId, 'uploaded'));
						}

						break;
					}

					case 'requestSnapshot':
					{
						const { snapshotProducerId, requestPeerId } = notification.data;

						const producer = this._getSnapshotProducer(snapshotProducerId);

						const snapshot = await this._createSnapshot(producer, 0.9);

						if (snapshot)
						{
							this.setSnapshot(this._peerId, producer, snapshot, requestPeerId);
						}
						else
						{
							try
							{
								await this.sendRequest('requestSnapshotFailed',
									{
										requestPeerId
									});
							}
							catch (error)
							{
								logger.error('requestSnapshot() [error:"%o"]', error);
							}
						}

						break;
					}

					case 'requestSnapshotFailed':
					{
						store.dispatch(requestActions.notify(
							{
								type : 'error',
								text : intl.formatMessage({
									id             : 'room.requestSnapshotError',
									defaultMessage : 'The request to pause the video has failed'
								})
							}));

						this._setUploadSnapshotInProgress(false);

						break;
					}

					case 'requestPeerSnapshot':
					{
						const { responsePeerId, snapshotProducerId } = notification.data;
						const producer = this._getSnapshotProducer(snapshotProducerId);
						const snapshot = await this._takeSnapshot(producer);

						try
						{
							await this.sendRequest('responsePeerSnapshot',
								{
									snapshotPeerId : this._peerId,
									responsePeerId,
									snapshotProducerId,
									snapshot
								});
						}
						catch (error)
						{
							logger.error('requestPeerSnapshot() [error:"%o"]', error);
						}

						break;
					}

					case 'responsePeerSnapshot':
					{
						const { snapshotPeerId, snapshotProducerId, snapshot } = notification.data;

						if (snapshot)
						{
							const now = new Date();
							const snapshotTitle = format(now, 'yyyy-MM-dd HH:mm:ss');
							const snapshotFileName = format(now, 'yyyy-MM-dd_HH-mm-ss');
							const snapshotId = `${snapshotFileName}_${Math.floor(Math.random() * 1000000)}`;
							const snapshotStatus = 'new';
							const uploadSnapshotCurrent = {
								snapshotId,
								snapshotTitle,
								snapshotFileName,
								snapshotStatus,
								snapshot,
								producerId : snapshotProducerId
							};

							if (this._uploadSnapshotCurrentTimeout)
							{
								clearTimeout(this._uploadSnapshotCurrentTimeout);
								this._uploadSnapshotCurrentTimeout = null;
							}

							store.dispatch(
								meActions.addToUploadSnapshots(uploadSnapshotCurrent));

							store.dispatch(
								meActions.setUploadSnapshotCurrent(uploadSnapshotCurrent));

							store.dispatch(toolareaActions.incSnapshotCount());

							if (
								!store.getState().toolarea.toolAreaOpen ||
								(store.getState().toolarea.toolAreaOpen &&
								store.getState().toolarea.currentToolTab !== 'snapshots')
							) // Make sound
							{
								store.dispatch(
									roomActions.setToolbarsVisible(true));
								this._soundNotification(notification.method);
							}

							this._uploadSnapshotCurrentTimeout = setTimeout(() =>
								this._removeUploadSnapshotCurrent(), 2000);

							if (store.getState().room.isUploadSnapshotEnabled)
								await this.sendRequest('uploadSnapshot', { ...uploadSnapshotCurrent });
						}
						else
						{
							logger.error('responsePeerSnapshot() No snapshot has been rturned by peerId [%s], producerId [%s]',
								snapshotPeerId, snapshotProducerId);
						}

						this._setUploadSnapshotInProgress(false);

						break;
					}

					case 'setSnapshot':
					{
						const { snapshotProducerId, snapshot, requestPeerId } = notification.data;

						store.dispatch(consumerActions.setSnapshot(snapshotProducerId, snapshot));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.setSnapshot',
									defaultMessage : 'The video has been paused for all'
								})
							}));

						if (this._peerId === requestPeerId)
						{
							this._setUploadSnapshotInProgress(false);
						}

						break;
					}

					case 'removeSnapshot':
					{
						const { snapshotPeerId, snapshotProducerId } = notification.data;

						if (snapshotPeerId === this._peerId)
						{
							store.dispatch(producerActions.removeSnapshot(snapshotProducerId));
						}
						else
						{
							store.dispatch(consumerActions.removeSnapshot(snapshotProducerId));
						}

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.removeSnapshot',
									defaultMessage : 'The video is again live for all'
								})
							}));

						break;
					}

					case 'drawPathOnVideo':
					{
						const { peerId, path, srcWidth, producerId } = notification.data;

						// this is used for drawing on canvases
						if (peerId === this._peerId)
						{
							store.dispatch(producerActions.addPathToDraw(producerId, path, srcWidth));
						}
						else
						{
							store.dispatch(consumerActions.addPathToDraw(producerId, path, srcWidth));
						}

						/*
						// this is used for drawing on source stream
						let producer;

						if (this._webcamProducer && this._webcamProducer.id === producerId)
						{
							producer = this._webcamProducer;
						}
						else if (this._screenSharingProducer &&
							this._screenSharingProducer.id === producerId)
						{
							producer = this._screenSharingProducer;
						}
						else if (this._extraVideoProducers)
						{
							for (const extraVideoProducer of this._extraVideoProducers.values())
							{
								if (extraVideoProducer.id === producerId)
								{
									producer = extraVideoProducer;

									break;
								}
							}
						}

						if (producer && producer.appData.drawCanvas)
						{
							const canvas = producer.appData.drawCanvas;
							const factor = canvas.width / srcWidth;

							fabric.util.enlivenObjects([ path ], (paths) =>
							{
								paths.forEach((pathObject) =>
								{
									pathObject.scaleX = pathObject.scaleX * factor;
									pathObject.scaleY = pathObject.scaleY * factor;
									pathObject.left = pathObject.left * factor;
									pathObject.top = pathObject.top * factor;
									pathObject.strokeWidth = pathObject.strokeWidth / factor;
									pathObject.setCoords();

									canvas.add(pathObject);
								});
							});
						}
						*/

						break;
					}

					case 'removeDrawings':
					{
						const { peerId, producerId } = notification.data;

						if (!peerId)
						{
							store.dispatch(producerActions.removeDrawings(null));
							store.dispatch(consumerActions.removeDrawings(null));
						}
						else if (peerId === this._peerId)
							store.dispatch(producerActions.removeDrawings(producerId));
						else
							store.dispatch(consumerActions.removeDrawings(producerId));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.drawingsRemoved',
									defaultMessage : 'The drawings have been removed'
								})
							}));

						break;
					}

					case 'chatMessage':
					{
						const { peerId, chatMessage } = notification.data;

						store.dispatch(
							chatActions.addMessage({ ...chatMessage, peerId, isRead: false }));

						if (
							!store.getState().toolarea.toolAreaOpen ||
							(store.getState().toolarea.toolAreaOpen &&
							store.getState().toolarea.currentToolTab !== 'chat')
						) // Make sound
						{
							store.dispatch(
								roomActions.setToolbarsVisible(true));
							this._soundNotification(notification.method);
						}

						break;
					}

					case 'moderator:clearChat':
					{
						store.dispatch(chatActions.clearChat());

						store.dispatch(fileActions.clearFiles());

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'moderator.clearChat',
									defaultMessage : 'Moderator cleared the chat'
								})
							}));

						break;
					}

					case 'sendFile':
					{
						const file = notification.data;

						store.dispatch(fileActions.addFile({ ...file }));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.newFile',
									defaultMessage : 'New file available'
								})
							}));

						if (
							!store.getState().toolarea.toolAreaOpen ||
							(store.getState().toolarea.toolAreaOpen &&
							store.getState().toolarea.currentToolTab !== 'chat')
						) // Make sound
						{
							store.dispatch(
								roomActions.setToolbarsVisible(true));
							this._soundNotification(notification.method);
						}

						break;
					}

					/*
					case 'moderator:clearFileSharing':
					{
						store.dispatch(fileActions.clearFiles());

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'moderator.clearFiles',
									defaultMessage : 'Moderator cleared the files'
								})
							}));

						break;
					}
					*/

					case 'newPeer':
					{
						const {
							newPeer,
							returning
						} = notification.data;

						newPeer.consumers = [];

						store.dispatch(peerActions.addPeer(newPeer));

						if (newPeer.loggedInWithPin)
							store.dispatch(roomActions.setPeerWithPinId(newPeer.id));

						this._spotlights.newPeer(newPeer);

						if (newPeer.id in this._consumerQueue)
						{
							while (this._consumerQueue[newPeer.id].length > 0)
							{
								const consumerData = this._consumerQueue[newPeer.id].shift();

								await this._createConsumer(consumerData);
							}
						}

						if (!returning && !newPeer.isRecorder)
						{
							this._soundNotification(notification.method);

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.newPeer',
										defaultMessage : '{displayName} joined the room'
									}, {
										displayName : newPeer.displayName
									})
								}));
						}

						break;
					}

					case 'peerClosed':
					{
						const { peerId, loggedInWithPin } = notification.data;

						for (const consumer of this._consumers.values())
						{
							if (peerId === consumer.appData.peerId)
							{
								this._closeConsumer(consumer.id);
							}
						}

						this._spotlights.closePeer(peerId);

						if (loggedInWithPin)
						{
							store.dispatch(roomActions.removePeerWithPinId());
						}

						if (this._webTorrent)
						{
							const filesToRemove = store.getState().files.files.filter((file) =>
							{
								return (file.peerId === peerId);
							});

							filesToRemove.forEach((file) =>
							{
								const existingTorrent = this._webTorrent.get(file.magnetUri);

								if (existingTorrent)
								{
									this._webTorrent.remove(file.magnetUri, {}, (error) =>
									{
										if (error)
											logger.error('this._webTorrent.remove error',
												error);
										else
											logger.debug('this._webTorrent.remove OK file.magnetUri: %s',
												file.magnetUri);
									});
								}
							});
						}

						store.dispatch(
							fileActions.removeFilesOfPeer(peerId));

						store.dispatch(
							peerActions.removePeer(peerId));

						break;
					}

					case 'newConsumer':
					{
						const {
							peerId,
							producerId,
							id,
							kind,
							rtpParameters,
							type,
							appData,
							producerPaused,
							score
						} = notification.data;

						const consumerData = {
							peerId,
							producerId,
							id,
							kind,
							rtpParameters,
							type,
							appData,
							producerPaused,
							score
						};

						const storePeers = store.getState().peers;

						if (!storePeers[peerId])
						{
							if (!(peerId in this._consumerQueue))
								this._consumerQueue[peerId] = [];

							this._consumerQueue[peerId].push(consumerData);

							logger.debug('newConsumer - peerId %s not yet in store, adding to _consumerQueue',
								peerId);
						}
						else
						{
							await this._createConsumer(consumerData);
						}

						break;
					}

					case 'consumerClosed':
					{
						const { consumerId } = notification.data;

						this._closeConsumer(consumerId);

						break;
					}

					case 'consumerPaused':
					{
						const { consumerId } = notification.data;
						const consumer = this._consumers.get(consumerId);

						if (!consumer)
							break;

						store.dispatch(
							consumerActions.setConsumerPaused(consumerId, 'remote'));

						this._spotlights.pauseVideoConsumer(consumerId);

						break;
					}

					case 'consumerResumed':
					{
						const { consumerId } = notification.data;
						const consumer = this._consumers.get(consumerId);

						if (!consumer)
							break;

						store.dispatch(
							consumerActions.setConsumerResumed(consumerId, 'remote'));

						this._spotlights.resumeVideoConsumer(consumerId);

						break;
					}

					case 'consumerLayersChanged':
					{
						const { consumerId, spatialLayer, temporalLayer } = notification.data;
						const consumer = this._consumers.get(consumerId);

						if (!consumer)
							break;

						store.dispatch(consumerActions.setConsumerCurrentLayers(
							consumerId, spatialLayer, temporalLayer));

						break;
					}

					case 'moderator:moveToUrl':
					{
						if (store.getState().me.isRecorder)
							break;

						const { url } = notification.data;

						this.close(false, false, url);

						break;
					}

					case 'moderator:mute':
					{
						if (store.getState().me.isRecorder)
							break;

						if (this._micProducer && !this._micProducer.paused)
						{
							this.muteMic();

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'moderator.muteAudio',
										defaultMessage : 'Moderator muted your audio'
									})
								}));
						}

						break;
					}

					case 'moderator:unmute':
					{
						if (store.getState().me.isRecorder)
							break;

						if (this._micProducer && this._micProducer.paused)
						{
							this.unmuteMic();

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'devices.microphoneUnMute',
										defaultMessage : 'Unmuted your microphone'
									})
								}));
						}
						else if (!this._micProducer)
						{
							this.updateMic({ start: true });

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'devices.microphoneEnable',
										defaultMessage : 'Enabled your microphone'
									})
								}));
						}

						break;
					}

					case 'moderator:stopVideo':
					{
						if (store.getState().me.isRecorder)
							break;

						this.disableWebcam();

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'moderator.muteVideo',
									defaultMessage : 'Moderator stopped your video'
								})
							}));

						break;
					}

					case 'moderator:startVideo':
					{
						if (store.getState().me.isRecorder)
							break;

						this.updateWebcam({ start: true });

						break;
					}

					case 'moderator:stopScreenSharing':
					{
						if (store.getState().me.isRecorder)
							break;

						this.disableScreenSharing();

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'moderator.stopScreenSharing',
									defaultMessage : 'Moderator stopped your screen sharing'
								})
							}));

						break;
					}

					case 'moderator:setDisplayName':
					{
						if (store.getState().me.isRecorder)
							break;

						const { displayName } = notification.data;

						this.changeDisplayName(displayName);

						break;
					}

					// ASTAGOR START
					case 'moderator:closeMeeting':
					{
						this.close(true);

						break;
					}
					// ASTAGOR END

					case 'moderator:kick':
					{
						// Need some feedback
						// ASTAGOR START
						// this.close();
						this.close(true);
						// ASTAGOR END

						break;
					}

					case 'moderator:lowerHand':
					{
						this.setRaisedHand(false);

						break;
					}

					case 'setConsultants':
					{
						const { consultants } = notification.data;

						store.dispatch(roomActions.setConsultants(consultants));

						break;
					}

					case 'gotRole':
					{
						const { peerId, roleId } = notification.data;

						const userRoles = store.getState().room.userRoles;

						if (peerId === this._peerId)
						{
							store.dispatch(meActions.addRole(roleId));

							if (roleId !== 4261)
								store.dispatch(requestActions.notify(
									{
										text : intl.formatMessage({
											id             : 'roles.gotRole',
											defaultMessage : 'You got the role: {role}'
										}, {
											role : intl.formatMessage({
												id             : userRoles.get(roleId).label,
												defaultMessage : userRoles.get(roleId).label
											})
										})
									}));
						}
						else
							store.dispatch(peerActions.addPeerRole(peerId, roleId));

						break;
					}

					case 'lostRole':
					{
						const { peerId, roleId } = notification.data;

						const userRoles = store.getState().room.userRoles;

						if (peerId === this._peerId)
						{
							store.dispatch(meActions.removeRole(roleId));

							if (roleId !== 4261)
								store.dispatch(requestActions.notify(
									{
										text : intl.formatMessage({
											id             : 'roles.lostRole',
											defaultMessage : 'You lost the role: {role}'
										}, {
											role : intl.formatMessage({
												id             : userRoles.get(roleId).label,
												defaultMessage : userRoles.get(roleId).label
											})
										})
									}));
						}
						else
							store.dispatch(peerActions.removePeerRole(peerId, roleId));

						break;
					}
					case 'addConsentForRecording':
					{
						// eslint-disable-next-line no-unused-vars
						const { peerId, consent } = notification.data;

						store.dispatch(
							peerActions.setPeerLocalRecordingConsent(peerId, consent));

						break;
					}
					case 'setLocalRecording':
					{
						const { peerId, localRecordingState } = notification.data;
						const { me, peers } = store.getState();

						let displayNameOfRecorder;

						if (peerId === me.id)
						{
							displayNameOfRecorder = store.getState().settings.displayName;
						}
						else if (peers[peerId])
							displayNameOfRecorder = store.getState().peers[peerId].displayName;
						else
							return;

						// Save state to peer
						store.dispatch(
							peerActions.setPeerLocalRecordingState(peerId, localRecordingState));

						switch (localRecordingState)
						{
							case 'start':
								store.dispatch(requestActions.notify(
									{
										text : intl.formatMessage({
											id             : 'room.localRecordingStarted',
											defaultMessage : '{displayName} started local recording'
										}, {
											displayName : displayNameOfRecorder
										})
									}));
								break;
							case 'resume':
								store.dispatch(requestActions.notify(
									{
										text : intl.formatMessage({
											id             : 'room.localRecordingResumed',
											defaultMessage : '{displayName} resumed local recording'
										}, {
											displayName : displayNameOfRecorder
										})
									}));
								break;
							case 'pause':
							{
								store.dispatch(requestActions.notify(
									{
										text : intl.formatMessage({
											id             : 'room.localRecordingPaused',
											defaultMessage : '{displayName} paused local recording'
										}, {
											displayName : displayNameOfRecorder
										})
									}));
								break;
							}
							case 'stop':
								store.dispatch(requestActions.notify(
									{
										text : intl.formatMessage({
											id             : 'room.localRecordingStopped',
											defaultMessage : '{displayName} stopped local recording'
										}, {
											displayName : displayNameOfRecorder
										})
									}));
								break;
							default:
								break;
						}
						break;
					}

					default:
					{
						logger.error(
							'unknown notification.method "%s"', notification.method);
					}
				}
			}
			catch (error)
			{
				logger.error('error on socket "notification" event [error:"%o"]', error);

				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'socket.requestError',
							defaultMessage : 'Error on server request'
						})
					}));
			}

		});

		store.dispatch(meActions.setJoinInProgress(false));
	}

	async _joinRoom({ joinVideo, joinAudio, returning })
	{
		logger.debug('_joinRoom()');

		const { displayName, enableOpusDetails } = store.getState().settings;
		const { picture, browser } = store.getState().me;

		try
		{
			// Robimy upload przez socket io
			this._torrentSupport = true;

			/*
			this._torrentSupport = WebTorrent.WEBRTC_SUPPORT;

			this._webTorrent = this._torrentSupport && new WebTorrent({
				tracker : {
					rtcConfig : {
						iceServers : this._turnServers
					}
				},
				downloadLimit : 1 * 1024 * 1024,
				uploadLimit   : 1 * 1024 * 1024
			});

			this._webTorrent.on('error', (error) =>
			{
				logger.error('Filesharing [error:"%o"]', error);

				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'filesharing.error',
							defaultMessage : 'There was a filesharing error'
						})
					}));
			});
			*/

			this._mediasoupDevice = new mediasoupClient.Device();

			const {
				routerRtpCapabilities,
				peerIpAddress
			} = await this.sendRequest('getRouterRtpCapabilities');

			routerRtpCapabilities.headerExtensions = routerRtpCapabilities.headerExtensions
				.filter((ext) => ext.uri !== 'urn:3gpp:video-orientation');

			await this._mediasoupDevice.load({ routerRtpCapabilities });

			let iceTransportPolicy = undefined;

			/*
			FireFox with new mediasoup should work now without forcing TURN
			if (this._device.flag === 'firefox' && this._turnServers)
				iceTransportPolicy = 'relay';
			else if (this._forceTurn && this._turnServers)
				iceTransportPolicy = 'relay';
			*/
			if (this._forceTurn && this._turnServers)
				iceTransportPolicy = 'relay';

			logger.debug('iceTransportPolicy %s', iceTransportPolicy);

			const isForceTcp = this._isForceTcp(peerIpAddress);

			logger.debug('forceTcp %s', isForceTcp);

			if (this._produce)
			{
				const transportInfo = await this.sendRequest(
					'createWebRtcTransport',
					{
						forceTcp  : isForceTcp,
						producing : true,
						consuming : false
					});

				const {
					id,
					iceParameters,
					iceCandidates,
					dtlsParameters
				} = transportInfo;

				this._sendTransport = this._mediasoupDevice.createSendTransport(
					{
						id,
						iceParameters,
						iceCandidates,
						dtlsParameters,
						iceServers             : this._turnServers,
						// TODO: Fix for issue #72
						iceTransportPolicy     : iceTransportPolicy,
						proprietaryConstraints : PC_PROPRIETARY_CONSTRAINTS
					});

				this._sendTransport.on(
					'connect', ({ dtlsParameters }, callback, errback) => // eslint-disable-line no-shadow
					{
						this.sendRequest(
							'connectWebRtcTransport',
							{
								transportId : this._sendTransport.id,
								dtlsParameters
							})
							.then(callback)
							.catch(errback);
					});

				this._sendTransport.on(
					'connectionstatechange', (connectState) =>
					{
						switch (connectState)
						{
							case 'disconnected':
							case 'failed':
								this.restartIce(this._sendTransport, this._sendRestartIce, 2000);
								break;

							default:
								clearTimeout(this._sendRestartIce.timer);
								break;
						}
					});

				this._sendTransport.on(
					'produce', async ({ kind, rtpParameters, appData }, callback, errback) =>
					{
						try
						{
							// eslint-disable-next-line no-shadow
							const { id } = await this.sendRequest(
								'produce',
								{
									transportId : this._sendTransport.id,
									kind,
									rtpParameters,
									appData
								});

							callback({ id });
						}
						catch (error)
						{
							errback(error);
						}
					});
			}

			const transportInfo = await this.sendRequest(
				'createWebRtcTransport',
				{
					forceTcp  : isForceTcp,
					producing : false,
					consuming : true
				});

			const {
				id,
				iceParameters,
				iceCandidates,
				dtlsParameters
			} = transportInfo;

			this._recvTransport = this._mediasoupDevice.createRecvTransport(
				{
					id,
					iceParameters,
					iceCandidates,
					dtlsParameters,
					iceServers         : this._turnServers,
					// TODO: Fix for issue #72
					iceTransportPolicy : iceTransportPolicy,
					additionalSettings : {
						encodedInsertableStreams : insertableStreamsSupported && enableOpusDetails
					},
					appData : {
						encodedInsertableStreams : insertableStreamsSupported && enableOpusDetails
					}
				});

			this._recvTransport.on(
				'connect', ({ dtlsParameters }, callback, errback) => // eslint-disable-line no-shadow
				{
					this.sendRequest(
						'connectWebRtcTransport',
						{
							transportId : this._recvTransport.id,
							dtlsParameters
						})
						.then(callback)
						.catch(errback);
				});

			this._recvTransport.on(
				'connectionstatechange', (connectState) =>
				{
					switch (connectState)
					{
						case 'disconnected':
						case 'failed':
							this.restartIce(this._recvTransport, this._recvRestartIce, 2000);
							break;

						default:
							clearTimeout(this._recvRestartIce.timer);
							break;
					}
				});

			// Set our media capabilities.
			store.dispatch(meActions.setMediaCapabilities(
				{
					canSendMic     : this._mediasoupDevice.canProduce('audio'),
					canSendWebcam  : this._mediasoupDevice.canProduce('video'),
					canShareScreen : this._mediasoupDevice.canProduce('video') &&
						this._screenSharing.isScreenShareAvailable(),
					canShareFiles : this._torrentSupport
				}));

			const rtpCapabilities = this._mediasoupDevice.rtpCapabilities;

			const clientId = store.getState().settings.clientId;

			const {
				authenticated,
				roles,
				peers,
				tracker,
				roomPermissions,
				userRoles,
				allowWhenRoleMissing,
				chatHistory,
				fileHistory,
				lastNHistory,
				consultants,
				locked,
				lobbyPeers,
				accessCode,
				loggedInWithPin,
				isRecorder,
				recorderInitState,
				isRecordingInProgress,
				isStreamingInProgress,
				isUploadSnapshotEnabled,
				drawingColor,
				permanentAuthToken
			} = await this.sendRequest(
				'join',
				{
					displayName     : displayName,
					picture         : picture,
					browser         : browser,
					rtpCapabilities : rtpCapabilities,
					returning       : returning,
					btPeerId        : (this._webTorrent ? this._webTorrent.peerId : null),
					clientId        : clientId
				});

			logger.debug(
				'_joinRoom() joined [authenticated:"%s", loggedInWithPin:"%s", isRecorder:"%s", peers:"%o", roles:"%o"]',
				authenticated,
				loggedInWithPin,
				isRecorder,
				peers,
				roles
			);

			store.dispatch(roomActions.setRtpCapabilities(rtpCapabilities));

			if (tracker)
			{
				let serverHost = tracker.hostname;

				if (!serverHost)
					serverHost = window.location.hostname;

				this._tracker = `${tracker.protocol}://${serverHost}:${tracker.port}${tracker.path}`;

				logger.debug('Setting BitTorrent tracker to', this._tracker);
			}

			store.dispatch(meActions.loggedIn(authenticated));
			store.dispatch(meActions.loggedInWithPin(loggedInWithPin));
			store.dispatch(roomActions.setConsultants(consultants));
			store.dispatch(meActions.isRecorder(isRecorder));
			store.dispatch(meActions.setIpAddress(peerIpAddress));
			store.dispatch(roomActions.setUploadSnapshotEnabled(isUploadSnapshotEnabled));
			store.dispatch(roomActions.setPermanentAuthToken(permanentAuthToken));

			if (isRecordingInProgress)
				store.dispatch(roomActions.setRecorderState('started'));

			if (isStreamingInProgress)
				store.dispatch(roomActions.setStreamerState('started'));

			const tSettings = store.getState().settings;

			if (tSettings.enableSnaphots && !config.snapshotsEnabled)
				store.dispatch(settingsActions.toggleEnableSnaphots());

			// we only support 16:9 video boxes
			store.dispatch(settingsActions.setAspectRatio(16 / 9));

			if (!config.drawingEnabled)
				this.toggleDrawingMode(false);

			if (isRecorder)
			{
				const recorderMode = store.getState().me.recorderMode;

				store.dispatch(roomActions.setHideSelfView(true));

				this._recorderInitState = recorderInitState;

				if (tSettings.showNotifications)
					store.dispatch(settingsActions.toggleShowNotifications());
				if (tSettings.notificationSounds)
					store.dispatch(settingsActions.toggleNotificationSounds());

				if (recorderMode === 5)
				{
					if (tSettings.hiddenControls)
						store.dispatch(settingsActions.toggleHiddenControls());
					if (!tSettings.buttonControlBar)
						store.dispatch(settingsActions.toggleButtonControlBar());
				}
				else
				{
					if (!tSettings.hiddenControls)
						store.dispatch(settingsActions.toggleHiddenControls());
					if (tSettings.buttonControlBar)
						store.dispatch(settingsActions.toggleButtonControlBar());
				}

				if ((config.recorderShowStats && !tSettings.advancedMode) ||
					(!config.recorderShowStats && tSettings.advancedMode))
				{
					store.dispatch(settingsActions.toggleAdvancedMode());
				}

				if (recorderMode === 3 || recorderMode === 6)
				{
					this.changeMaxSpotlights(1);
				}
				else if (recorderMode === 4 || recorderMode === 43)
				{
					this.changeMaxSpotlights(100);
				}
				else
					this.changeMaxSpotlights(this.getConfigMaxSpotlights() + 1);

				if (recorderMode === 5)
				{
					if (!tSettings.drawingAlwaysOn)
						store.dispatch(settingsActions.toggleDrawingAlwaysOn());

					if (!tSettings.showRemoveDrawingsPerVideo)
						store.dispatch(settingsActions.toggleShowRemoveDrawingsPerVideo());
				}

				// Na razie pokazujemy top bar jeśli nie ma video w full screen
				/*
				else if (recorderMode === 6)
				{
					this.changeMaxSpotlights(1);

					if (tSettings.permanentTopBar)
						store.dispatch(settingsActions.togglePermanentTopBar());
				}
				*/

				// Trzeba się zastanowić czy nagrywać top bar, na razie tak
				// if (tSettings.permanentTopBar)
				// 	store.dispatch(settingsActions.togglePermanentTopBar());
			}
			else
			{
				// hack bo w room.js usatwilismy ze self view jest default hidden
				store.dispatch(roomActions.setHideSelfView(false));

				// hack bo default jest na false
				if (!tSettings.buttonControlBar)
					store.dispatch(settingsActions.toggleButtonControlBar());

				if (tSettings.hiddenControls)
					store.dispatch(settingsActions.toggleHiddenControls());

				if (config.drawingEnabled && !tSettings.drawingAlwaysOn)
					this.toggleDrawingMode(false);
			}

			// hack for OR FHD resolution and other settings
			if (loggedInWithPin)
			{
				if (this._device.platform !== 'desktop')
					store.dispatch(settingsActions.setVideoResolution(config.resolutionPinMobile));
				else
					store.dispatch(settingsActions.setVideoResolution(config.resolutionPin));

				store.dispatch(settingsActions.setVideoFrameRate(config.frameRatePin));

				if (store.getState().settings.mirrorOwnVideo)
					store.dispatch(settingsActions.toggleMirrorOwnVideo());
			}

			// hack to endble/disable setting for 3d rooms
			if (!config.roomsWith3d[this._roomId])
			{
				if (store.getState().settings.in3dModeView)
					store.dispatch(settingsActions.toggleIn3dModeView());

				if (store.getState().settings.in3dModeSend)
					store.dispatch(settingsActions.toggleIn3dModeSend());
			}
			else
			{
				if (!store.getState().settings.in3dModeView)
					store.dispatch(settingsActions.toggleIn3dModeView());

				if (loggedInWithPin && !store.getState().settings.in3dModeSend)
					store.dispatch(settingsActions.toggleIn3dModeSend());
			}

			/*
			w tej chwili robimy że zawsze top bar widoczny bo na komorce nie da sie rysowac
			Jak pojawia się i znika
			if ((tSettings.permanentTopBar && browser.platform !== 'desktop') ||
				(!tSettings.permanentTopBar && browser.platform === 'desktop'))
				store.dispatch(settingsActions.togglePermanentTopBar());
			*/
			if (!tSettings.permanentTopBar)
				store.dispatch(settingsActions.togglePermanentTopBar());

			store.dispatch(roomActions.setDrawingColor(drawingColor));

			store.dispatch(roomActions.setRoomPermissions(roomPermissions));

			const roomUserRoles = new Map();

			Object.values(userRoles).forEach((val) => roomUserRoles.set(val.id, val));

			store.dispatch(roomActions.setUserRoles(roomUserRoles));

			if (allowWhenRoleMissing)
				store.dispatch(roomActions.setAllowWhenRoleMissing(allowWhenRoleMissing));

			const myRoles = store.getState().me.roles;

			for (const roleId of roles)
			{
				if (!myRoles.some((myRoleId) => roleId === myRoleId))
				{
					store.dispatch(meActions.addRole(roleId));

					if (roleId !== 4261)
						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'roles.gotRole',
									defaultMessage : 'You got the role: {role}'
								}, {
									role : intl.formatMessage({
										id             : roomUserRoles.get(roleId).label,
										defaultMessage : roomUserRoles.get(roleId).label
									})
								})
							}));
				}
			}

			for (const peer of peers)
			{
				store.dispatch(
					peerActions.addPeer({ ...peer, consumers: [] }));

				if (peer.loggedInWithPin)
					store.dispatch(roomActions.setPeerWithPinId(peer.id));

				if (isRecorder &&
					!this._isNotFollowRecorder() &&
					this._recorderInitState.selectedPeer &&
					!this._recorderInitState.soloVideo &&
					this._recorderInitState.selectedPeer.peerId === peer.id)
				{
					// uwaga usuwa też solo peer
					this.setSelectedPeer(peer.id);
					this._recorderInitState.selectedPeer = null;
				}

				if (peer.id in this._consumerQueue)
				{
					while (this._consumerQueue[peer.id].length > 0)
					{
						const consumerData = this._consumerQueue[peer.id].shift();

						await this._createConsumer(consumerData);
					}
				}
			}

			// zawsze strawdzamy czy jest router, aby nawet jak OR jest w innym pokoju moglo sterowac routerm
			this._BMDRouterGetStatus().then(() =>
			{
				if (store.getState().me.routerConfig)
				{
					store.dispatch(toolareaActions.setToolTab('router'));
				}
			});

			// zawsze sprawdzamy czy mozemy ustwic dzwiek systemowy i jaki jest
			this.systemVolumeControl();

			// zawsze sprawdzamy czy mozemy pokazać guzik wyłączający terminal
			this.shutdown();

			// zawsze spradzamy czy jest address book
			this.getAddressBook();

			this._initTerminalClient();

			await this._updateAudioOutputDevices();

			(chatHistory.length > 0) && store.dispatch(
				chatActions.addChatHistory(chatHistory));

			(fileHistory.length > 0) && store.dispatch(
				fileActions.addFileHistory(fileHistory));

			locked ?
				store.dispatch(roomActions.setRoomLocked()) :
				store.dispatch(roomActions.setRoomUnLocked());

			(lobbyPeers.length > 0) && lobbyPeers.forEach((peer) =>
			{
				// if peer already joined, do not add to lobby - race
				if (store.getState().peers[peer.id])
					return;

				store.dispatch(
					lobbyPeerActions.addLobbyPeer(peer.id));
				store.dispatch(
					lobbyPeerActions.setLobbyPeerDisplayName(peer.displayName, peer.id));
				store.dispatch(
					lobbyPeerActions.setLobbyPeerPicture(peer.picture, peer.id));
			});

			(accessCode != null) && store.dispatch(
				roomActions.setAccessCode(accessCode));

			// Don't produce if explicitly requested to not to do it.
			if (this._produce)
			{
				if (
					joinVideo &&
					this._havePermission(permissions.SHARE_VIDEO)
				)
				{
					this.updateWebcam({ init: true, start: true });

					// add extra videos from start if set.
					// we iterate over a copy, as we will be deleting on error
					if (this._havePermission(permissions.EXTRA_VIDEO))
					{
						const selectedExtraVideosCopy =
							[ ...store.getState().settings.selectedExtraVideos ];

						this.addExtraVideos(selectedExtraVideosCopy);
					}
				}
				if (
					joinAudio &&
					this._mediasoupDevice.canProduce('audio') &&
					this._havePermission(permissions.SHARE_AUDIO)
				)
				{
					if (!this._muted)
					{
						await this.updateMic({ start: true });
						let autoMuteThreshold = config.autoMuteThreshold;

						if (config.autoMuteThresholds[this._roomId])
						{
							autoMuteThreshold = config.autoMuteThresholds[this._roomId];
						}

						if (autoMuteThreshold >= 0 && peers.length >= autoMuteThreshold)
						{
							this.muteMic();
						}
					}
				}
			}

			this._startStatsMonitoring();

			store.dispatch(roomActions.setRoomState('connected'));

			// Clean all the existing notifications.
			store.dispatch(notificationActions.removeAllNotifications());

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.joined',
						defaultMessage : 'You have joined the room'
					})
				}));

			this._spotlights.addPeers(peers);

			if (lastNHistory.length > 0)
			{
				logger.debug('_joinRoom() | got lastN history');

				this._spotlights.addSpeakerList(
					lastNHistory.filter((peerId) => peerId !== this._peerId)
				);
			}

			if (config.enableBeforeUnloadAlert &&
				(!isRecorder || (isRecorder && store.getState().me.recorderMode > 1)))
			{
				window.addEventListener('beforeunload', this._onBeforeUnload);
			}
		}
		catch (error)
		{
			logger.error('_joinRoom() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.cantJoin',
						defaultMessage : 'Unable to join the room'
					})
				}));

			this.close();
		}
	}

	getRecorderSupportedMimeTypes()
	{
		const mimeTypes = [];

		const mimeTypeCapability = [

			/* audio codecs
			[ 'audio/wav', [] ],
			[ 'audio/pcm', [] ],
			[ 'audio/webm', [ 'Chrome', 'Firefox', 'Safari' ] ],
			[ 'audio/ogg', [ 'Firefox' ] ],
			[ 'audio/opus', [] ],
			*/
			[ 'video/webm', [ 'Chrome', 'Firefox', 'Safari' ] ],
			[ 'video/webm;codecs="vp8, opus"', [ 'Chrome', 'Firefox', 'Safari' ] ],
			[ 'video/webm;codecs="vp9, opus"', [ 'Chrome' ] ],
			[ 'video/webm;codecs="h264, opus"', [ 'Chrome' ] ],
			[ 'video/mp4', [] ],
			[ 'video/mpeg', [] ],
			[ 'video/x-matroska;codecs=avc1', [ 'Chrome' ] ]
		];

		if (typeof MediaRecorder === 'undefined')
		{
			window.MediaRecorder = {
				isTypeSupported : function()
				{
					return false;
				}
			};
		}
		mimeTypeCapability.forEach((item) =>
		{
			if (MediaRecorder.isTypeSupported(item[0]) && !mimeTypes.includes(item[0]))
			{
				mimeTypes.push(item[0]);
			}
		});

		return mimeTypes;
	}

	async lockRoom()
	{
		logger.debug('lockRoom()');

		try
		{
			await this.sendRequest('lockRoom');

			store.dispatch(
				roomActions.setRoomLocked());

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.youLocked',
						defaultMessage : 'You locked the room'
					})
				}));
		}
		catch (error)
		{
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.cantLock',
						defaultMessage : 'Unable to lock the room'
					})
				}));

			logger.error('lockRoom() [error:"%o"]', error);
		}
	}

	async unlockRoom()
	{
		logger.debug('unlockRoom()');

		try
		{
			await this.sendRequest('unlockRoom');

			store.dispatch(
				roomActions.setRoomUnLocked());

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.youUnLocked',
						defaultMessage : 'You unlocked the room'
					})
				}));
		}
		catch (error)
		{
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.cantUnLock',
						defaultMessage : 'Unable to unlock the room'
					})
				}));

			logger.error('unlockRoom() [error:"%o"]', error);
		}
	}

	async addConsentForRecording(consent)
	{
		logger.debug('addConsentForRecording()');

		try
		{
			store.dispatch(
				recorderActions.setLocalRecordingConsent(consent));
			await this.sendRequest('addConsentForRecording', { consent });
		}
		catch (error)
		{

			logger.error('addConsentForRecording() [error:"%o"]', error);
		}
	}

	async setAccessCode(code)
	{
		logger.debug('setAccessCode()');

		try
		{
			await this.sendRequest('setAccessCode', { accessCode: code });

			store.dispatch(
				roomActions.setAccessCode(code));

			store.dispatch(requestActions.notify(
				{
					text : 'Access code saved.'
				}));
		}
		catch (error)
		{
			logger.error('setAccessCode() [error:"%o"]', error);
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : 'Unable to set access code.'
				}));
		}
	}

	async setJoinByAccessCode(value)
	{
		logger.debug('setJoinByAccessCode()');

		try
		{
			await this.sendRequest('setJoinByAccessCode', { joinByAccessCode: value });

			store.dispatch(
				roomActions.setJoinByAccessCode(value));

			store.dispatch(requestActions.notify(
				{
					text : `You switched Join by access-code to ${value}`
				}));
		}
		catch (error)
		{
			logger.error('setAccessCode() [error:"%o"]', error);
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : 'Unable to set join by access code.'
				}));
		}
	}

	async addExtraVideos(selectedExtraVideos, videoParams = {})
	{
		if (!Array.isArray(selectedExtraVideos))
			return;

		for (const extraVideoDeviceId of selectedExtraVideos)
		{
			await this.addExtraVideo(extraVideoDeviceId, videoParams);
		}
	}

	async addExtraVideo(videoDeviceId, {
		newResolution = null,
		newFrameRate = null,
		newVideoCodec = null
	} = {})
	{
		logger.debug(
			'addExtraVideo() [videoDeviceId:"%s"]',
			videoDeviceId
		);

		store.dispatch(
			roomActions.setExtraVideoOpen(false));

		if (!videoDeviceId)
		{
			logger.debug('addExtraVideo() | no video device has been chosen');

			return;
		}

		if (!this._mediasoupDevice.canProduce('video'))
		{
			logger.error('addExtraVideo() | cannot produce video');

			return;
		}

		const { aspectRatio } = store.getState().settings;

		let { resolution, videoCodec, frameRate } = store.getState().settings;

		if (newResolution)
			resolution = newResolution;

		if (newVideoCodec)
			videoCodec = newVideoCodec;

		if (newFrameRate)
			frameRate = newFrameRate;

		if (this._encodingProblems.doSwitch === 'switch')
		{
			videoCodec = config.videoCodecProblem;

			if (frameRate > config.frameRateProblem)
				frameRate = config.frameRateProblem;

			const currentIndex =
				config.resolutionsAvailable.indexOf(resolution);
			const problemIndex =
				config.resolutionsAvailable.indexOf(config.resolutionProblem);

			if (currentIndex === -1 || currentIndex > problemIndex)
				resolution = config.resolutionProblem;
		}

		const videoConstrains = getVideoConstrains(resolution, aspectRatio);

		if (resolution === 'ultra')
		{
			logger.error('addExtraVideo() | extra video not allowed if resolution is UHD');

			return;
		}

		let track;

		store.dispatch(
			meActions.setWebcamInProgress(true));

		try
		{
			if (Object.keys(this._webcams).length === 0)
				await this._updateWebcams();

			const device = this._webcams[videoDeviceId];

			if (!device)
			{
				// remove the stored device id from selected extra videos
				store.dispatch(
					settingsActions.removeSelectedExtraVideo(videoDeviceId));

				throw new Error('no webcam devices');
			}

			const deviceIdConstrains = getDeviceIdConstrains(videoDeviceId);

			let stream = await navigator.mediaDevices.getUserMedia(
				{
					video :
					{
						deviceId : deviceIdConstrains,
						...videoConstrains,
						frameRate
					}
				});

			let trackDeviceId;

			let trackSettings;

			if (this._encodingProblems.doSwitch === 'blur')
			{
				const { canvasStream, origTrackDeviceId } =
					await this._useCanvasStream(stream, frameRate);

				stream = canvasStream;
				trackDeviceId = origTrackDeviceId;
				([ track ] = stream.getVideoTracks());
				trackSettings = track.getSettings();

				logger.debug('getUserMedia extra video using origTrackDeviceId %s track settings: %o',
					origTrackDeviceId, trackSettings);
			}
			else
			{
				([ track ] = stream.getVideoTracks());
				trackSettings = track.getSettings();
				trackDeviceId = trackSettings.deviceId;

				logger.debug('getUserMedia extra video using track settings:', trackSettings);
			}

			const { width, height } = trackSettings;

			let exists = false;

			this._extraVideoProducers.forEach((extraVideoProducer) =>
			{
				if (extraVideoProducer.appData.deviceId === trackDeviceId)
					exists = true;
			});

			if (!exists)
			{

				let producer;

				const networkPriority = config.networkPriorities.extraVideo;
				const codecOptions = this._getCodecOptions(resolution);
				const codec = this._getVideoCodec(videoCodec);

				this._setVideoTrackContentHint(track, videoCodec.toLowerCase());

				logger.debug('ASTAGOR debug extravideo codecs %o', this._mediasoupDevice.rtpCapabilities.codecs);
				logger.debug('ASTAGOR debug extravideo videoCodecString %s', videoCodec);
				logger.debug('ASTAGOR debug extravideo codec %o', codec);
				logger.debug('ASTAGOR debug extravideo codecOptions %o', codecOptions);

				if (videoCodec.toLowerCase() === 'vp9')
				{
					const encoding = this._getSvcEncoding(width, height);
					const resolutionScalings = getSvcResolutionScalings(encoding);

					encoding.networkPriority = networkPriority;

					logger.debug('ASTAGOR VP9 debug extravideo encoding %o', encoding);
					logger.debug('ASTAGOR VP9 debug extravideo resolutionScalings %o', resolutionScalings);

					producer = await this._sendTransport.produce(
						{
							track,
							codec,
							codecOptions,
							encodings : [ encoding ],
							appData   :
							{
								source   : 'extravideo',
								deviceId : trackDeviceId,
								width,
								height,
								resolutionScalings
							}
						});
				}
				else if (videoCodec.toLowerCase() === 'vp8' &&
					this._useSimulcast && resolution !== 'ultra')
				{
					const encodings = this._getSimulcastEncodings(width, height);
					const resolutionScalings = getSimulcastResolutionScalings(encodings);

					/**
					 * TODO:
					 * I receive DOMException:
					 * Failed to execute 'addTransceiver' on 'RTCPeerConnection':
					 * Attempted to set an unimplemented parameter of RtpParameters.
					encodings.forEach((encoding) =>
					{
						encoding.networkPriority=networkPriority;
					});
					*/
					encodings[0].networkPriority=networkPriority;

					producer = await this._sendTransport.produce(
						{
							track,
							codec,
							codecOptions,
							encodings,
							appData :
							{
								source   : 'extravideo',
								deviceId : trackDeviceId,
								width,
								height,
								resolutionScalings
							}
						});
				}
				else
				{
					producer = await this._sendTransport.produce({
						track,
						codec,
						codecOptions,
						encodings : [ { networkPriority } ],
						appData   :
						{
							source   : 'extravideo',
							deviceId : trackDeviceId,
							width,
							height
						}
					});
				}

				this._extraVideoProducers.set(producer.id, producer);

				store.dispatch(producerActions.addProducer(
					{
						id            : producer.id,
						source        : 'extravideo',
						deviceLabel   : device.label,
						paused        : producer.paused,
						track         : producer.track,
						rtpParameters : producer.rtpParameters,
						codec         : producer.rtpParameters.codecs[0].mimeType.split('/')[1]
					}));

				await this._updateWebcams();

				producer.on('transportclose', () =>
				{
					this._extraVideoProducers.delete(producer.id);

					producer = null;
				});

				producer.on('trackended', () =>
				{
					store.dispatch(requestActions.notify(
						{
							type : 'error',
							text : intl.formatMessage({
								id             : 'devices.cameraDisconnected',
								defaultMessage : 'Camera disconnected'
							})
						}));

					this.disableExtraVideo(producer.id)
						.catch(() => {});
				});

				this._encodingProblems.videoStartTimes[producer.id] = Date.now();

				store.dispatch(
					settingsActions.addSelectedExtraVideo(trackDeviceId));

				logger.debug('addExtraVideo() succeeded');

			}
			else
			{
				logger.error('addExtraVideo() duplicate');
				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'room.extraVideoDuplication',
							defaultMessage : 'The video source is already in use.'
						})
					}));
			}
		}
		catch (error)
		{
			logger.error('addExtraVideo() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.cameraError',
						defaultMessage : 'An error occurred while accessing your camera'
					})
				}));

			if (track)
				track.stop();
		}

		store.dispatch(
			meActions.setWebcamInProgress(false));
	}

	async disableMic()
	{
		logger.debug('disableMic()');

		if (!this._micProducer)
			return;

		store.dispatch(meActions.setAudioInProgress(true));

		this._micProducer.close();

		store.dispatch(
			producerActions.removeProducer(this._micProducer.id));

		try
		{
			await this.sendRequest(
				'closeProducer', { producerId: this._micProducer.id });
		}
		catch (error)
		{
			logger.error('disableMic() [error:"%o"]', error);
		}

		this._micProducer = null;

		store.dispatch(meActions.setAudioInProgress(false));
	}
	async updateRecorderPreferredMimeType({ recorderPreferredMimeType = null } = {})
	{
		logger.debug('updateRecorderPreferredMimeType [mime-type: "%s"]',
			recorderPreferredMimeType
		);
		store.dispatch(
			settingsActions.setRecorderPreferredMimeType(recorderPreferredMimeType));
	}

	async updateScreenSharing({
		start = false,
		newResolution = null,
		newFrameRate = null
	} = {})
	{
		logger.debug('updateScreenSharing() [start:"%s"]', start);

		let track;

		try
		{
			const available = this._screenSharing.isScreenShareAvailable();

			const isAudioEnabled = this._screenSharing.isAudioEnabled();

			if (!available)
				throw new Error('screen sharing not available');

			if (!this._mediasoupDevice.canProduce('video'))
				throw new Error('cannot produce video');

			if (newResolution)
				store.dispatch(settingsActions.setScreenSharingResolution(newResolution));

			if (newFrameRate)
				store.dispatch(settingsActions.setScreenSharingFrameRate(newFrameRate));

			store.dispatch(meActions.setScreenShareInProgress(true));

			const {
				screenSharingResolution,
				autoGainControl,
				echoCancellation,
				noiseSuppression,
				aspectRatio,
				screenSharingFrameRate,
				screenSharingCodec,
				sampleRate,
				channelCount,
				sampleSize,
				opusStereo,
				opusDtx,
				opusFec,
				opusPtime,
				opusMaxPlaybackRate
			} = store.getState().settings;

			const videoConstrains = getVideoConstrains(screenSharingResolution, aspectRatio);

			if (start)
			{
				let stream;

				if (isAudioEnabled)
				{
					stream = await this._screenSharing.start({
						...videoConstrains,
						frameRate          : screenSharingFrameRate,
						sampleRate,
						channelCount,
						autoGainControl,
						echoCancellation,
						noiseSuppression,
						sampleSize,
						selfBrowserSurface : 'include'
					});

				}
				else
				{
					stream = await this._screenSharing.start({
						...videoConstrains,
						frameRate          : screenSharingFrameRate,
						selfBrowserSurface : 'include'
					});

				}

				([ track ] = stream.getVideoTracks());

				const { width, height } = track.getSettings();

				logger.debug('screenSharing track settings:', track.getSettings());

				const networkPriority = config.networkPriorities.screenShare;
				const codecOptions = this._getCodecOptions(screenSharingResolution);
				const codec = this._getVideoCodec(screenSharingCodec);

				this._setVideoTrackContentHint(track, screenSharingCodec.toLowerCase(), true);

				logger.debug('ASTAGOR debug screen codecs %o', this._mediasoupDevice.rtpCapabilities.codecs);
				logger.debug('ASTAGOR debug screen screenSharingCodecString %s', screenSharingCodec);
				logger.debug('ASTAGOR debug screen codec %o', codec);
				logger.debug('ASTAGOR debug screen codecOptions %o', codecOptions);

				if (screenSharingCodec.toLowerCase() === 'vp9')
				{
					const encoding = this._getSvcEncoding(width, height, true);
					const resolutionScalings = getSvcResolutionScalings(encoding);

					encoding.networkPriority = networkPriority;

					logger.debug('ASTAGOR VP9 debug screen encoding %o', encoding);
					logger.debug('ASTAGOR VP9 debug screen resolutionScalings %o', resolutionScalings);

					this._screenSharingProducer = await this._sendTransport.produce(
						{
							track,
							codec,
							codecOptions,
							encodings : [ encoding ],
							appData   :
							{
								source : 'screen',
								width,
								height,
								resolutionScalings
							}
						});
				}
				else if (screenSharingCodec.toLowerCase() === 'vp8' &&
					this._useSharingSimulcast && screenSharingResolution !== 'ultra')
				{
					let encodings = this._getSimulcastEncodings(width, height);

					encodings = encodings
						.map((encoding) => ({ ...encoding, dtx: true }));

					const resolutionScalings = getSimulcastResolutionScalings(encodings);

					/**
					 * TODO:
					 * I receive DOMException:
					 * Failed to execute 'addTransceiver' on 'RTCPeerConnection':
					 * Attempted to set an unimplemented parameter of RtpParameters.
					encodings.forEach((encoding) =>
					{
						encoding.networkPriority=networkPriority;
					});
					*/
					encodings[0].networkPriority=networkPriority;

					this._screenSharingProducer = await this._sendTransport.produce(
						{
							track,
							codec,
							codecOptions,
							encodings,
							appData :
							{
								source : 'screen',
								width,
								height,
								resolutionScalings
							}
						});
				}
				else
				{
					this._screenSharingProducer = await this._sendTransport.produce({
						track,
						codec,
						codecOptions,
						encodings : [ { networkPriority } ],
						appData   :
						{
							source : 'screen',
							width,
							height
						}
					});
				}

				const screenSharingDeviceLabel = intl.formatMessage({
					id             : 'label.screenSharing',
					defaultMessage : 'Screen'
				});

				store.dispatch(producerActions.addProducer(
					{
						id            : this._screenSharingProducer.id,
						source        : 'screen',
						deviceLabel   : screenSharingDeviceLabel,
						paused        : this._screenSharingProducer.paused,
						track         : this._screenSharingProducer.track,
						rtpParameters : this._screenSharingProducer.rtpParameters,
						codec         : this._screenSharingProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
					}));

				this._screenSharingProducer.on('transportclose', () =>
				{
					this._screenSharingProducer = null;
				});

				this._screenSharingProducer.on('trackended', () =>
				{
					store.dispatch(requestActions.notify(
						{
							type : 'error',
							text : intl.formatMessage({
								id             : 'devices.screenSharingDisconnected',
								defaultMessage : 'Screen sharing disconnected'
							})
						}));

					this.disableScreenSharing();
				});

				([ track ] = stream.getAudioTracks());

				if (isAudioEnabled && track)
				{

					this._screenSharingAudioProducer = await this._sendTransport.produce(
						{
							track,
							codecOptions :
							{
								opusStereo          : opusStereo,
								opusFec             : opusFec,
								opusDtx             : opusDtx,
								opusMaxPlaybackRate : opusMaxPlaybackRate,
								opusPtime           : opusPtime
							},
							appData :
							{ source: 'mic', isScreenAudio: true }
						});

					store.dispatch(producerActions.addProducer(
						{
							id            : this._screenSharingAudioProducer.id,
							source        : 'mic',
							deviceLabel   : screenSharingDeviceLabel,
							paused        : this._screenSharingAudioProducer.paused,
							track         : this._screenSharingAudioProducer.track,
							rtpParameters : this._screenSharingAudioProducer.rtpParameters,
							codec         : this._screenSharingAudioProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
						}));

					this._screenSharingAudioProducer.on('transportclose', () =>
					{
						this._screenSharingAudioProducer = null;
					});

					this._screenSharingAudioProducer.on('trackended', () =>
					{
						store.dispatch(requestActions.notify(
							{
								type : 'error',
								text : intl.formatMessage({
									id             : 'devices.screenSharingDisconnected',
									defaultMessage : 'Screen sharing disconnected'
								})
							}));

						// this.disableScreenSharing();
					});

					this._screenSharingAudioProducer.volume = 0;
				}

			}
			else
			{
				if (this._screenSharingProducer)
				{
					({ track } = this._screenSharingProducer);

					await track.applyConstraints(
						{
							...videoConstrains,
							frameRate : screenSharingFrameRate
						}
					);
				}
				if (this._screenSharingAudioProducer)
				{
					({ track } = this._screenSharingAudioProducer);

					await track.applyConstraints(
						{
							sampleRate,
							channelCount,
							autoGainControl,
							echoCancellation,
							noiseSuppression,
							sampleSize
						}
					);
				}
			}
		}
		catch (error)
		{
			logger.error('updateScreenSharing() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.screenSharingError',
						defaultMessage : 'An error occurred while accessing your screen'
					})
				}));

			if (track)
				track.stop();
		}

		store.dispatch(meActions.setScreenShareInProgress(false));
	}

	async disableScreenSharing()
	{
		logger.debug('disableScreenSharing()');

		if (!this._screenSharingProducer)
			return;

		store.dispatch(meActions.setScreenShareInProgress(true));

		this._screenSharingProducer.close();

		if (this._screenSharingAudioProducer)
		{
			this._screenSharingAudioProducer.close();

			store.dispatch(
				producerActions.removeProducer(this._screenSharingAudioProducer.id));
		}

		if (store.getState().room.soloVideoProducerId === this._screenSharingProducer.id)
			this.setSoloVideoProducerId(null);

		store.dispatch(
			producerActions.removeProducer(this._screenSharingProducer.id));

		try
		{
			await this.sendRequest(
				'closeProducer', { producerId: this._screenSharingProducer.id });

			if (this._screenSharingAudioProducer)
			{
				await this.sendRequest(
					'closeProducer', { producerId: this._screenSharingAudioProducer.id });
			}
		}
		catch (error)
		{
			logger.error('disableScreenSharing() [error:"%o"]', error);
		}

		this._screenSharingProducer = null;
		this._screenSharingAudioProducer = null;

		this._screenSharing.stop();

		store.dispatch(meActions.setScreenShareInProgress(false));
	}

	async disableAllExtraVideos()
	{
		for (const producer of this._extraVideoProducers.values())
			await this.disableExtraVideo(producer.id);
	}

	async disableExtraVideo(id)
	{
		logger.debug('disableExtraVideo()');

		const producer = this._extraVideoProducers.get(id);

		if (!producer)
			return;

		store.dispatch(meActions.setWebcamInProgress(true));

		producer.close();

		this._encodingProblems.videoStartTimes[producer.id] = null;

		if (store.getState().room.soloVideoProducerId === id)
			this.setSoloVideoProducerId(null);

		store.dispatch(
			producerActions.removeProducer(id));

		try
		{
			await this.sendRequest(
				'closeProducer', { producerId: id });
		}
		catch (error)
		{
			logger.error('disableExtraVideo() [error:"%o"]', error);
		}

		this._extraVideoProducers.delete(id);

		store.dispatch(
			settingsActions.removeSelectedExtraVideo(producer.appData.deviceId));

		store.dispatch(meActions.setWebcamInProgress(false));
	}

	async disableWebcam()
	{
		logger.debug('disableWebcam()');

		if (!this._webcamProducer)
			return;

		store.dispatch(meActions.setWebcamInProgress(true));

		if (this._blurBackgroundInterval)
		{
			clearInterval(this._blurBackgroundInterval);
			this._blurBackgroundInterval = null;
		}

		this._webcamProducer.close();

		this._encodingProblems.videoStartTimes[this._webcamProducer.id] = null;

		if (store.getState().room.soloVideoProducerId === this._webcamProducer.id)
			this.setSoloVideoProducerId(null);

		store.dispatch(
			producerActions.removeProducer(this._webcamProducer.id));

		try
		{
			await this.sendRequest(
				'closeProducer', { producerId: this._webcamProducer.id });
		}
		catch (error)
		{
			logger.error('disableWebcam() [error:"%o"]', error);
		}

		this._webcamProducer = null;
		store.dispatch(settingsActions.setVideoMuted(true));
		store.dispatch(meActions.setWebcamInProgress(false));
	}

	async _setNoiseThreshold(threshold)
	{
		logger.debug('_setNoiseThreshold() [threshold:"%s"]', threshold);

		this._hark?.setThreshold(threshold);

		store.dispatch(
			settingsActions.setNoiseThreshold(threshold));
	}

	async _updateAudioDevices()
	{
		logger.debug('_updateAudioDevices()');

		// Reset the list.
		this._audioDevices = {};

		try
		{
			logger.debug('_updateAudioDevices() | calling enumerateDevices()');

			const devices = await navigator.mediaDevices.enumerateDevices();

			for (const device of devices)
			{
				if (device.kind !== 'audioinput')
					continue;

				this._audioDevices[device.deviceId] = device;
			}

			store.dispatch(
				meActions.setAudioDevices(this._audioDevices));
		}
		catch (error)
		{
			logger.error('_updateAudioDevices() [error:"%o"]', error);
		}
	}

	async _updateWebcams()
	{
		logger.debug('_updateWebcams()');

		// Reset the list.
		this._webcams = {};

		try
		{
			logger.debug('_updateWebcams() | calling enumerateDevices()');

			const devices = await navigator.mediaDevices.enumerateDevices();

			for (const device of devices)
			{
				if (device.kind !== 'videoinput')
					continue;

				this._webcams[device.deviceId] = device;
			}

			store.dispatch(
				meActions.setWebcamDevices(this._webcams));
		}
		catch (error)
		{
			logger.error('_updateWebcams() [error:"%o"]', error);
		}
	}

	async _getAudioDeviceId()
	{
		logger.debug('_getAudioDeviceId()');

		try
		{
			logger.debug('_getAudioDeviceId() | calling _updateAudioDevices()');

			await this._updateAudioDevices();

			const { selectedAudioDevice } = store.getState().settings;

			if (selectedAudioDevice && this._audioDevices[selectedAudioDevice])
				return selectedAudioDevice;
			else
			{
				const audioDevices = Object.values(this._audioDevices);

				return audioDevices[0] ? audioDevices[0].deviceId : null;
			}
		}
		catch (error)
		{
			logger.error('_getAudioDeviceId() [error:"%o"]', error);
		}
	}

	async _getWebcamDeviceId()
	{
		logger.debug('_getWebcamDeviceId()');

		try
		{
			logger.debug('_getWebcamDeviceId() | calling _updateWebcams()');

			await this._updateWebcams();

			const { selectedWebcam } = store.getState().settings;

			if (selectedWebcam && this._webcams[selectedWebcam])
				return selectedWebcam;
			else
			{
				const webcams = Object.values(this._webcams);

				return webcams[0] ? webcams[0].deviceId : null;
			}
		}
		catch (error)
		{
			logger.error('_getWebcamDeviceId() [error:"%o"]', error);
		}
	}

	_initSelectedAudioOutputDevice()
	{
		logger.debug('_initSelectedAudioOutputDevice()');

		if (Object.keys(this._audioOutputDevices).length > 0)
		{
			const { selectedAudioOutputDevice } = store.getState().settings;

			if (!selectedAudioOutputDevice ||
				!(selectedAudioOutputDevice in this._audioOutputDevices))
			{
				logger.debug('_initSelectedAudioOutputDevice() - setting default. Non selected or not present | deviceId = %s',
					selectedAudioOutputDevice);

				store.dispatch(
					settingsActions.setSelectedAudioOutputDevice(
						Object.keys(this._audioOutputDevices)[0]
					)
				);
			}
		}
		else
		{
			logger.debug('_initSelectedAudioOutputDevice() - no audio out devices present');
		}
	}

	async _updateAudioOutputDevices()
	{
		logger.debug('_updateAudioOutputDevices()');

		// Reset the list.
		this._audioOutputDevices = {};

		try
		{
			logger.debug('_updateAudioOutputDevices() | calling enumerateDevices()');

			const devices = await navigator.mediaDevices.enumerateDevices();

			for (const device of devices)
			{
				if (device.kind !== 'audiooutput')
					continue;

				this._audioOutputDevices[device.deviceId] = device;
			}

			store.dispatch(
				meActions.setAudioOutputDevices(this._audioOutputDevices));

			this._initSelectedAudioOutputDevice();
		}
		catch (error)
		{
			logger.error('_updateAudioOutputDevices() [error:"%o"]', error);
		}
	}

	async sendConsultationRequest(consultantId)
	{
		this._setConsultationStatus(consultantId, 'sending');

		const serverHost = `${window.location.protocol}//${window.location.host}/`;

		try
		{
			await this.sendRequest('sendConsultationRequest',
				{ serverHost, consultantId });

		}
		catch (error)
		{
			this._setConsultationStatus(consultantId, 'error');
			logger.error('sendConsultationRequest() [error:"%s"]', error);
		}
	}

	_setConsultationStatus(id, status)
	{
		store.dispatch(meActions.setConsultationStatus(id, status, false));

		if (status === 'error')
		{
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.sendConsultationRequestError',
						defaultMessage : 'Sending the consultation request has failed'
					})
				}));
		}
		else if (status === 'sent')
		{
			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.sendConsultationRequestSent',
						defaultMessage : 'The consultation request has been sent successfully'
					})
				}));
		}

		setTimeout(() =>
		{
			store.dispatch(meActions.setConsultationStatus(id, null, true));
		}, 5 * 60 * 1000);
	}

	async sendRecorderRequest(recorderRequest, params)
	{
		const serverHost = window.location.hostname;

		try
		{
			/*
			if (recorderRequest === 'recorderSetFullscreenConsumer' &&
				(store.getState().room.recorderState === 'started' ||
				store.getState().room.streamerState === 'started'))
			{
				await this.sendRequest(recorderRequest, params);
			}
			else if (recorderRequest === 'recorderRemoveFullscreenConsumer' &&
				(store.getState().room.recorderState === 'started' ||
				store.getState().room.streamerState === 'started'))
			{
				await this.sendRequest(recorderRequest, { });
			}
			else if (recorderRequest === 'recorderSetSelectedPeer' &&
				(store.getState().room.recorderState === 'started' ||
				store.getState().room.streamerState === 'started'))
			{
				await this.sendRequest(recorderRequest, params);
			}
			else if (recorderRequest === 'recorderRemoveSelectedPeer' &&
				(store.getState().room.recorderState === 'started' ||
				store.getState().room.streamerState === 'started'))
			{
				await this.sendRequest(recorderRequest, { });
			}
			else if (recorderRequest === 'recorderSetSoloVideo' &&
				(store.getState().room.recorderState === 'started' ||
				store.getState().room.streamerState === 'started'))
			{
				await this.sendRequest(recorderRequest, params);
			}
			else if (recorderRequest === 'recorderRemoveSoloVideo' &&
				(store.getState().room.recorderState === 'started' ||
				store.getState().room.streamerState === 'started'))
			{
				await this.sendRequest(recorderRequest, { });
			}
			else if (recorderRequest === 'recorderStartRecording')
			{
				await this.sendRequest(recorderRequest, { serverHost });

				store.dispatch(roomActions.setRecorderState('starting'));
			}
			else if (recorderRequest === 'recorderStopRecording')
			{
				await this.sendRequest(recorderRequest, { });

				store.dispatch(roomActions.setRecorderState('stopping'));
			}
			else if (recorderRequest === 'recorderStartStreaming')
			{
				await this.sendRequest(recorderRequest, { serverHost });

				store.dispatch(roomActions.setStreamerState('starting'));
			}
			else if (recorderRequest === 'recorderStopStreaming')
			{
				await this.sendRequest(recorderRequest, { });

				store.dispatch(roomActions.setStreamerState('stopping'));
			}
			*/

			if (recorderRequest === 'recorderSetFullscreenConsumer')
			{
				await this.sendRequest(recorderRequest, params);
			}
			else if (recorderRequest === 'recorderRemoveFullscreenConsumer')
			{
				await this.sendRequest(recorderRequest, { });
			}
			else if (recorderRequest === 'recorderSetSelectedPeer')
			{
				await this.sendRequest(recorderRequest, params);
			}
			else if (recorderRequest === 'recorderRemoveSelectedPeer')
			{
				await this.sendRequest(recorderRequest, { });
			}
			else if (recorderRequest === 'recorderSetSoloVideo')
			{
				await this.sendRequest(recorderRequest, params);
			}
			else if (recorderRequest === 'recorderRemoveSoloVideo')
			{
				await this.sendRequest(recorderRequest, { });
			}
			else if (recorderRequest === 'recorderStartRecording')
			{
				await this.sendRequest(recorderRequest, { serverHost });

				store.dispatch(roomActions.setRecorderState('starting'));
			}
			else if (recorderRequest === 'recorderStopRecording')
			{
				await this.sendRequest(recorderRequest, { });

				store.dispatch(roomActions.setRecorderState('stopping'));
			}
			else if (recorderRequest === 'recorderStartStreaming')
			{
				await this.sendRequest(recorderRequest, { serverHost });

				store.dispatch(roomActions.setStreamerState('starting'));
			}
			else if (recorderRequest === 'recorderStopStreaming')
			{
				await this.sendRequest(recorderRequest, { });

				store.dispatch(roomActions.setStreamerState('stopping'));
			}
		}
		catch (error)
		{
			logger.error('sendRecorderRequest() [reuest: "%s", error:"%s"]',
				recorderRequest, error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.recorderRequestError',
						defaultMessage : 'The request to the video recorder has failed'
					})
				}));
		}
	}

	setSoloVideoProducerId(soloVideoProducerId)
	{
		store.dispatch(roomActions.setSoloVideoProducerId(soloVideoProducerId));
	}

	_BMDRouterParseResponse(res)
	{
		if (!res)
			return false;

		const lines = res.split('\n');

		if (lines.length > 0 && lines[0] === 'ACK')
		{
			this._BMDRouterParseStatus(res);

			return true;
		}

		return false;
	}

	_BMDRouterParseStatus(res)
	{
		const lines = res.split('\n');

		for (let i = 0; i < lines.length; i++)
		{
			const line = lines[i];

			if (line === 'VIDEO OUTPUT ROUTING:')
			{
				for (let j = i + 1; j < lines.length; j++)
				{
					const outputLine = lines[j];

					if (outputLine === '')
					{
						break;
					}

					const mapping = outputLine.split(' ');

					if (mapping.length === 2)
					{
						store.dispatch(
							meActions.setRouterState(mapping[0], mapping[1]));
					}
				}

				break;
			}
		}
	}

	async _BMDRouterGetStatus()
	{
		store.dispatch(
			meActions.setBMDChangeInProgress(true));

		await this.fetchWithTimeout('http://127.0.0.1/BMDRouterControler/BMDRouterControler.php', {
			method         : 'GET',
			cache          : 'no-cache',
			redirect       : 'follow',
			referrerPolicy : 'no-referrer' })
			.then((response) => response.json())
			.then((json) =>
			{
				if (json.config)
				{
					store.dispatch(
						meActions.setRouterConfig(json.config));
				}

				if (json.error || !json.status)
				{
					logger.error('_BMDRouterGetStatus() - error geting BMD router status', json.error);
				}
				else
				{
					this._BMDRouterParseStatus(json.status);
				}
			})
			.catch((error) =>
			{
				logger.error('_BMDRouterGetStatus() - error geting BMD router status', error);
			});

		store.dispatch(
			meActions.setBMDChangeInProgress(false));
	}

	async BMDRouterChangeInput(dest, id)
	{
		store.dispatch(
			meActions.setBMDChangeInProgress(true));

		await this.fetchWithTimeout(`http://127.0.0.1/BMDRouterControler/BMDRouterControler.php?set=1&dest=${dest}&id=${id}`, {
			method         : 'GET',
			cache          : 'no-cache',
			redirect       : 'follow',
			referrerPolicy : 'no-referrer' })
			.then((response) => response.json())
			.then((json) =>
			{
				if (json.config)
				{
					store.dispatch(
						meActions.setRouterConfig(json.config));
				}

				if (json.error || !this._BMDRouterParseResponse(json.change))
				{
					logger.error('BMDRouterChangeInput() - error setting router input', json.error);

					store.dispatch(requestActions.notify(
						{
							type : 'error',
							text : intl.formatMessage({
								id             : 'room.videoRouterError',
								defaultMessage : 'Switching of the video input has failed'
							})
						}));
				}
			})
			.catch((error) =>
			{
				logger.error('BMDRouterChangeInput() - error setting router input', error);

				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'room.videoRouterError',
							defaultMessage : 'Switching of the video input has failed'
						})
					}));
			});

		store.dispatch(
			meActions.setBMDChangeInProgress(false));
	}

	async systemVolumeControl(mode)
	{
		let modeString = '';

		if (mode === 'inc')
			modeString = '?incVolume=1';
		else if (mode === 'dec')
			modeString = '?decVolume=1';

		await this.fetchWithTimeout(`http://127.0.0.1/medVCAudioVolume/medVCAudioVolume.php${modeString}`, {
			method         : 'GET',
			cache          : 'no-cache',
			redirect       : 'follow',
			referrerPolicy : 'no-referrer' })
			.then((response) => response.json())
			.then((json) =>
			{
				if (json.error || json.volume === undefined)
				{
					logger.error('_getCurrentVolume() - error geting current volume', json.error);
				}
				else
				{
					store.dispatch(
						meActions.setCurrentVolume(json.volume));
				}
			})
			.catch((error) =>
			{
				logger.error('_getCurrentVolume() - error geting current volume', error);
			});
	}

	async shutdown(mode)
	{
		let modeString = '';

		if (mode === 'shutdown')
			modeString = '?shutdown=1';
		else if (mode === 'restart')
			modeString = '?restart=1';

		await this.fetchWithTimeout(`http://127.0.0.1/medVCShutdown/medVCShutdown.php${modeString}`, {
			method         : 'GET',
			cache          : 'no-cache',
			redirect       : 'follow',
			referrerPolicy : 'no-referrer' })
			.then((response) => response.json())
			.then((json) =>
			{
				if (json.error || !json.shutdownEnabled)
				{
					logger.error('shutdown() - error in shutdown command', json.error);
				}
				else
				{
					store.dispatch(
						meActions.setShutdownEnabled(true));
				}
			})
			.catch((error) =>
			{
				logger.error('shutdown() - error in shutdown command', error);
			});
	}

	async getAddressBook()
	{
		await this.fetchWithTimeout('http://127.0.0.1/medVCAddressBook/medVCAddressBook.php', {
			credentials    : 'omit',
			method         : 'GET',
			cache          : 'no-cache',
			redirect       : 'follow',
			referrerPolicy : 'unsafe-url' })
			.then((response) =>
			{
				return response.json();
			})
			.then((json) =>
			{
				if (json.error)
				{
					logger.error('Error getting address book', json.error);
				}
				else if (json.addressBook)
				{
					store.dispatch(
						meActions.setAddressBook(json.addressBook));
				}
			})
			.catch((error) =>
			{
				logger.error('Exception while getting address book', error);
			});
	}

	async _initTerminalClient()
	{
		try
		{
			const response = await this.fetchWithTimeout(config.terminalUrl,
				{
					credentials    : 'omit',
					method         : 'GET',
					cache          : 'no-cache',
					redirect       : 'follow',
					referrerPolicy : 'unsafe-url'
				});

			const terminalClient = new TerminalClient({ uri: config.terminalUrl });

			await terminalClient.connectServices();

			this._terminalClient = terminalClient;
		}
		catch (error)
		{
			logger.error('Exception while starting terminal client', error);
		}
	}

	async fetchWithTimeout(resource, options = {})
	{
		const { timeout = 5000 } = options;
		const abortController = new AbortController();
		const id = setTimeout(() => abortController.abort(), timeout);
		const response = await fetch(resource, {
			...options,
			signal : abortController.signal
		});

		clearTimeout(id);

		return response;
	}

	_getSnapshotProducer(snapshotProducerId)
	{
		return store.getState().producers[snapshotProducerId];
	}

	_getMediaSoupSnapshotProducer(snapshotProducerId)
	{
		let producer;

		if (!snapshotProducerId)
			return producer;

		if (this._webcamProducer &&
			this._webcamProducer.id === snapshotProducerId)
		{
			producer = this._webcamProducer;
		}
		else if (this._screenSharingProducer &&
			this._screenSharingProducer.id === snapshotProducerId)
		{
			producer = this._screenSharingProducer;
		}
		else
		{
			for (const extraProducer of this._extraVideoProducers.values())
			{
				if (extraProducer.id === snapshotProducerId)
				{
					producer = extraProducer;

					break;
				}
			}
		}

		return producer;
	}

	_getImageFromSnapshot(snapshot)
	{
		return new Promise((resolve, reject) =>
		{
			const img = new Image();

			img.onload = () => resolve(img);
			img.onerror = reject;
			img.src = snapshot;
		});
	}

	_setFabricCanvasBackround(fabricCanvas, snapshot)
	{
		return new Promise((resolve, reject) =>
		{
			fabricCanvas.setBackgroundImage(
				snapshot,
				() =>
				{
					fabricCanvas.renderAll.bind(fabricCanvas);
					resolve();
				},
				{
					width   : fabricCanvas.width,
					height  : fabricCanvas.height,
					originX : 'left',
					originY : 'top'
				}
			);
		});
	}

	async _takeSnapshot(producer)
	{
		if (!producer)
			return null;

		let snapshot = null;

		if (producer.snapshot)
			snapshot = producer.snapshot;
		else
			snapshot = await this._createSnapshot(producer, 1.0);

		if (snapshot && producer.pathsToDraw && producer.pathsToDraw.length > 0)
		{
			const imageBitmap = await this._getImageFromSnapshot(snapshot);
			const fabricCanvas = new fabric.StaticCanvas(
				'uploadSnapshotCanvas',
				{ width: imageBitmap.width, height: imageBitmap.height }
			);

			producer.pathsToDraw.forEach((pathEntry) =>
			{
				const factor = fabricCanvas.width / pathEntry.srcWidth;

				fabric.util.enlivenObjects([ pathEntry.path ], (paths) =>
				{
					paths.forEach((pathObject) =>
					{
						pathObject.scaleX = pathObject.scaleX * factor;
						pathObject.scaleY = pathObject.scaleY * factor;
						pathObject.left = pathObject.left * factor;
						pathObject.top = pathObject.top * factor;
						pathObject.setCoords();
						pathObject.selectable = false;

						fabricCanvas.add(pathObject);
					});
				});
			});

			await this._setFabricCanvasBackround(fabricCanvas, snapshot);

			fabricCanvas.renderAll();

			snapshot = fabricCanvas.toDataURL({ format: 'jpeg', quality: 0.9 });
		}

		return snapshot;
	}

	async takeAndUploadSnapshot(snapshotProducerId)
	{
		const producer = this._getSnapshotProducer(snapshotProducerId);

		if (!producer)
			return;

		this._setUploadSnapshotInProgress(true);

		const snapshot = await this._takeSnapshot(producer);

		if (snapshot)
		{
			const now = new Date();
			const snapshotTitle = format(now, 'yyyy-MM-dd HH:mm:ss');
			const snapshotFileName = format(now, 'yyyy-MM-dd_HH-mm-ss');
			const snapshotId = `${snapshotFileName}_${Math.floor(Math.random() * 1000000)}`;
			const snapshotStatus = 'new';
			const uploadSnapshotCurrent = {
				snapshotId,
				snapshotTitle,
				snapshotFileName,
				snapshotStatus,
				snapshot,
				producerId : producer.id
			};

			if (this._uploadSnapshotCurrentTimeout)
			{
				clearTimeout(this._uploadSnapshotCurrentTimeout);
				this._uploadSnapshotCurrentTimeout = null;
			}

			store.dispatch(
				meActions.addToUploadSnapshots(uploadSnapshotCurrent));

			store.dispatch(
				meActions.setUploadSnapshotCurrent(uploadSnapshotCurrent));

			store.dispatch(toolareaActions.incSnapshotCount());

			if (
				!store.getState().toolarea.toolAreaOpen ||
				(store.getState().toolarea.toolAreaOpen &&
				store.getState().toolarea.currentToolTab !== 'snapshots')
			) // Make sound
			{
				store.dispatch(
					roomActions.setToolbarsVisible(true));
				this._soundNotification();
			}

			this._uploadSnapshotCurrentTimeout = setTimeout(() =>
				this._removeUploadSnapshotCurrent(), 2000);

			if (store.getState().room.isUploadSnapshotEnabled)
				await this.sendRequest('uploadSnapshot', { ...uploadSnapshotCurrent });
		}

		this._setUploadSnapshotInProgress(false);
	}

	_setUploadSnapshotInProgress(isInProgress)
	{
		if (this._uploadSnapshotInProgressTimeout)
		{
			clearTimeout(this._uploadSnapshotInProgressTimeout);
			this._uploadSnapshotInProgressTimeout = null;
		}

		if (isInProgress)
		{
			store.dispatch(
				meActions.setUploadSnapshotInProgress(true));

			this._uploadSnapshotInProgressTimeout = setTimeout(() =>
			{
				store.dispatch(
					meActions.setUploadSnapshotInProgress(false));
			}, 15000);
		}
		else
		{
			store.dispatch(
				meActions.setUploadSnapshotInProgress(false));
		}
	}

	async requestPeerSnapshot(snapshotPeerId, snapshotProducerId)
	{
		this._setUploadSnapshotInProgress(true);

		try
		{
			await this.sendRequest('requestPeerSnapshot',
				{ snapshotPeerId, snapshotProducerId });
		}
		catch (error)
		{
			logger.error('requestPeerSnapshot() [error:"%o"]', error);

			this._setUploadSnapshotInProgress(false);
		}
	}

	_removeUploadSnapshotCurrent()
	{
		store.dispatch(
			meActions.setUploadSnapshotCurrent(null));

		this._uploadSnapshotCurrentTimeout = null;
	}

	downloadSharedFile(url, fileName)
	{
		if (!url || !fileName)
			return;

		const link = document.createElement('a');

		link.download = fileName;
		link.href = url;
		link.click();
	}

	downloadSnapshot(snapshotObject)
	{
		if (!snapshotObject)
			return;

		const link = document.createElement('a');

		link.download = `${snapshotObject.snapshotFileName}.jpg`;
		link.href = snapshotObject.snapshot;
		link.click();
		store.dispatch(requestActions.notify(
			{
				text : intl.formatMessage({
					id             : 'room.snapshotDownloaded',
					defaultMessage : 'The snapshot has been downloaded to the default location'
				})
			}));
	}

	async _createSnapshot(producer, quality)
	{
		const mediaSoupProducer = this._getMediaSoupSnapshotProducer(producer?.id);

		if (mediaSoupProducer && producer && !producer.paused && producer.track)
		{
			const aspectRatio = store.getState().settings.aspectRatio;

			let finalWidth = mediaSoupProducer.appData.width;

			let finalHeight = mediaSoupProducer.appData.height;

			if (finalWidth !== Math.round(finalHeight * aspectRatio))
			{
				if (finalWidth > finalHeight)
				{
					finalHeight = Math.round(finalWidth / aspectRatio);
				}
				else
				{
					finalWidth = Math.round(finalHeight * aspectRatio);
				}
			}

			if ('ImageCapture' in window)
			{
				let canvas;

				const imageCapture = new ImageCapture(producer.track);
				const snapshot = await imageCapture.grabFrame().then((imageBitmap) =>
				{
					canvas = document.createElement('canvas');
					canvas.width = finalWidth;
					canvas.height = finalHeight;

					const context = canvas.getContext('2d');

					context.fillStyle = '#000000';
					context.fillRect(0, 0, canvas.width, canvas.height);

					const hRatio = canvas.width / imageBitmap.width;
					const vRatio = canvas.height / imageBitmap.height;
					const ratio = Math.min(hRatio, vRatio);
					const centerShiftX = (canvas.width - imageBitmap.width * ratio) / 2;
					const centerShiftY = (canvas.height - imageBitmap.height * ratio) / 2;

					context.drawImage(imageBitmap,
						0, 0, imageBitmap.width, imageBitmap.height,
						centerShiftX, centerShiftY,
						imageBitmap.width * ratio, imageBitmap.height * ratio
					);

					const snapshotTemp = canvas.toDataURL('image/jpeg', quality);

					logger.debug('ImageCapture w: %s, h: %s, size: %s',
						canvas.width, canvas.height, snapshotTemp.length);

					canvas.remove();
					imageBitmap.close();

					return snapshotTemp;
				})
					.catch((error) =>
					{
						if (canvas)
							canvas.remove();

						logger.error('Failed to grab snapshot', error);
					});

				return snapshot;
			}
			else
			{
				const video = document.createElement('video');
				const canvas = document.createElement('canvas');
				const context = canvas.getContext('2d');
				const stream = new MediaStream([ producer.track ]);

				video.srcObject = stream;

				const promise = new Promise((resolve, reject) =>
				{
					video.addEventListener('loadeddata', async () =>
					{
						const { videoWidth, videoHeight } = video;

						canvas.width = finalWidth;
						canvas.height = finalHeight;

						context.fillStyle = '#000000';
						context.fillRect(0, 0, canvas.width, canvas.height);

						try
						{
							await video.play();

							const hRatio = canvas.width / videoWidth;
							const vRatio = canvas.height / videoHeight;
							const ratio = Math.min(hRatio, vRatio);
							const centerShiftX = (canvas.width - videoWidth * ratio) / 2;
							const centerShiftY = (canvas.height - videoHeight * ratio) / 2;

							context.drawImage(video,
								0, 0, videoWidth, videoHeight,
								centerShiftX, centerShiftY,
								videoWidth * ratio, videoHeight * ratio
							);

							const snapshotTemp = canvas.toDataURL('image/jpeg', quality);

							logger.debug('NO ImageCapture w: %s, h: %s, size: %s',
								canvas.width, canvas.height, snapshotTemp.length);

							canvas.remove();
							resolve(snapshotTemp);
						}
						catch (error)
						{
							reject(error);
						}
					});
				});

				const snapshot = await promise.catch((error) =>
				{
					if (canvas)
						canvas.remove();

					logger.error('Failed to grab snapshot - combablity mode', error);

					return null;
				});

				return snapshot;
			}
		}

		return null;
	}

	async requestSnapshot(snapshotPeerId, snapshotProducerId)
	{
		this._setUploadSnapshotInProgress(true);

		if (snapshotPeerId === this._peerId)
		{
			const producer = this._getSnapshotProducer(snapshotProducerId);

			const snapshot = await this._createSnapshot(producer, 0.9);

			if (snapshot)
			{
				this.setSnapshot(this._peerId, producer, snapshot, this._peerId);
			}
			else
			{
				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'room.requestSnapshotError',
							defaultMessage : 'The request to pause the video has failed'
						})
					}));

				this._setUploadSnapshotInProgress(false);
			}
		}
		else
		{
			try
			{
				await this.sendRequest('requestSnapshot',
					{ snapshotPeerId, snapshotProducerId, requestPeerId: this._peerId });
			}
			catch (error)
			{
				logger.error('requestSnapshot() [error:"%o"]', error);

				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'room.requestSnapshotError',
							defaultMessage : 'The request to pause the video has failed'
						})
					}));

				this._setUploadSnapshotInProgress(false);
			}
		}
	}

	async setSnapshot(snapshotPeerId, producer, snapshot, requestPeerId)
	{
		try
		{
			await this.sendRequest('setSnapshot',
				{ snapshotPeerId, snapshotProducerId: producer.id, snapshot, requestPeerId });

			store.dispatch(producerActions.setSnapshot(producer.id, snapshot));

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.setSnapshot',
						defaultMessage : 'The video has been paused for all'
					})
				}));
		}
		catch (error)
		{
			logger.error('setSnapshot() [error:"%o"]', error);
		}

		if (snapshotPeerId === requestPeerId)
		{
			this._setUploadSnapshotInProgress(false);
		}
	}

	async removeSnapshot(snapshotPeerId, snapshotProducerId)
	{
		this._setUploadSnapshotInProgress(true);

		try
		{
			await this.sendRequest('removeSnapshot',
				{ snapshotPeerId, snapshotProducerId });

			if (snapshotPeerId === this._peerId)
			{
				store.dispatch(producerActions.removeSnapshot(snapshotProducerId));
			}
			else
			{
				store.dispatch(consumerActions.removeSnapshot(snapshotProducerId));
			}

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.removeSnapshot',
						defaultMessage : 'The operating room video is again live for all'
					})
				}));
		}
		catch (error)
		{
			logger.error('removeSnapshot() [error:"%o"]', error);
		}

		this._setUploadSnapshotInProgress(false);
	}

	async toggleDrawingMode(start)
	{
		try
		{
			// Na razie nie wysyłamy do innych, może w przyszłości
			// await this.sendRequest('toggleDrawingMode', { start });

			// teraz można rysowac na wszystkich, więc nie usuwamy przypięcia
			/*
			if (start)
			{
				this.clearSelectedPeers();
			}
			*/

			store.dispatch(roomActions.toggleDrawingMode(start));

			if (start)
			{
				store.dispatch(requestActions.notify(
					{
						text : intl.formatMessage({
							id             : 'room.startDrawingMode',
							defaultMessage : 'The drawing mode has been started'
						})
					}));
			}
			else
			{
				store.dispatch(requestActions.notify(
					{
						text : intl.formatMessage({
							id             : 'room.stopDrawingMode',
							defaultMessage : 'The drawing mode has been stopped'
						})
					}));
			}
		}
		catch (error)
		{
			logger.error('startDrawingMode() [error:"%o"]', error);
		}
	}

	_havePermission(permission)
	{
		const {
			roomPermissions,
			allowWhenRoleMissing
		} = store.getState().room;

		if (!roomPermissions)
			return false;

		const { roles } = store.getState().me;

		const permitted = roles.some((userRoleId) =>
			roomPermissions[permission].some((permissionRole) =>
				userRoleId === permissionRole.id
			)
		);

		if (permitted)
			return true;

		if (!allowWhenRoleMissing)
			return false;

		const peers = Object.values(store.getState().peers);

		// Allow if config is set, and no one is present
		if (allowWhenRoleMissing.includes(permission) &&
			peers.filter(
				(peer) =>
					peer.roles.some(
						(roleId) => roomPermissions[permission].some((permissionRole) =>
							roleId === permissionRole.id
						)
					)
			).length === 0
		)
			return true;

		return false;
	}

	_closeConsumer(consumerId)
	{
		const consumer = this._consumers.get(consumerId);

		this._spotlights.removeVideoConsumer(consumerId);

		if (store.getState().room.fullScreenConsumer === consumerId)
			store.dispatch(roomActions.toggleConsumerFullscreen(null));

		if (!consumer)
			return;

		consumer.close();

		if (store.getState().room.soloVideoProducerId === consumer.producerId)
			this.setSoloVideoProducerId(null);

		if (consumer.hark != null)
			consumer.hark.stop();

		this._consumers.delete(consumerId);

		const { peerId } = consumer.appData;

		store.dispatch(
			consumerActions.removeConsumer(consumerId, peerId));
	}

	_chooseSvcEncoding(svcProfiles, size)
	{
		let encoding;

		const sortedMap = new Map([ ...Object.entries(svcProfiles) ]
			.sort((a, b) => parseInt(b[0]) - parseInt(a[0])));

		for (const [ key, value ] of sortedMap)
		{
			if (key < size)
			{
				if (encoding === null)
				{
					encoding = value;
				}

				break;
			}

			encoding = value;
		}

		return encoding;
	}

	_chooseSimulcastEncodings(simulcastProfiles, size)
	{
		let encodings;

		const sortedMap = new Map([ ...Object.entries(simulcastProfiles) ]
			.sort((a, b) => parseInt(b[0]) - parseInt(a[0])));

		for (const [ key, value ] of sortedMap)
		{
			if (key < size)
			{
				if (encodings === null)
				{
					encodings = value;
				}

				break;
			}

			encodings = value;
		}

		// hack as there is a bug in mediasoup
		if (encodings.length === 1)
		{
			encodings.push({ ...encodings[0] });
		}

		return encodings;
	}

	_getCodecOptions(resolution)
	{
		let codecOptions;

		if (resolution === 'ultra')
			codecOptions = config.codecOptionsUltra;
		else
			codecOptions = config.codecOptions;

		logger.debug('_getCodecOptions returning: %o', codecOptions);

		return codecOptions;
	}

	_getVideoCodec(preferredCodec)
	{
		let codec;

		if (preferredCodec)
		{
			const mimeType = `video/${preferredCodec}`;

			codec = this._mediasoupDevice
				.rtpCapabilities
				.codecs
				.find((c) => c.mimeType.toLowerCase() === mimeType.toLowerCase());
		}

		if (!codec)
		{
			codec = this._mediasoupDevice
				.rtpCapabilities
				.codecs
				.find((c) => c.kind === 'video');
		}

		return codec;
	}

	_getSvcEncoding(width, height, isScreenSharing = false)
	{
		const size = (width > height ? width : height);

		let encoding;

		if (isScreenSharing)
		{
			encoding = config.svcScreenSharingProfile;
		}
		else
		{
			let svcProfiles;

			if (this._encodingProblems.doSwitch === 'switch')
			{
				svcProfiles = config.svcProfilesProblem;
			}
			else if (config.roomsWith3d[this._roomId] &&
				store.getState().settings.in3dModeSend)
			{
				svcProfiles = config.svcProfiles3d;
			}
			else if (this._device.platform !== 'desktop')
			{
				svcProfiles = config.svcProfilesMobile;
			}
			else
			{
				svcProfiles = config.svcProfiles;
			}

			encoding = this._chooseSvcEncoding(svcProfiles, size);
		}

		return encoding;
	}

	// no simulcast for scree sharing, for sceen sharing this should never be called
	_getSimulcastEncodings(width, height)
	{
		const size = (width > height ? width : height);

		let simulcastProfiles;

		if (config.roomsWith3d[this._roomId] &&
			store.getState().settings.in3dModeSend)
		{
			simulcastProfiles = config.simulcastProfiles3d;
		}
		else
		{
			simulcastProfiles = config.simulcastProfiles;
		}

		const encodings = this._chooseSimulcastEncodings(simulcastProfiles, size);

		return encodings;
	}

	setHideNoVideoParticipants(hideNoVideoParticipants)
	{
		this._spotlights.hideNoVideoParticipants = hideNoVideoParticipants;
	}

	_isNotFollowRecorder()
	{
		return [ 4, 5, 6 ].includes(store.getState().me.recorderMode);
	}

	async _createConsumer(consumerData)
	{
		const {
			peerId,
			producerId,
			id,
			kind,
			rtpParameters,
			type,
			appData,
			producerPaused,
			score
		} = consumerData;

		logger.debug('_createConsumer() - Creating new consumer %o', consumerData);

		const consumer = await this._recvTransport.consume(
			{
				id,
				producerId,
				kind,
				rtpParameters,
				appData : { ...appData, peerId } // Trick.
			});

		if (this._recvTransport.appData.encodedInsertableStreams)
		{
			const { enableOpusDetails } = store.getState().settings;

			if (kind === 'audio' && enableOpusDetails)
				opusReceiverTransform(consumer.rtpReceiver, consumer.id);
			else
				directReceiverTransform(consumer.rtpReceiver);
		}

		// Store in the map.
		this._consumers.set(consumer.id, consumer);

		consumer.on('transportclose', () =>
		{
			this._consumers.delete(consumer.id);
		});

		const { spatialLayers, temporalLayers } =
			mediasoupClient.parseScalabilityMode(
				consumer.rtpParameters.encodings[0].scalabilityMode);

		let preferredSpatialLayer = 0;

		let preferredTemporalLayer = 0;

		if (store.getState().me.isRecorder)
		{
			preferredSpatialLayer = spatialLayers - 1;
			preferredTemporalLayer = temporalLayers - 1;

			if (preferredSpatialLayer < 0)
				preferredSpatialLayer = 0;
			if (preferredTemporalLayer < 0)
				preferredTemporalLayer = 0;
		}

		const consumerStoreObject = {
			id                     : consumer.id,
			peerId                 : peerId,
			producerId             : producerId,
			kind                   : kind,
			type                   : type,
			locallyPaused          : false,
			remotelyPaused         : producerPaused,
			rtpParameters          : consumer.rtpParameters,
			source                 : consumer.appData.source,
			width                  : consumer.appData.width,
			height                 : consumer.appData.height,
			pathsToDraw            : consumer.appData.pathsToDraw,
			snapshot               : consumer.appData.snapshot,
			resolutionScalings     : consumer.appData.resolutionScalings,
			spatialLayers          : spatialLayers,
			temporalLayers         : temporalLayers,
			preferredSpatialLayer  : preferredSpatialLayer,
			preferredTemporalLayer : preferredTemporalLayer,
			priority               : 1,
			codec                  : consumer.rtpParameters.codecs[0].mimeType.split('/')[1],
			track                  : consumer.track,
			score                  : score,
			audioGain              : undefined,
			opusConfig             : null
		};

		this._spotlights.addVideoConsumer(consumerStoreObject);

		store.dispatch(consumerActions.addConsumer(consumerStoreObject, peerId));

		await this._startConsumer(consumer);

		if (kind === 'video')
		{
			await this.setConsumerPreferredLayers(
				consumer.id, preferredSpatialLayer, preferredTemporalLayer);
		}
		else if (kind === 'audio')
		{
			consumer.volume = 0;

			const stream = new MediaStream();

			stream.addTrack(consumer.track);

			if (!stream.getAudioTracks()[0])
				throw new Error('request.newConsumer | given stream has no audio track');

			consumer.hark = hark(stream, { play: false, interval: 100 });

			consumer.hark.on('volume_change', (volume) =>
			{
				volume = Math.round(volume);

				if (consumer && volume !== consumer.volume)
				{
					consumer.volume = volume;

					store.dispatch(peerVolumeActions.setPeerVolume(peerId, volume));
				}
			});
		}

		if (store.getState().me.isRecorder &&
			!this._isNotFollowRecorder() &&
			this._recorderInitState.fullscreenConsumer &&
			this._recorderInitState.fullscreenConsumer.peerId === peerId &&
			this._recorderInitState.fullscreenConsumer.producerId === producerId)
		{
			if (store.getState().room.fullScreenConsumer !== consumer.id)
			{
				store.dispatch(roomActions.toggleConsumerFullscreen(consumer.id));
			}

			this._recorderInitState.fullscreenConsumer = null;
		}

		if (store.getState().me.isRecorder &&
			!this._isNotFollowRecorder() &&
			this._recorderInitState.soloVideo &&
			this._recorderInitState.soloVideo.peerId === peerId &&
			this._recorderInitState.soloVideo.producerId === producerId)
		{
			// uwaga usuwa też solo peer
			this.setSelectedPeer(peerId);

			this.setSoloVideoProducerId(producerId);

			this._recorderInitState.soloVideo = null;
		}

		if (store.getState().me.isRecorder &&
			store.getState().me.recorderMode === 6)
		{
			const consumerPeer = store.getState().peers[peerId];
			const { videoId, clientId, forceExtraVideo } =
				store.getState().me.videoForwardConfig;

			const debugData = {
				peerId          : consumerPeer?.id,
				peerClientId    : consumerPeer?.clientId,
				consumerVideoId : consumer?.appData?.deviceId,
				consumerSource  : consumer?.appData?.source,
				kind,
				clientId,
				videoId
			};

			logger.debug('_createConsumer() recorderMode 6 debug: %o', debugData);

			if (consumerPeer &&
				kind === 'video' &&
				clientId &&
				videoId &&
				consumerPeer.clientId === clientId &&
				consumer.appData.deviceId === videoId
			)
			{
				this.setSelectedPeer(peerId);

				if (!store.getState().room.fullScreenConsumer)
				{
					store.dispatch(
						roomActions.toggleConsumerFullscreen(consumer.id));
				}
				else if ((consumer.appData.source === 'webcam' && !forceExtraVideo) ||
					(consumer.appData.source === 'extravideo' && forceExtraVideo)
				)
				{
					store.dispatch(
						roomActions.toggleConsumerFullscreen(null));

					store.dispatch(
						roomActions.toggleConsumerFullscreen(consumer.id));
				}
			}
			else if (consumerPeer &&
				kind === 'audio' &&
				clientId &&
				(
					consumerPeer.clientId !== clientId ||
					consumer.appData.source !== 'mic' ||
					consumer.appData.isScreenAudio
				)
			)
			{
				this.modifyPeerConsumer(peerId, 'mic', true);
			}
		}
	}

	_onBeforeUnload(event)
	{
		event.preventDefault();
		event.returnValue = '';
	}

	async attachSnapshotToChat(snapshotObject)
	{
		const fileName = `${snapshotObject.snapshotFileName}.jpg`;
		const conv = await fetch(snapshotObject.snapshot);
		const fileContent = await conv.blob();
		const file = new File([ fileContent ], fileName,
			{
				type         : 'image/jpeg',
				lastModified : new Date()
			}
		);

		this.attachFilesToChat([ file ]);
	}

	async attachFilesToChat(fileList)
	{
		fileList.forEach(async (file) =>
		{
			const fileId = (Math.floor(Math.random() * (Number.MAX_SAFE_INTEGER - 1)) + 1)
				.toString().padStart(32, '0');

			const serverHost = `${window.location.protocol}//${window.location.hostname}`;

			const sender = store.getState().me;

			await this.shareFilesSocketIo({
				type       : 'file',
				time       : Date.now(),
				serverHost : serverHost,
				fileId     : fileId,
				fileName   : file.name,
				sender     : 'response',
				isRead     : null,
				name       : sender.displayName,
				peerId     : sender.id,
				attachment : file
			});
		});
	}

	_setVideoTrackContentHint(track, codec, isScreenSharing = false)
	{
		// Nie działa dla VP9 !!!!!!
		if (codec === 'vp9')
			return;

		if (!track)
			return;

		if ('contentHint' in track)
		{
			let hint;

			if (isScreenSharing)
				hint = config.screenSharingTrackContentHint;
			else
				hint = config.videoTrackContentHint;

			if (hint && hint !== '')
				track.contentHint = hint;
		}
	}

	async _useCanvasStream(streamOrig, frameRate)
	{
		const trackOrig = streamOrig.getVideoTracks()[0];
		const { deviceId: trackDeviceId, width, height } = trackOrig.getSettings();
		const streamCanvasElement = document.createElement('canvas');
		const drawVideoElement = document.createElement('video');

		drawVideoElement.srcObject = streamOrig;
		drawVideoElement.addEventListener('loadedmetadata', (e) =>
		{
			drawVideoElement.play();
		});

		streamCanvasElement.width = width;
		streamCanvasElement.height = height;

		const ctx = streamCanvasElement.getContext('2d',
			{ willReadFrequently: false });

		ctx.textBaseline = 'middle';
		ctx.textAlign = 'center';
		ctx.fillStyle = 'black';
		ctx.strokeStyle = 'white';
		ctx.font = '200pt Verdana';

		setInterval(() =>
		{
			ctx.filter = `blur(${config.videoFilterBlurPixels}px)`;
			ctx.drawImage(drawVideoElement, 0, 0);
			ctx.filter = 'none';
			ctx.fillText('Optimizing cameras. Please wait.',
				streamCanvasElement.width / 2,
				streamCanvasElement.height / 2,
				streamCanvasElement.width);
			ctx.strokeText('Optimizing cameras. Please wait.',
				streamCanvasElement.width / 2,
				streamCanvasElement.height / 2,
				streamCanvasElement.width);
		}, 1000 / frameRate);

		return {
			canvasStream      : streamCanvasElement.captureStream(frameRate),
			origTrackDeviceId : trackDeviceId
		};
	}

	_isForceTcp(ip)
	{
		if (this._forceTcp)
			return true;
		else if (!ip)
			return false;

		const ipToNum = (ipConv) =>
		{
			return Number(ipConv.split('.')
				.map((d) => `000${d}`.substr(-3))
				.join('')
			);
		};

		return config.forceTcpForIps.some(
			(ipRange) => ipToNum(ipRange.start) < ipToNum(ip) &&
				ipToNum(ipRange.end) > ipToNum(ip));
	}

	async _blurBackground(streamOrig, frameRate, requestedEffect)
	{
		let videoBackgroundEffect = requestedEffect;

		const trackOrig = streamOrig.getVideoTracks()[0];
		const { deviceId: trackDeviceId, width, height } = trackOrig.getSettings();

		if (!this._blurBodyPix)
		{
			await import('@tensorflow/tfjs-backend-webgl');

			const bodyPix = await import('@tensorflow-models/body-pix');

			this._blurBodyPix = await bodyPix.load();
		}

		const outputCanvas = document.createElement('canvas');
		const outputContext = outputCanvas.getContext('2d',
			{ willReadFrequently: false });
		const segmentationCanvas = document.createElement('canvas');
		const segmentationContext = segmentationCanvas.getContext('2d',
			{ willReadFrequently: false });
		const segmentationPixelCount = width * height;
		const segmentationMask = new ImageData(width, height);
		const source = document.createElement('video');
		const blurAmount = 5;

		source.autoplay = true;
		source.playsInline = true;
		source.style.visibility = 'hidden';
		source.width = width;
		source.height = height;

		outputCanvas.hidden = true;
		outputCanvas.width = width;
		outputCanvas.height = height;

		segmentationCanvas.hidden = true;
		segmentationCanvas.width = width;
		segmentationCanvas.height = height;

		let backImage, backImageScale, backWidth, backHeight, backX, backY;

		const loadImage = (url) => new Promise((resolve, reject) =>
		{
			const img = new Image();

			img.addEventListener('load', () => resolve(img));
			img.addEventListener('error', (err) => reject(err));
			img.src = url;
		});

		const backImageDataUrl = store.getState().me.picture;

		if (videoBackgroundEffect === 'image' && !backImageDataUrl)
		{
			videoBackgroundEffect = 'blur';
		}
		else if (videoBackgroundEffect === 'image')
		{
			try
			{
				backImage = await loadImage(store.getState().me.picture);
				backImageScale = Math.min(outputCanvas.width / backImage.width,
					outputCanvas.height / backImage.height);
				backWidth = backImage.width * backImageScale;
				backHeight = backImage.height * backImageScale;
				backX = (outputCanvas.width / 2) - (backWidth / 2);
				backY = (outputCanvas.height / 2) - (backHeight / 2);
			}
			catch (error)
			{
				videoBackgroundEffect = 'blur';
				store.dispatch(settingsActions.setVideoBackgroundEffect(videoBackgroundEffect));
				logger.error('_blurBackground() image load error, reverting to blur [error:"%o"]',
					error);
			}
		}

		source.srcObject = new MediaStream([ trackOrig ]);

		if (this._blurBackgroundInterval)
		{
			clearInterval(this._blurBackgroundInterval);
			this._blurBackgroundInterval = null;
		}

		this._blurBackgroundInterval = setInterval(async () =>
		{
			segmentationContext.clearRect(0, 0,
				outputCanvas.width, outputCanvas.height);
			segmentationContext.drawImage(source, 0, 0,
				outputCanvas.width, outputCanvas.height);

			const segmentation = await this._blurBodyPix.
				segmentPerson(segmentationCanvas);

			for (let i = 0; i < segmentationPixelCount; i++)
				segmentationMask.data[(i * 4) + 3] = segmentation.data[i] ? 255 : 0;

			segmentationContext.putImageData(segmentationMask, 0, 0);

			outputContext.clearRect(0, 0, outputCanvas.width, outputCanvas.height);

			outputContext.globalCompositeOperation = 'copy';

			outputContext.drawImage(segmentationCanvas, 0, 0,
				outputCanvas.width, outputCanvas.height);

			outputContext.globalCompositeOperation = 'source-in';
			outputContext.filter = 'none';

			outputContext.drawImage(source, 0, 0, outputCanvas.width, outputCanvas.height);

			if (videoBackgroundEffect === 'blur')
			{
				outputContext.globalCompositeOperation = 'destination-over';
				outputContext.filter = `blur(${blurAmount}px)`;
				outputContext.drawImage(source, 0, 0, outputCanvas.width, outputCanvas.height);
			}
			else if (videoBackgroundEffect === 'image')
			{
				outputContext.globalCompositeOperation = 'destination-over';
				outputContext.filter = 'none';
				outputContext.drawImage(backImage, backX, backY, backWidth, backHeight);
			}

			outputContext.restore();

		}, 1000 / frameRate);

		return {
			canvasStream      : outputCanvas.captureStream(frameRate),
			origTrackDeviceId : trackDeviceId
		};
	}

	copyToClipboard(text, withNotification = true)
	{
		navigator.clipboard.writeText(text);

		if (withNotification)
			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.copiedToClipboard',
						defaultMessage : 'Copied to clipboard'
					})
				}));
	}

	async generatePermanentAuthToken()
	{

		logger.debug('generatePermanentAuthToken()');

		store.dispatch(
			roomActions.setAuthTokenChangeInProgress(true));

		try
		{
			const { permanentAuthToken } =
				await this.sendRequest('generatePermanentAuthToken');

			if (!permanentAuthToken)
			{
				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'room.generatePermanentAuthTokenError',
							defaultMessage : 'An error occurred while generating new access token'
						})
					}));
			}
			else
				store.dispatch(roomActions.setPermanentAuthToken(permanentAuthToken));
		}
		catch (error)
		{
			logger.error('generatePermanentAuthToken() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.generatePermanentAuthTokenError',
						defaultMessage : 'An error occurred while generating new access token'
					})
				}));
		}

		store.dispatch(
			roomActions.setAuthTokenChangeInProgress(false));
	}

	async refreshConsultants()
	{
		try
		{
			await this.sendRequest('refreshConsultants', { });
		}
		catch (error)
		{
			logger.error('refreshConsultants() [error:"%o"]', error);
		}
	}

	setSpotlightsCurrentPage(newPage)
	{
		this._spotlights.currentPage = newPage;
	}
}
